@import "../../style/scss/base/index";

.checkbox__container {
  display: block;
  position: relative;
  padding: 0 0 0 20px;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
  }

  span {
    display: block;
  }
}

.checkbox__container:not(:first-of-type) {
  margin: 0 0 0 35px;
}

.checkbox__content {
  p {
    font-size: 11px;
    margin: 0;
    padding: 0;
  }
}

.checkbox__link {
  padding: 0 0 0 35px;
  font-size: 11px;
  color: $blue !important;
  text-decoration: underline;
}

.checkbox__wrapper {
  margin-top: 12px;
}

.checkbox__checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  border: 1px solid #e8e8e8;
  height: 15px;
  width: 15px;
  background-color: $white;
  transition: border-width 0.1s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.checkbox__container:hover input ~ .checkbox__checkmark {
  background-color: #0070c541;
  border: none;
}

.checkbox__container input:checked ~ .checkbox__checkmark {
  border: 1px solid $blue;
  transition: border-width 0.1s ease-in-out;
}

.checkbox__container input:checked ~ .checkbox__checkmark:after {
  display: block;
}

.checkbox__container .checkbox__checkmark:after {
  left: 4px;
  bottom: 2px;
  width: 5px;
  height: 10px;
  border: solid $blue;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
