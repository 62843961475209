@import '../../scss/base/vars';

.page-title-area {
  z-index: 1;
  padding: 0 15px;

  &__title {
    color: $blue;
    font: {
      size: 22px;
      weight: 600;
    }
    letter-spacing: -0.55px;
    line-height: 44px;
    text-align: center;
    max-width: 800px;
    margin: {
      top: 37px;
      bottom: 15px;
      left: auto;
      right: auto;
    }
  }

  &__subtitle {
    text-align: center;
  }

  &__content {}

  &--without-breadcrumbs {
    .breadcrumbs {
      display: none;
    }
  }

  .rating {
    text-align: center;
    font-size: 17px;
    margin-top: 20px;

    i {
      color: #dca416;
      display: inline-block;
      margin: {
        right: 3px;
      }
    }
    .rating-count {
      display: inline-block;
      position: relative;
      top: -2px;
      font: {
        size: $font-size;
        weight: 600;
      }
    }
  }
}
