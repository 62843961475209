@import "../../style/scss/base/index";

.radiobox__container {
  display: block;
  position: relative;
  padding: 0 0 0 30px;
  margin: 0 0 15px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
  }

  span {
    display: block;
  }
}

.radiobox__content {
  p {
    font-size: 11px;
    margin: 0;
    padding: 0;
  }
}

.radiobox__link {
  padding: 0 0 0 35px;
  font-size: 11px;
  color: $blue !important;
  text-decoration: underline;
}

.radiobox__wrapper {
  margin-top: 12px;
}

.radiobox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  height: 17px;
  width: 17px;
  background-color: $white;
  transition: border-width 0.1s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.radiobox__checkmark--disabled {
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  height: 17px;
  width: 17px;
  background-color: $white;
  transition: border-width 0.1s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.radiobox__container:hover input ~ .radiobox__checkmark {
  background-color: #0070c541;
  border: none;
}

.radiobox__container input:checked ~ .radiobox__checkmark {
  border: 4px solid $blue;
  transition: border-width 0.1s ease-in-out;
}

.radiobox__container input:checked ~ .radiobox__checkmark:after {
  display: block;
}
