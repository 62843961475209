.breadcrumbs {
  &__item {
    margin-right: 17px;
    font-size: 14px;
    margin: {
      right: 17px;
      bottom: 8px;
    }

    &::before {
      right: -11px;
      top: 4px;
      height: 13px;
    }
  }
}
