@import "./vars";

@mixin font-13-normal {
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  font-weight: normal;
}

@mixin font-14-normal {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
}

@mixin font-15-normal {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
}

@mixin font-15-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

@mixin font-16-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}

@mixin font-17-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 600;
}

@mixin font-18-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

@mixin font-19-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
  font-weight: 600;
}

@mixin font-22-normal {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
}

@mixin font-22-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}

@mixin font-24-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 2.4rem;
  font-weight: 600;
}

@mixin font-30-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  font-weight: 600;
}

@mixin font-32-semi-bold {
  font-family: "Poppins", sans-serif;
  font-size: 3.2rem;
  font-weight: 600;
}

@mixin standard-text {
  p,
  ul,
  ol,
  li,
  th,
  td,
  strong,
  em {
    color: $greyFont;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }

  table {
    max-width: 100%;
    margin: 1.5em auto;
    border-color: #eee;

    th,
    td {
      border-color: #eee;
      vertical-align: middle;
    }
  }

  a {
    color: $green;
    cursor: pointer;
    transition: color 250ms ease-in-out;

    &:hover {
      color: lighten($green, 10%);
    }
  }

  ul,
  ol {
    margin: 15px 0;
    padding-left: 40px;
  }

  li {
    p {
      padding: 0;
      margin: 0;
    }
  }

  ol li {
    margin-bottom: 10px;
  }
}
