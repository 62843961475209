@import "../../../style/scss/base/";

.courses-details-info {
  background-color: #f8f9f8;
  border-radius: 5px;
  padding: 12px 12px 50px 12px;
  margin: {
    left: 15px;
    top: -248px;
  }
  .image {
    text-align: center;
    position: relative;

    .link-btn {
      display: block;
      width: 100%;
      height: 100%;
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    .content {
      position: absolute;
      left: 0;
      z-index: 2;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      i {
        display: block;
        width: 60px;
        height: 60px;
        background-color: $blue;
        color: $white;
        border-radius: 50%;
        line-height: 60px;
        font-size: 25px;
        margin: {
          left: auto;
          right: auto;
          bottom: 15px;
        }
      }
      span {
        display: inline-block;
        color: $white;
        border-radius: 5px;
        padding: 4px 20px;
        background-color: $black;
        font: {
          size: 17px;
          weight: 700;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: $black;
      opacity: 0.25;
    }
  }
  .info {
    margin-bottom: 0;
    list-style-type: none;
    padding: {
      left: 25px;
      right: 25px;
      top: 35px;
    }
    li {
      border-bottom: 1px solid #e5e5e7;
      color: $greyFont;
      font: {
        size: 17px;
        weight: 600;
      }
      padding: {
        top: 15px;
        bottom: 14px;
      }
      span {
        font-weight: 800;
        color: $black;
        position: relative;
        padding-left: 28px;

        i {
          color: $blue;
          position: absolute;
          left: 0;
          font-weight: normal;
          top: -2px;
          font-size: 20px;
        }
      }
      &.price {
        padding-bottom: 10px;
        color: $blue;
        font: {
          size: 28px;
          weight: 800;
        }
        span {
          color: $black;
          font: {
            size: 17px;
            weight: 800;
          }
        }
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .btn-box {
    margin-top: 35px;
    padding: {
      left: 25px;
      right: 25px;
    }
    .default-btn {
      display: block;
      width: 100%;
      padding: {
        right: 30px;
        left: 30px;
        top: 12px;
        bottom: 12px;
      }
      i {
        position: relative;
        left: 0;
        top: 2px;
        margin-right: 8px;
        line-height: 1;
        font: {
          weight: normal;
          size: 20px;
        }
      }
      &:not(:first-child) {
        margin-top: 15px;
        background-color: transparent;
        color: $black;
        border: 1px solid $black;

        &:hover {
          color: $white;
          border-color: $black;
        }
      }
    }
  }
  .courses-share {
    text-align: center;
    margin-top: 20px;
    padding: {
      left: 25px;
      right: 25px;
    }
    .share-info {
      display: inline-block;
      position: relative;
      cursor: pointer;

      span {
        display: inline-block;
        color: $blue;
        line-height: 1;
        font: {
          weight: 700;
        }
        i {
          color: $black;
        }
      }
      .social-link {
        padding-left: 0;
        list-style-type: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -35px;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        margin: {
          bottom: 0;
        }
        li {
          display: inline-block;
          margin-right: 3px;

          &:last-child {
            margin-right: 0;
          }
          a {
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: #e1e1e1;
            font-size: 18px;
            color: $black;
            position: relative;
            border-radius: 2px;

            i {
              position: absolute;
              left: 0;
              top: 50%;
              right: 0;
              transform: translateY(-48%);
            }
            &:hover {
              background-color: $blue;
              color: $white;
            }
          }
        }
      }
      &:hover {
        .social-link {
          opacity: 1;
          visibility: visible;
          bottom: -42px;
        }
      }
    }
  }
}
.courses-details-header {
  margin-bottom: 20px;
  .category {
    @include font-15-normal();
    color: $white;
    background-color: $yellow;
    border-radius: 3px;
    margin-right: 5px;
    padding: 2px 8px;
    width: auto;
  }
  .courses-title {
    p {
      font-size: 17px;
    }
  }
  .courses-meta {
    margin-top: 20px;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-right: 20px;
        position: relative;
        display: inline-block;
        border-right: 1px solid #eeeeee;
        font: {
          weight: 800;
          size: 17px;
        }
        padding: {
          right: 20px;
          left: 40px;
        }
        i {
          font-size: 28px;
          color: #cfcfcf;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        span {
          display: block;
          color: $blue;
          text-transform: uppercase;
          margin-bottom: 1px;
          font: {
            size: 13.5px;
            weight: 700;
          }
        }
        a {
          display: inline-block;
          color: $black;

          &:hover,
          &:focus {
            color: $blue;
          }
        }
        &:last-child {
          padding-right: 0;
          margin-right: 0;
          border-right: none;
        }
      }
    }
  }
  .courses-price {
    text-align: right;

    .price {
      display: inline-block;
      margin-right: 15px;
      line-height: 1;
      position: relative;
      top: -9px;
      font: {
        size: 36px;
        weight: 800;
      }
    }
    .courses-review {
      display: block;
      margin-bottom: 10px;

      .review-stars {
        display: inline-block;

        i {
          color: #f2b827;
          font-size: 18px;
          display: inline-block;
          margin-right: 2px;
        }
      }
      .reviews-total {
        position: relative;
        color: $greyFont;
        position: relative;
        top: -1px;
        margin-left: 2px;
        font-weight: 700;
      }
    }
  }
}
.courses-details-image-style-two {
  border-radius: 5px;

  img {
    width: 100%;
    border-radius: 5px;
  }
}
.courses-details-desc-style-two {
  margin-top: 35px;

  h3 {
    margin-bottom: 15px;
    font: {
      size: 24px;
      weight: 800;
    }
  }
  .why-you-learn {
    margin-top: 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      padding-left: 15px;
      margin: {
        left: -10px;
        right: -10px;
        bottom: -12px;
      }
      li {
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
          left: 10px;
          right: 10px;
          bottom: 12px;
        }
        span {
          display: block;
          color: $black;
          position: relative;
          padding-left: 22px;
          line-height: 1.7;
          font: {
            weight: 500;
          }
          i {
            position: absolute;
            left: 0;
            top: 3px;
            color: $blue;
            font-size: 13px;
          }
        }
      }
    }
  }
  .courses-curriculum {
    ul {
      padding: 0;
    }
    ul li {
      list-style: none;
      &:nth-child(odd) div.anchor {
        background-color: #f8f9f8;
      }
      &:nth-child(even) div.anchor {
        background-color: #ffffff;
      }

      div.anchor {
        background-color: transparent;
        color: $black;
        position: relative;
        border-radius: 5px;
        padding: {
          left: 14px;
          right: 14px;
          top: 11px;
          bottom: 11px;
        }

        .courses-name {
          @include font-13-normal();
          color: $blue;
        }
        .preview {
          cursor: pointer;
          transition: transform 0.2s ease-out;
          &:hover {
            transform: scale3d(1.1, 1.1, 1);
          }
        }
        .courses-meta {
          text-align: right;

          .status {
            display: inline-block;
            height: 20px;
            width: 20px;
            &.RichText {
              background-image: url(../../../images/awf/txt_.svg);
            }
            &.Video {
              background-image: url(../../../images/awf/mov_.svg);
            }
            &.H5P {
              background-image: url(../../../images/awf/inter_.svg);
            }
          }
        }
        &:hover {
          color: $blue;
        }
      }
    }
    h3 {
      @include font-15-semi-bold();
      color: $blue;
      span {
        @include font-15-semi-bold();
        color: $blue;
      }
    }
  }
  .requirements-list {
    padding-left: 15px;
    margin-bottom: 0;
    list-style-type: none;

    li {
      color: $greyFont;
      margin-bottom: 10px;
      position: relative;
      padding-left: 17px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  p {
    strong {
      color: $black;
    }
  }
  .description-features-list {
    padding-left: 15px;
    margin-bottom: 15px;
    list-style-type: none;

    li {
      color: $greyFont;
      margin-bottom: 10px;
      position: relative;
      padding-left: 17px;
      line-height: 1.8;

      &::before {
        position: absolute;
        left: 0;
        content: "";
        top: 10px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $black;
      }
      strong {
        color: $black;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .audience-list {
    padding-left: 15px;
    margin-bottom: 0;
    list-style-type: none;

    li {
      color: $greyFont;
      margin-bottom: 10px;
      position: relative;
      padding-left: 17px;
      line-height: 1.8;

      &::before {
        position: absolute;
        left: 0;
        content: "";
        top: 10px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $black;
      }
      strong {
        color: $black;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .courses-reviews {
    h3 {
      margin-bottom: 0;
      display: inline-block;
      margin-right: 15px;
      font: {
        size: 24px;
        weight: 800;
      }
    }
    .rating {
      display: inline-block;
      position: relative;
      top: 2px;

      span {
        font-size: 17px;
        color: #cecfd2;
        margin-right: 2px;

        &.checked {
          color: orange;
        }
      }
    }
    .rating-count {
      margin: {
        top: 10px;
        bottom: 20px;
      }
      span {
        display: block;

        color: $greyFont;
      }
    }
    .row {
      overflow: hidden;
      margin: {
        left: 0;
        right: 0;
      }
    }
    .side {
      float: left;
      width: 9%;
      margin-top: 10px;

      div {
        font: {
          weight: 700;
        }
      }
    }
    .middle {
      margin-top: 14px;
      float: left;
      width: 82%;
    }
    .right {
      text-align: right;
    }
    .bar-container {
      width: 100%;
      background-color: #f1f1f1;
      text-align: center;
      color: $white;
      border-radius: 5px;
    }
    .bar-5 {
      width: 100%;
      height: 18px;
      background-color: #4caf50;
      border-radius: 5px;
    }
    .bar-4 {
      width: 75%;
      height: 18px;
      background-color: #2196f3;
      border-radius: 5px;
      border-radius: 5px;
    }
    .bar-3 {
      width: 50%;
      height: 18px;
      background-color: #00bcd4;
      border-radius: 5px;
    }
    .bar-2 {
      width: 25%;
      height: 18px;
      background-color: #ff9800;
      border-radius: 5px;
    }
    .bar-1 {
      width: 0;
      height: 18px;
      background-color: #f44336;
      border-radius: 5px;
    }
  }
}
.courses-review-comments {
  margin-top: 40px;

  h3 {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 10px;
    margin: {
      top: 0;
      bottom: 10px;
    }
  }
  .user-review {
    border-bottom: 1px solid #f3f3f3;
    padding: 20px 0 20px 110px;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 20px;
      width: 90px;
      height: 90px;
      border-radius: 5px;
    }
    .sub-comment {
      margin-bottom: 8px;
      font: {
        weight: 700;
      }
    }
    .review-rating {
      display: block;
      margin-bottom: 8px;

      .review-stars {
        display: inline-block;

        i {
          color: #cecfd2;
          font-size: 18px;
          display: inline-block;
          margin-right: 2px;

          &.checked {
            color: orange;
          }
        }
      }
      span {
        color: $black;
        position: relative;
        top: -2px;
        font-weight: 700;
        margin-left: 5px;
      }
    }
  }
}
.related-courses {
  margin-top: 40px;

  h3 {
    margin-bottom: 20px;
    font: {
      size: 24px;
      weight: 800;
    }
  }
}
.courses-sidebar-information {
  background-color: $white;
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  padding: 30px;

  .info {
    margin-bottom: 0;
    list-style-type: none;
    padding: {
      left: 0;
    }
    li {
      border-bottom: 1px dashed #eeeeee;
      color: $greyFont;
      font: {
        size: 17px;
        weight: 600;
      }
      padding: {
        top: 15px;
        bottom: 13px;
      }
      span {
        font-weight: 800;
        color: $black;
        position: relative;
        padding-left: 28px;

        i {
          color: $blue;
          position: absolute;
          left: 0;
          font-weight: normal;
          top: -2px;
          font-size: 20px;
        }
      }
      &.price {
        padding-bottom: 10px;
        color: $blue;
        font: {
          size: 28px;
          weight: 800;
        }
        span {
          color: $black;
          font: {
            size: 17px;
            weight: 800;
          }
        }
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .btn-box {
    margin-top: 35px;

    .default-btn {
      display: block;
      padding: {
        right: 30px;
        left: 30px;
        top: 12px;
        bottom: 12px;
      }
      i {
        position: relative;
        left: 0;
        top: 2px;
        margin-right: 8px;
        line-height: 1;
        font: {
          weight: normal;
          size: 20px;
        }
      }
      &:not(:first-child) {
        margin-top: 15px;
        background-color: transparent;
        color: $black;
        border: 1px solid $black;

        &:hover {
          color: $white;
          border-color: $black;
        }
      }
    }
  }
  .courses-share {
    text-align: center;
    margin-top: 25px;

    .share-info {
      display: inline-block;
      position: relative;
      cursor: pointer;

      span {
        display: inline-block;
        color: $blue;
        line-height: 1;
        font: {
          weight: 700;
        }
        i {
          color: $black;
        }
      }
      .social-link {
        padding-left: 0;
        list-style-type: none;
        margin: {
          bottom: 0;
          top: 12px;
        }
        li {
          display: inline-block;
          margin-right: 3px;

          &:last-child {
            margin-right: 0;
          }
          a {
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: #e1e1e1;
            font-size: 18px;
            color: $black;
            position: relative;
            border-radius: 2px;

            i {
              position: absolute;
              left: 0;
              top: 50%;
              right: 0;
              transform: translateY(-48%);
            }
            &:hover {
              background-color: $blue;
              color: $white;
            }
          }
        }
      }
    }
  }
}
.courses-details-area {
  .courses-details-header {
    h2 {
      margin-top: 11px;
      @include font-30-semi-bold();
      color: $blue;
      margin-bottom: 20px;
    }
    .course-meta {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__update {
        @include font-13-normal();

        color: rgba($blue, 0.45);
      }
    }
  }

  .program-wrapper {
    .white-wrapper {
      &:first-of-type {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-bottom: 5px;
        padding-bottom: 10px;
      }
      &:nth-child(2) {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        margin-bottom: 5px;
      }
    }
    .program-heading {
      h3 {
        @include font-22-semi-bold();
        color: $blue;
        margin-top: 0;
      }
    }
    .startCourse {
      margin-top: 0;
      padding: 11px 80px;
      background-color: $green;
      @include font-18-semi-bold();
      width: 100%;
      color: $white;
      display: block;
      text-align: center;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
    }
  }

  .courses-details-desc-style-two {
    @include font-15-normal();
    * {
      font-family: "Poppins";
    }
    &.program {
      margin-top: 0;

      .courses-name {
        font: {
          size: 14.5px;
          weight: 700;
        }
      }
      .courses-meta {
        text-align: right;

        .duration {
          display: inline-block;
          background: $ultraLightBlue;
          color: $lightBlue;
          text-transform: lowercase;
          border-radius: 3px;

          padding: 4px 9px;
          font: {
            size: 13px;
            weight: 600;
          }
        }
        .status {
          display: inline-block;
          height: 20px;
          width: 20px;
          &.RichText {
            background-image: url(../../../images/awf/txt_.svg);
          }
          &.Video {
            background-image: url(../../../images/awf/mov_.svg);
          }
          &.H5P {
            background-image: url(../../../images/awf/inter_.svg);
          }
        }
      }
    }
  }

  .courses-author {
    margin-top: 7px;
    .author-profile {
      .author-profile-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .escolalms-image {
          width: 80px;
          img {
            width: 73px;
            height: 73px;
            border-radius: 50%;
            margin-right: 15px;
          }
        }

        .author-profile-details {
          h3 {
            @include font-13-normal();
            color: $blue;
          }
          h4 {
            @include font-15-semi-bold();
            color: $blue;
          }
        }
      }
      .bio {
        @include font-15-normal();
      }
    }
  }
}
