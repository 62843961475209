@import "../../style/scss/base";
.navbar-area {
  &.navbar-area-course {
    position: relative;
  }
}
.course-nav {
  background-color: $blue;
  padding-bottom: 0;
  box-shadow: 0px 3px 6px #00000055;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .course-navbar-title {
    margin: 0;
    color: $white;
    @include font-18-semi-bold();
    small {
      margin-top: 10px;
      @include font-15-normal();
    }
  }

  .navbar-nav {
    a {
      color: #8ba8bf;
      @include font-13-normal();
      display: flex;
      align-items: center;
      .close {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        border-radius: 50%;
        background-color: #8ba8bf;
        margin-left: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 20px;
          color: $white;
        }
      }
    }
  }

  .top-progress {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0px;
    z-index: 100;
    background-color: rgba(#5881a1, 0.3);
    .top-progress-bar {
      position: relative;
      height: 5px;
      background-color: $green;
      transition: width 1s ease-out;
      max-width: 100%;
    }
  }
}
