@import "../../style/scss/base/";

.single-courses-box {
  margin-bottom: 30px;
  position: relative;
  border-radius: 13px;
  padding: 6px 7px;
  background-color: $white;
  transition: 0.3s transform ease;
  border: 1px solid $courseCardBorder;

  .courses-image {
    position: relative;
    z-index: 1;

    .image {
      border-radius: 8px;

      img {
        border-radius: 8px;
      }
    }
  }
  .courses-content {
    border-radius: 0 0 5px 5px;
    padding: 5px;

    .courses-categories {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 5px;
      span {
        @include font-13-normal();
        color: $yellow;
        margin-right: 5px;
      }
    }

    h3 {
      margin-bottom: 12px;
      line-height: 1.3;

      a {
        @include font-16-semi-bold();
        display: inline-block;
        color: $blue;
        &:hover {
          color: $blue;
        }
      }
    }
  }
  .course-finish-date {
    color: $green;
    i {
      background-color: #dbf4e9;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 5px;
      text-align: center;
    }
  }
  &.bg-color {
    background-color: #fffaf3;
    box-shadow: unset;
    transition: $transition;

    .courses-content {
      h3 {
        margin-bottom: 10px;
      }
    }
    &:hover {
      background-color: $white;
      box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    }
  }
  &.without-boxshadow {
    background-color: $white;
    box-shadow: unset;
    transition: $transition;

    .courses-content {
      h3 {
        margin-bottom: 10px;
      }
    }
    &:hover,
    &.active {
      box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    }
  }
  &:hover {
    transform: scale(1.03);
  }
}
