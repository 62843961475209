@import "../../style/scss/base/vars";
@import "../../style/scss/base/typo";

.course-program-player {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: calc(99vw - 400px);
  position: relative;
  align-self: flex-start;
  margin-right: 13px;
  align-items: center;

  @include standard-text();

  > iframe {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }

  &.scorm {
    iframe {
      overflow: auto;
      resize: both;
    }
  }

  .course-program-player-next {
    flex-basis: 100%;
    text-align: center;
    margin: 1em;
    padding: 1em;
    > button {
      padding: 14px 73px 14px 73px;
      border-radius: 36px;
      background-color: $green;
      color: $white;
      &:hover {
        border-color: $green;
        color: $green;
        .course-program-player-next-button__icon {
          transition: fill 0.5s ease-in-out;
          fill: $green;
        }
      }
      &:disabled {
        background-color: #dedede;
        cursor: not-allowed;
        color: $white;
        &:hover {
          border-color: #dedede;
          color: $white;
          .course-program-player-next-button__icon {
            transition: fill 0.5s ease-in-out;
            fill: $white;
          }
          span {
            width: 0;
            height: 0;
          }
        }
      }
    }
  }

  .course-program-player-content {
    width: 100%;
    // min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 1200px;
    // padding: 50px 100px;

    > .h5p-player {
      width: 100%;
    }

    > .typebase {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    h2 {
      margin-top: 20px;
      margin-bottom: 20px;
      color: $blue;
      @include font-22-semi-bold();
    }

    &__wrapper {
      width: 100%;
    }
  }
  .course-program-summary {
    margin-top: 10px;
    margin-right: auto;
    padding: 10px;
    border-top: 1px solid #eeeeee;
  }

  .course-program-player-content,
  .course-program-summary {
  }
}

.course-program-player-next {
  display: flex;
  justify-content: center;

  button {
    height: 50px;
    position: relative;
    display: flex;
  }
}

.course-program-player-next-button {
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 20px;
    right: 5%;
    margin: 0 0 0 auto;
    width: 10px;
    height: 10px;
    transform: rotate(-90deg);
    transition: fill 0.5s ease-in-out;
    fill: $white;
  }
}

.course-program-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.course-program-wrapper {
  display: flex;
  width: 100%;
}

.course-program-wrapper-preview {
  display: flex;
  max-width: 1400px;
}

@media (max-width: 1100px) {
  .course-program-wrapper .course-program-player {
    max-width: calc(99vw - 300px);
  }
  .course-program-wrapper .course-program-sidebar {
    width: 300px;
  }
}

@media (max-width: 800px) {
  .course-program-wrapper .course-program-player {
    max-width: calc(99vw - 250px);
  }
  .course-program-wrapper .course-program-sidebar {
    width: 250px;
  }
}

@media (max-width: 650px) {
  .course-program-wrapper {
    flex-wrap: wrap;
  }

  .course-program-wrapper .course-program-player {
    width: 100%;
    max-width: 100%;
    margin: 5px;
  }
  .course-program-wrapper .course-program-sidebar {
    width: 100%;
    margin: 5px;
  }
}
