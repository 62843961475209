@import "../../../style/scss/base/vars";

.tag-cloud {
  &__title {
    margin-bottom: 5px;
    position: relative;
    padding-bottom: 7px;
    font-family: "Poppins", sans-serif;
    color: #003e70;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
