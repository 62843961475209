@import "../../style/scss/base/index";

.register-container {
  display: flex;
  justify-content: center;
  min-height: 78vh;
  margin: 0;
  background-color: $inputBackground;
}

.register-container .app-input {
  max-width: 350px;
}

.register-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 100px 30px;
  background-color: $blue;
  border-radius: 10px;
}

.register-wrapper__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  color: $white;
  font-size: 22px;
  font-weight: bold;
}

.register-content {
  background-color: $white;
  border-radius: 10px 10px 8px 8px;
  padding: 30px 60px;

  h1 {
    margin-bottom: 30px;
    color: $blue;
    font-weight: bold;
    font-size: 16px;
    font-family: inherit;
  }
}

.register-radio {
  margin-bottom: 20px;
}

.register-radio-tooltip {
  display: flex;
  align-items: center;
}

.register-radio-tooltip .anticon {
  margin: 0 0 14px 5px;
}

.register-radio-tooltip label {
  display: inline-block !important;
}

.register-radio__wrapper {
  border-radius: 8px;
  border: 1px solid $borderGrey;
  padding: 10px 30px;
  color: $greyFont;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  h2 {
    color: $greyFont;
    font-weight: bold;
    font-size: 13px;
    font-family: inherit;
  }
}

.register-radio__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.register-radio__content .checkbox__container {
  margin: 10px 20px 10px 0;
}

.form-group {
  label {
    font-size: 13px;
    color: $greyFont;
  }
}

.form-group.agreements {
  label {
    font-size: 15px;
    color: $greyFont;
  }
}

.is-invalid {
  transition: 0.5s;
  border: 1px solid $error;
  background-image: url(../../images/substraction_2.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  padding-right: calc(1.5em + 0.75rem);
}

select.is-invalid {
  transition: 0.5s;
  border: 1px solid $error;
  background-image: url(../../images/substraction_2.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.9em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  padding-right: calc(1.5em + 0.75rem);
}

.agreements {
  display: flex;
  flex-direction: column;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.register-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.agreements .checkbox__container {
  margin: 10px 0;
}

.app-button {
  border-radius: 20px;
  background-color: $lightBlue;
  width: 100%;
  max-width: 300px;
  border: 2px solid $lightBlue;
  height: 40px;
  font-size: 16px;
  color: $white;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  transition: $transition;
  z-index: 1;

  &-dark {
    background-color: $blue;
    border: 2px solid $blue;
  }

  &-long {
    min-width: 120px;
    margin: 0 5px;
  }

  &--register {
    @media (min-width: 990px) and (max-width: 1050px) {
      font-size: 0.8em;
      min-width: 90px;
    }
  }

  span.rollover {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $white;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &:hover {
    color: $blue;
    border: 2px solid $blue;
    span.rollover {
      width: 225%;
      height: 562.5px;
    }
  }

  &:disabled {
    background-color: #dedede;
    color: $white;
    &:hover {
      color: $blue;
    }
  }
}

.register-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-h1 {
  margin-top: 100px;
}

.register-success__wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  height: 250px;
  width: 100%;
  max-width: 800px;
  padding: 35px 60px;
  margin-top: 100px;

  h1 {
    margin-bottom: 30px;
    color: $blue;
    font-weight: bold;
    font-size: 16px;
    font-family: inherit;
  }

  p {
    margin: 5px 0;
    color: $blue;
    font-size: 14px;
    font-family: inherit;
  }
}

@media (max-width: 450px) {
  .register-content {
    padding: 30px 20px;
  }
}
a {
  &:hover {
    color: $blue !important;
  }
}
