@import "../../style/scss/base/";

.pagination-area {
  margin-top: 35px;

  .page-numbers {
    cursor: pointer;
    width: 33px;
    height: 33px;
    background-color: #f3f0f0;
    color: $greyFont;
    text-align: center;
    display: inline-block;
    border-radius: 13px;
    line-height: 34px;
    position: relative;
    margin: {
      left: 3px;
      right: 3px;
    }
    font: {
      weight: 800;
    }
    &:hover,
    &.current {
      color: $white;
      background-color: $blue;
    }
    i {
      position: relative;
      top: 1.5px;
    }
  }
}
