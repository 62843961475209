@import "../../style/scss/base/vars";
@import "../../style/scss/base/typo";

.courses-area {
  position: relative;
  z-index: 1;
  margin: {
    top: 50px;
    bottom: 50px;
  }
}

.course-container {
  display: flex;
  justify-content: center;
  margin: 0;
  background-color: $inputBackground;
  min-height: 100vh;
  @media only screen and (max-width: 767px) {
    min-height: auto;
  }
}

.course-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  width: 100%;
  margin: 100px 30px 50px;
  .row {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    margin: 60px 10px;
  }
}

.single-courses-item-box {
  margin-bottom: 30px;
  position: relative;
  background-color: $white;
  transition: $transition;
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.08);
  text-align: center;

  .courses-image {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      background-color: $black;
      opacity: 0.3;
      transition: $transition;
    }
    .fav {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 20px;
      color: $white;
      font-size: 30px;
      z-index: 2;
      line-height: 1;

      &:hover {
        color: $blue;
      }
    }
    .price {
      display: inline-block;
      background-color: $blue;
      color: $white;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: -32.5px;
      left: 0;
      text-align: center;
      line-height: 67px;
      z-index: 2;
      font: {
        size: 24px;
        weight: 800;
      }
      margin: {
        left: auto;
        right: auto;
      }
    }
  }
  .courses-content {
    padding: 50px 20px 20px;

    .course-author {
      margin-bottom: 15px;

      img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
      span {
        color: $blue;
        font: {
          weight: 600;
          size: 15px;
        }
      }
    }
    h3 {
      margin-bottom: 0;
      line-height: 1.3;
      font: {
        size: 22px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .rating {
      margin-top: 15px;

      i {
        color: #f4c150;
      }
      .rating-total {
        display: inline-block;
        color: $greyFont;
        font-weight: 600;
        position: relative;
        top: -1.5px;
        margin-left: 5px;
      }
    }
  }
  &:hover {
    .courses-image {
      &::before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
.courses-info {
  text-align: center;
  margin-top: 20px;

  .default-btn {
    margin-bottom: 15px;
  }
  p {
    max-width: 630px;
    margin: {
      left: auto;
      right: auto;
      bottom: 0;
    }
    a {
      color: $blue;

      &:hover {
        text-decoration: underline;
        color: $black;
      }
    }
  }
}
.courses-slides {
  &.owl-theme {
    .owl-dots {
      margin: {
        top: 20px !important;
        bottom: 35px;
      }
      .owl-dot {
        span {
          margin: 0 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $blue;

            &::before {
              background-color: $blue;
            }
          }
        }
      }
    }
    .owl-nav {
      margin-top: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;

      [class*="owl-"] {
        margin: 0 40px;
        width: 35px;
        height: 35px;
        border-radius: 3px;
        transition: $transition;
        background-color: $white;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        line-height: 42px;
        padding: 0;
        font-size: 25px;
        color: $blue;

        &:hover {
          background-color: $blue;
          color: $white;
        }
      }
    }
  }
}
.shape16 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  text-align: center;
}
.shorting-menu {
  text-align: center;
  margin-bottom: 30px;

  .filter {
    display: inline-block;
    padding: 0;
    background-color: transparent;
    color: $black;
    transition: $transition;
    border: none;
    font: {
      size: 16.5px;
      weight: 700;
    }
    margin: {
      left: 10px;
      right: 10px;
    }
    &.active,
    &:hover {
      color: $blue;
    }
  }
  &.shorting-menu-style-two {
    background-color: #f8f9f8;
    border-radius: 5px;
    margin: {
      bottom: 40px;
    }
    padding: {
      left: 60px;
      right: 60px;
    }
    .filter {
      position: relative;
      color: $greyFont;
      padding: {
        top: 16px;
        bottom: 14px;
        left: 7px;
        right: 7px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $blue;
        transition: $transition;
        transform: scaleX(0);
      }
      &:hover,
      &.active {
        color: $black;

        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
}
.shorting {
  .mix {
    display: none;
  }
}
.escolalms-grid-sorting {
  margin-bottom: 40px;

  .result-count {
    h2 {
      @include font-22-semi-bold();
      color: $blue;
    }
  }
  .ordering {
    text-align: right;

    label {
      display: inline-block;
      margin-bottom: 0;
      color: #ababab;
      margin-right: 5px;
      font: {
        weight: 700;
      }
    }
    .nice-select {
      display: inline-block;
      width: 215px;
      background: #f8f8f8;
      border-color: #eeeeee;
      color: $black;
      transition: $transition;
      padding: 0 0 0 12px;
      height: 45px;
      line-height: 45px;
      font: {
        weight: 700;
      }
      .list {
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        list-style-type: none;
        border: none;
        width: 100%;
        margin: {
          bottom: 0;
          top: 0;
        }
        padding: {
          left: 0;
          top: 10px;
          bottom: 10px;
        }
        .option {
          line-height: 38px;
          min-height: 38px;
          color: $black;
          position: relative;
          transition: $transition;
          padding: {
            left: 32px;
            right: 25px;
          }
          font: {
            weight: 700;
          }
          &:hover {
            background-color: $blue !important;
            color: $white;

            &::before {
              color: $white;
            }
          }
          &.focus,
          &.selected.focus {
            background-color: transparent !important;
            color: $black;
          }
          &::before {
            content: "\f11f";
            position: absolute;
            left: 10px;
            top: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            color: $blue;
            font: {
              family: Flaticon;
              size: 11px;
            }
          }
          &.selected {
            &::before {
              opacity: 1;
              visibility: visible;
            }
            &:hover {
              background-color: $blue !important;
              color: $white;

              &::before {
                color: $white;
              }
            }
          }
        }
      }
      &:after {
        border-color: $black;
        height: 8px;
        width: 8px;
        margin-top: -5px;
      }
      &:hover {
        border-color: $blue;
        background-color: transparent;
      }
    }
  }
  .select-box {
    max-width: 220px;
    margin-left: auto;
    select {
      background-color: $white;
    }
  }
}
.load-more-btn {
  text-align: center;
  margin: {
    top: 30px;
    bottom: 30px;
  }
  .load-more {
    display: inline-block;
    background-color: transparent;
    border: 1px solid #eeeeee;
    padding: 13px 65px 13px 40px;
    border-radius: 5px;
    color: $blue;
    position: relative;

    i {
      position: absolute;
      right: 40px;
      top: 50%;
      font-size: 18px;
      transform: translateY(-50%);
    }
    &:hover {
      color: $white;
      border-color: $white;
      background-color: $blue;
    }
  }
}
.courses-items {
  margin: {
    left: -15px;
    right: -15px;
  }
}
.single-courses-item {
  &.without-box-shadow {
    box-shadow: unset;
    transition: $transition;

    .courses-image {
      img {
        border-radius: 50%;
      }
      &::before {
        border-radius: 50%;
      }
    }
    &:hover {
      box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);

      .courses-image {
        img {
          border-radius: 5px;
          transform: scale(1);
        }
        &::before {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
.single-courses-box {
  .courses-image {
    .price {
      &.bg-45a393 {
        background-color: $blue;
      }
    }
  }
}
.courses-area {
  &.bg-image {
    background: {
      color: #fff7f4;
      image: url(../../images/courses-bg.jpg);
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }
}
