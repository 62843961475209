@import "../../style/scss/base";

.footer-area {
  background-color: $white;
  position: relative;
  z-index: 1;
  padding: 45px 120px;
  .footer-wrapper {
    display: flex;
    flex-direction: row;
    p,
    a {
      color: $footerText;
      @include font-13-normal();
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 45px 45px;
    .footer-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
