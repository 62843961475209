.center-image-richtext p img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: 95vh;
  width: auto;
  // 50vw pushes the image to be as big as possible (width must be auto)
  // the image will be between 450-600px but no larger than the container
  max-width: min(max(min(70vw, 1000px), 450px), 100%);
}
