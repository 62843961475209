@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
html {
  font-size: 62.5%;
}

body {
  font-size: 1.5rem;
  background-color: $inputBackground !important;
}
b,
strong {
  font-weight: bold;
}

* {
  font-family: "Poppins", sans-serif !important;
}
