.paymentModal {
  .StripeElement,
  .form-control {
    padding: 0.375rem 0.75rem;
    height: auto;
    min-height: 40px;
  }

  .modal-footer {
    button {
      padding-left: 30px;
    }
    .close-btn {
      background-color: $black;
    }
  }
}
