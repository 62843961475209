@import "../../style/scss/base";

/*================================================
Navbar Area CSS
=================================================*/
.escolalms-nav {
  .container-fluid {
    padding: {
      left: 40px;
      right: 40px;
    }
  }
  .navbar {
    position: inherit;
    padding: {
      left: 0;
      right: 0;
      top: 17px;
      bottom: 17px;
    }
    .navbar-brand {
      font-size: inherit;
      line-height: 1;
      padding: {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      min-width: 250px;
      img {
        margin-right: 45px;
      }
    }
    ul {
      padding-left: 0;
      list-style-type: none;
      margin-bottom: 0;
    }
    .search-box {
      margin-left: 50px;
      position: relative;
      width: 260px;
      border-radius: 5px;

      .input-search {
        display: block;
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 5px;
        background-color: #f5f5f5;
        transition: $transition;
        color: $black;

        padding: {
          left: 15px;
          top: 1px;
          bottom: 0;
          right: 0;
        }
        &::placeholder {
          color: #848484;
          transition: $transition;
        }
        &:focus {
          border-color: $blue;

          &::placeholder {
            color: transparent;
          }
        }
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        height: 48px;
        background-color: transparent;
        border: none;
        color: $blue;
        border-radius: 5px;
        padding: 0 15px;
      }
    }
    .navbar-nav {
      margin: {
        left: auto;
      }
      .nav-item {
        position: relative;
        @include font-15-normal();
        margin-right: 45px;
        a {
          color: $blue;
          @include font-15-normal();
          padding: {
            left: 0;
            right: 0;
            top: 10px;
            bottom: 10px;
          }
          margin: {
            left: 13px;
            right: 13px;
          }
          i {
            font-size: 18px;
            position: relative;
            top: 2px;
            display: inline-block;
            margin: {
              left: -4px;
              right: -3px;
            }
          }
          &.active {
            color: $lightBlue;
            border-bottom: 2px solid $lightBlue;
          }
        }
        &:last-child {
          a {
            margin-right: 0;
          }
        }
        &:first-child {
          a {
            margin-left: 0;
          }
        }
        &:hover {
          a {
            color: $lightBlue;
          }
        }

        .dropdown-menu {
          border: none;
          top: 33px;
          left: 0;
          z-index: 99;
          opacity: 0;
          width: 140px;
          display: block;
          border-radius: 0;
          padding: 10px 0;
          margin-top: 15px;
          position: absolute;
          visibility: hidden;
          background: $white;
          transition: all 0.2s ease-in-out;
          border-top: 3px solid $blue;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

          li {
            a {
              padding: 8px 20px;
              margin: 0;
              position: relative;
              display: block;
              color: $greyFont;
              @include font-13-normal();
              i {
                margin: 0;
                position: absolute;
                top: 50%;
                font-size: 20px;
                transform: translateY(-50%);
                right: 15px;
              }
              &:hover,
              &:focus,
              &.active {
                color: $blue;
              }
            }
            .dropdown-menu {
              top: 0;
              opacity: 0;
              left: -100%;
              margin-top: 15px;
              visibility: hidden;

              li {
                a,
                button {
                  color: $black;

                  &:hover,
                  &:focus,
                  &.active {
                    color: $blue;
                  }
                }
                .dropdown-menu {
                  top: 0;
                  opacity: 0;
                  left: 100%;
                  visibility: hidden;

                  li {
                    a,
                    button {
                      color: $black;

                      &:hover,
                      &:focus,
                      &.active {
                        color: $blue;
                      }
                    }
                    .dropdown-menu {
                      top: 0;
                      opacity: 0;
                      left: -100%;
                      visibility: hidden;

                      li {
                        a {
                          color: $black;

                          &:hover,
                          &:focus,
                          &.active {
                            color: $blue;
                          }
                        }
                        .dropdown-menu {
                          top: 0;
                          opacity: 0;
                          left: 100%;
                          visibility: hidden;
                          transform: scaleY(0);

                          li {
                            a,
                            button {
                              color: $black;

                              &:hover,
                              &:focus,
                              &.active {
                                color: $blue;
                              }
                            }
                            .dropdown-menu {
                              top: 0;
                              opacity: 0;
                              left: -100%;
                              visibility: hidden;
                              transform: scaleY(0);

                              li {
                                a {
                                  color: $black;

                                  &:hover,
                                  &:focus,
                                  &.active {
                                    color: $blue;
                                  }
                                }
                                .dropdown-menu {
                                  top: 0;
                                  opacity: 0;
                                  left: 100%;
                                  visibility: hidden;

                                  li {
                                    a {
                                      color: $black;

                                      &:hover,
                                      &:focus,
                                      &.active {
                                        color: $blue;
                                      }
                                    }
                                  }
                                }
                                &.active {
                                  a {
                                    color: $blue;
                                  }
                                }
                                &:hover {
                                  .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                  }
                                }
                              }
                            }
                            &.active {
                              a {
                                color: $blue;
                              }
                            }
                            &:hover {
                              .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                              }
                            }
                          }
                        }
                        &.active {
                          a {
                            color: $blue;
                          }
                        }
                        &:hover {
                          .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                          }
                        }
                      }
                    }
                    &.active {
                      a {
                        color: $blue;
                      }
                    }
                    &:hover {
                      .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                      }
                    }
                  }
                }
                &.active {
                  a {
                    color: $blue;
                  }
                }
                &:hover {
                  .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
            &.active {
              a {
                color: $blue;
              }
            }
            &:hover {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
                margin-top: 0;
              }
            }
          }
        }
        &:hover {
          .dropdown-menu {
            opacity: 1;
            visibility: visible;
            margin-top: 9px;
          }
        }
        &.megamenu {
          position: inherit;

          .container {
            display: block;
            flex-wrap: unset;
            align-items: unset;
            justify-content: unset;
          }
          .dropdown-menu {
            width: 100% !important;
            top: auto;
            left: 0;
            margin-top: 0;
            padding: 30px 0;
            position: absolute;
            transform: unset !important;

            .megamenu-submenu {
              li {
                a {
                  margin-top: 14px;
                  color: $black;
                  padding: 0;
                  display: block;
                  font: {
                    size: 16px;
                    weight: 700;
                  }
                  &:hover,
                  &.active {
                    color: $blue;
                  }
                }
                &:first-child {
                  a {
                    margin-top: 0;
                  }
                }
              }
            }
            .single-category-widget {
              position: relative;
              border-top: 1px solid #eeeeee;
              padding-top: 30px;
              margin: {
                top: 30px;
              }
              .icon {
                width: 70px;
                height: 70px;
                position: relative;
                background: #f4f4f4;
                text-align: center;
                font-size: 40px;
                transition: $transition;
                color: $black;
                margin-bottom: 15px;
                border: {
                  width: 1.5px;
                  style: dashed;
                  color: #eeeeee;
                  radius: 50%;
                }
                i {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  right: 0;
                }
              }
              h3 {
                margin-bottom: 0;
                font: {
                  size: 20px;
                  weight: 800;
                }
              }
              .sub-title {
                display: block;
                margin-top: 5px;
                color: $blue;
                font: {
                  weight: 600;
                  size: 15px;
                }
              }
              .link-btn {
                position: absolute;
                display: block;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 2;
              }
              &:hover {
                .icon {
                  background-color: $blue;
                  border-color: $white;
                  color: $white;
                }
              }
            }
          }
        }
        &.courses {
          display: flex;
          flex-direction: column;
          margin-right: 0;
          padding: 0;
          font-size: 20px;
          a {
            color: $black !important;
            width: 100%;
            border-bottom: none;
            transition: all 0.3s ease-in-out;
          }
          .category-dropdown {
            width: 100%;
          }
          &:hover {
            a {
              color: $blue !important;
            }
          }
        }
      }
    }
    .others-option {
      margin-left: 25px;

      .option-item {
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:first-child {
          margin-left: 0;
        }
        .default-btn {
          margin-left: 10px;
        }

        &__icon {
          width: 37px;
          min-width: 37px;
          height: 37px;
          min-height: 37px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $blue;
        }
      }
      .cart-btn {
        font-size: 22px;
        position: relative;
        line-height: 1;

        a {
          display: inline-block;
          position: relative;
          color: $black;

          span {
            position: absolute;
            right: -4px;
            top: -4px;
            width: 14px;
            height: 14px;
            text-align: center;
            line-height: 14px;
            border-radius: 50%;
            color: $white;
            background-color: $blue;
            font: {
              size: 10px;
              weight: 600;
            }
          }
          &:hover {
            color: $blue;
          }
        }
      }

      .user-dropdown {
        position: relative;
        margin-left: 15px;
        a {
          display: flex;
          align-items: center;
          color: $blue;
          span svg {
            margin-left: 5px;
          }
          &:hover {
            color: $blue;
          }
        }
        .dropdown-menu {
          border: none;
          top: 28px;
          left: auto;
          right: 0;
          z-index: 99;
          opacity: 0;
          width: 150px;
          display: block;
          border-radius: 0;
          padding: 10px 0;
          margin-top: 15px;
          position: absolute;
          visibility: hidden;
          background: $white;
          transition: all 0.2s ease-in-out;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

          li {
            a {
              padding: 8px 20px;
              margin: 0;
              position: relative;
              display: block;
              color: $black;
              @include font-13-normal();
              i {
                margin: 0;
                position: absolute;
                top: 50%;
                font-size: 20px;
                transform: translateY(-50%);
                right: 15px;
              }
              &:hover,
              &:focus,
              &.active {
                color: $blue !important;
              }
            }
          }
        }
        &:hover {
          .dropdown-menu {
            opacity: 1;
            visibility: visible;
            margin-top: 9px;
          }
        }
      }
    }
  }
}
.navbar-area {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: $white;
  padding: {
    bottom: 5px;
  }
  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: $white !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

    &.pb-0 {
      padding-bottom: 5px !important;
    }
  }
  &.course-nav-area {
    position: static;
  }
  @media (max-width: 768px) {
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  }
}

/* Navbar toggler */
.navbar-toggler {
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;

  .icon-bar {
    width: 35px;
    transition: all 0.3s;
    background: #221638;
    height: 4px;
    display: block;
    border-radius: 3px;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
    left: 4px;
    position: relative;
  }
  .middle-bar {
    opacity: 0;
    margin: 5px 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    left: 4px;
    position: relative;
  }
  &.collapsed {
    .top-bar {
      transform: rotate(0);
      left: 0;
    }
    .middle-bar {
      opacity: 1;
    }
    .bottom-bar {
      transform: rotate(0);
      left: 0;
    }
  }
}

.course-nav .navbar .navbar-toggler {
  margin-bottom: 10px;
  span {
    background-color: #8ba8bf;
  }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 1199px) {
  .navbar-area {
    padding: {
      // top: 20px;
      // bottom: 20px;
    }
  }
}

@media (min-width: 992px) {
  .nav-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      margin: auto;
      text-align: center;
      display: block;
    }
  }
}

@media (max-width: 992px) {
  .escolalms-nav .navbar .navbar-nav .nav-item {
    margin-right: 0;
  }
  .others-option {
    margin-top: 20px;
    justify-content: flex-start;
  }
}

@media (max-width: 360px) {
  .escolalms-nav .navbar .navbar-brand img:first-child {
    width: 50px;
    margin-right: 20px;
  }
  .escolalms-nav .navbar .navbar-brand {
    min-width: auto;
  }
  .escolalms-nav .navbar .navbar-brand img:last-child {
    margin-right: 0;
    width: 100px;
    max-width: initial;
  }

  .navbar-brand {
    margin-right: 5px !important;
    width: 50px !important;
  }
}
