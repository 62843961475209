@import "../../style/scss/base/";

.widget-area {
  padding-left: 15px;

  .widget {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
    .widget-title {
      margin-bottom: 5px;
      position: relative;
      padding-bottom: 7px;
      @include font-22-semi-bold();
      color: $blue;
      &.small {
        @include font-15-semi-bold();
      }
    }
    input,
    select {
      background-color: $white;
    }
  }
  .widget_search {
    form {
      position: relative;

      .screen-reader-text {
        display: none;
      }
      label {
        display: block;
        margin-bottom: 0;
      }
      .search-field {
        height: 50px;
        color: $black;
        background-color: #f2f4f5;
        display: block;
        width: 100%;
        border-radius: 5px;
        padding: 0 0 0 15px;
        border: none;
        transition: $transition;
        font: {
          weight: 500;
        }
        &::placeholder {
          color: $greyFont;
          transition: $transition;
        }
        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
      button {
        border: none;
        background-color: transparent;
        color: $blue;
        height: 30px;
        width: 30px;
        position: absolute;
        right: 5px;
        padding: 0;
        transition: $transition;
        top: 5px;
        font-size: 20px;

        i {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover,
        &:focus {
          border-radius: 5px;
          background-color: $blue;
          color: $white;
        }
      }
    }
  }
  .widget_escolalms_posts_thumb {
    position: relative;
    overflow: hidden;

    .item {
      overflow: hidden;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 80px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          border-radius: 5px;
          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }
          &.bg1 {
            background-image: url(../../images/blog/blog15.jpg);
          }
          &.bg2 {
            background-image: url(../../images/blog/blog14.jpg);
          }
          &.bg3 {
            background-image: url(../../images/blog/blog13.jpg);
          }
        }
        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: $white;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }
        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }
        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }
      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: $greyFont;
          text-transform: uppercase;
          margin: {
            top: -2px;
            bottom: 5px;
          }
          font: {
            size: 12px;
          }
        }
        .title {
          margin-bottom: 0;
          line-height: 1.4;
          font: {
            size: 17px;
            weight: 700;
          }
          a {
            display: inline-block;
          }
        }
      }
      &:hover {
        .thumb {
          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
  .widget_categories {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        overflow: hidden;
        color: $black;
        padding-left: 18px;
        font: {
          weight: 700;
          size: 15px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $blue;
          height: 8px;
          width: 8px;
          content: "";
          border-radius: 50%;
          left: 0;
          top: 7px;
          position: absolute;
        }
        a {
          color: $black;
          display: inline-block;

          &:hover {
            color: $blue;
          }
        }
        .post-count {
          color: $greyFont;
          font-size: 14px;
          display: inline-block;
          position: relative;
          top: 1px;
        }
      }
    }
  }
  .widget_tag_cloud {
    .widget-title {
      margin-bottom: 17px;
    }
  }
  .widget_insight {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-bottom: -1px;

        a {
          position: relative;
          display: block;
          padding: 15px 25px 15px 40px;
          color: $greyFont;
          border: 1px solid #eeeeee;
          font: {
            weight: 700;
          }
          &::before {
            width: 5px;
            height: 5px;
            transition: $transition;
            background-color: $greyFont;
            content: "";
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
          }
          &:hover,
          &.active {
            border-color: $blue;
            background-color: $blue;
            color: $white;

            &::before {
              background-color: $white;
            }
          }
        }
        &.active {
          a {
            border-color: $blue;
            background-color: $blue;
            color: $white;

            &::before {
              background-color: $white;
            }
          }
        }
      }
    }
  }
  .widget_recent_courses {
    position: relative;
    overflow: hidden;

    .widget-title {
      margin-bottom: 30px;
    }
    .item {
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 80px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          border-radius: 3px;
          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }
          &.bg1 {
            background-image: url(../../images/courses/courses1.jpg);
          }
          &.bg2 {
            background-image: url(../../images/courses/courses2.jpg);
          }
          &.bg3 {
            background-image: url(../../images/courses/courses3.jpg);
          }
        }
        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: $white;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }
        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }
        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }
      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: $blue;
          text-transform: uppercase;
          margin: {
            bottom: 6px;
            top: -5px;
          }
          font: {
            size: 18px;
            weight: 800;
            style: italic;
          }
        }
        .title {
          margin-bottom: 0;
          line-height: 1.4;
          font: {
            weight: 700;
          }
          a {
            display: inline-block;
          }
        }
      }
      &:hover {
        .thumb {
          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
  .filter_by_price {
    .irs--flat {
      .irs-bar {
        background-color: $blue;
      }
      .irs-handle > i:first-child {
        background-color: $blue;
      }
    }
  }
  .widget_popular_products {
    position: relative;
    overflow: hidden;

    .widget-title {
      margin-bottom: 30px;
    }
    .item {
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
      .thumb {
        float: left;
        height: 110px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 110px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 110px;
          height: 110px;
          display: inline-block;
          border-radius: 3px;
          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }
          &.bg1 {
            background-image: url(../../images/products/product7.jpg);
          }
          &.bg2 {
            background-image: url(../../images/products/product8.jpg);
          }
          &.bg3 {
            background-image: url(../../images/products/product9.jpg);
          }
        }
        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: $white;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }
        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }
        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }
      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: $blue;
          text-transform: uppercase;
          margin: {
            bottom: 6px;
            top: -5px;
          }
          font: {
            size: 18px;
            weight: 800;
            style: italic;
          }
        }
        .title {
          margin-bottom: 0;
          line-height: 1.4;
          font: {
            size: 17px;
            weight: 700;
          }
          a {
            display: inline-block;
          }
        }
        .rating {
          margin-top: 8px;

          i {
            color: #f6b500;
            display: inline-block;
            margin-right: 2px;
          }
        }
      }
      &:hover {
        .thumb {
          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
}
