@import "../../style/scss/base/index";

.faq-container {
  display: flex;
  justify-content: center;
  min-height: 77vh;
  margin: 0;
  background-color: $inputBackground;
}

.faq-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 50px 30px;

  h1 {
    font-size: 22px;
    font-weight: bold;
    color: $blue;
  }
}

.faq-item__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 25px 0;
}

.faq-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 10px 0;
  max-width: 800px;
  width: 100%;
  background-color: $white;
  padding: 25px;
  border: none;
  border-radius: 10px;

  span {
    font-size: 16px;
    font-weight: bold;
    color: $blue;
  }

  &__icon-container {
    background-color: $blue;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border-radius: 50%;
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 12px;
    width: 12px;
    background-color: inherit;
  }

  &__icon {
    width: 15px;
    margin: 0;
    padding: 0;
    transition: transform 0.5s ease-in-out;
    display: flex;
    justify-content: center;
  }

  &__icon--active {
    transform: rotate(180deg);
  }

  &__question {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    margin: 10px 0 0 50px;
  }
}

@media (max-width: 600px) {
  .faq-wrapper {
    h1 {
      text-align: center;
    }
  }

  .faq-item__question {
    flex-direction: column;
  }
  .faq-item__icon-container {
    margin: 10px 0;
    order: 2;
  }
  .faq-item__content {
    margin: 0;
  }
}
