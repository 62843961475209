@import "../../style/scss/base/vars";

.error-box {
  &__wrapper {
    display: flex;
    width: 100%;
  }

  &__title {
    font-size: 30px;
    font-weight: 600;
  }

  &__content {
    margin: 0 auto;
  }

  &--with-big-padding &__content {
    padding: 35px;
  }

  &--red {
    &, h1, h2, h3, h4, h5, h6, p {
      color: $error !important;
    }
  }
}
