$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1200px;
$value_eight: 1355px;
$value_nine: 1550px;

/* Max width 767px */
@media only #{$media} and ($feature_max : $value_two) {
  body {
    font-size: 14.5px;
  }
  .container {
    max-width: 100%;
  }
  p {
    font-size: 14.5px;
    line-height: 1.7;
  }
  .ptb-100 {
    padding: {
      top: 60px;
      bottom: 60px;
    }
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-70 {
    padding: {
      top: 30px;
      bottom: 30px;
    }
  }
  .pt-70 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
  .default-btn {
    font-size: 13px;
    padding-left: 45px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    i {
      left: 20px;
    }
  }
  .section-title {
    max-width: 100%;
    margin: {
      bottom: 40px;
      left: 0;
      right: 0;
    }
    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      max-width: 100%;
      font-size: 25px;
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
        top: 10px;
      }
    }
  }
  .mtb-100 {
    margin: {
      top: 0;
      bottom: 0;
    }
  }
  .form-control {
    height: 48px;
    font-size: 14.5px;
  }

  .main-banner {
    padding-top: 70px;
    .container-fluid {
      max-width: 100%;
    }
  }
  .main-banner-content {
    max-width: 100%;
    text-align: center;
    top: 0;
    padding-right: 0;

    p {
      max-width: 100%;
      font: {
        weight: 500;
        size: 15px;
      }
    }
    h1 {
      margin-bottom: 15px;
      font-size: 28px;
    }
    .default-btn {
      margin-top: 5px;
    }
  }
  .main-banner-courses-list {
    .row {
      .col-lg-6 {
        &:nth-child(1) {
          .single-courses-box {
            margin-top: 0;
          }
        }
      }
    }
    .single-courses-box {
      margin-top: 30px !important;
    }
  }
  .banner-section {
    padding: {
      top: 100px;
      bottom: 80px;
    }
    .container-fluid {
      max-width: 100%;
    }
  }

  .banner-inner-area {
    .row {
      .col-lg-4 {
        &:last-child {
          .single-banner-box {
            border-bottom: none;
          }
        }
      }
    }
  }
  .single-banner-box {
    padding: 25px;
    text-align: center;
    border-radius: 0 !important;
    border: {
      right: none;
      bottom: 1px solid #eeeeee;
    }
    .icon {
      position: relative;
      left: 0;
      top: 0;
      transform: unset;
      font-size: 35px;
      margin-bottom: 15px;
    }
    h3 {
      margin-bottom: 8px;
      font-size: 18px;
    }
  }

  .boxes-area {
    padding-top: 30px;
  }
  .single-box-item {
    top: 0;
    padding: 55px 20px 25px;
    margin: {
      bottom: 0;
      top: 30px;
    }
    .icon {
      font-size: 50px;
      margin-bottom: 50px;
    }
    h3 {
      font-size: 18px;
    }
  }

  .partner-area {
    padding-bottom: 20px;

    &.ptb-70 {
      padding-top: 50px;
    }
  }

  .single-features-box {
    padding: 20px 15px;

    .icon {
      font-size: 40px;
    }
    h3 {
      margin-bottom: 12px;
      font-size: 18px;
    }
    .link-btn {
      margin-top: 3px;
      font-size: 14.5px;
    }
    &:hover {
      transform: translateY(0);
    }
    &.without-padding {
      text-align: center;
    }
  }

  .about-image {
    .row {
      .col-lg-6 {
        &:nth-child(3) {
          .image {
            margin-top: 0;
          }
        }
      }
    }
  }
  .about-content {
    padding-left: 0;
    text-align: center;
    margin-top: 30px;

    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      font-size: 25px;
    }
    .features-list {
      margin-top: -10px;

      li {
        span {
          font-size: 16px;
          padding: {
            left: 0;
            top: 0;
            bottom: 0;
          }
          i {
            position: relative;
            left: 0;
            top: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 20px;
            display: block;
            margin: 0 auto 5px;
          }
        }
      }
    }
    .default-btn {
      margin-top: 25px;
    }
  }
  .about-area-two {
    &.pt-70 {
      padding-top: 60px;
    }
  }
  .about-content-box {
    padding-left: 0;
    text-align: center;

    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      font-size: 25px;
    }
  }
  .about-video-box {
    margin: {
      top: 30px;
      left: 0;
    }
    .video-btn {
      width: 65px;
      height: 65px;
      font-size: 30px;
      line-height: 65px;
    }
  }
  .about-img {
    margin-top: 30px;

    .image {
      text-align: center;
      padding-bottom: 0;

      img {
        width: 100%;

        &:nth-child(2) {
          position: relative;
          left: 0;
          bottom: 0;
          margin-top: 25px;
        }
      }
    }
  }
  .divider {
    display: none;
  }
  .shape1,
  .shape2,
  .shape3,
  .shape4,
  .shape5,
  .shape6,
  .shape7,
  .shape8,
  .shape9,
  .shape10,
  .shape11,
  .shape12,
  .shape13,
  .shape14,
  .shape15,
  .shape16,
  .shape17,
  .shape18,
  .shape19,
  .shape20,
  .shape21,
  .shape22,
  .shape23 {
    display: none;
  }

  .slogan-image {
    padding-right: 0;
    margin: {
      top: 0;
      bottom: 30px;
    }
  }
  .slogan-content {
    padding-left: 0;
    text-align: center;

    p {
      font-size: 17px;
    }
    h3 {
      font-size: 18px;
    }
    span {
      font-size: 14px;
      margin-top: 6px;
    }
    &::before {
      right: 0;
      left: 0;
      top: 0;
      font-size: 160px;
    }
  }
  .divider2,
  .divider3 {
    display: none;
  }

  .courses-area {
    &.pt-100 {
      &.pb-70 {
        padding-bottom: 60px;

        &.bg-f5f7fa {
          padding-bottom: 30px;
        }
      }
    }
  }
  .single-courses-box {
    .courses-image {
      .price {
        width: 50px;
        height: 50px;
        right: 15px;
        bottom: -25px;
        line-height: 52px;
        font-size: 18px;
      }
    }
    .courses-content {
      padding: 20px;

      h3 {
        font-size: 18px;
      }
      .courses-box-footer {
        li {
          font-size: 14px;
        }
      }
    }
    &.without-boxshadow {
      box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    }
  }
  .courses-info {
    margin-top: 5px;

    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
  .single-courses-item {
    .courses-content {
      padding: 20px;

      .fav {
        font-size: 20px;
      }
      .price {
        font-size: 20px;
        margin-bottom: 6px;
      }
      h3 {
        font-size: 18px;
      }
      .courses-content-footer {
        padding-right: 0;

        li {
          font-size: 14px;
        }
      }
    }
  }
  .courses-slides {
    &.owl-theme {
      .owl-dots {
        margin: {
          top: 10px !important;
          bottom: 30px;
        }
      }
      .owl-nav {
        display: none;
      }
    }
  }
  .single-courses-item-box {
    .courses-image {
      .fav {
        font-size: 25px;
      }
      .price {
        width: 50px;
        height: 50px;
        bottom: -25px;
        line-height: 52px;
        font-size: 18px;
      }
    }
    .courses-content {
      h3 {
        font-size: 18px;
      }
    }
  }

  .shorting-menu {
    margin-bottom: 20px;

    .filter {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  .single-categories-box {
    .content {
      h3 {
        font-size: 20px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .categories-btn-box {
    margin-top: 10px;
  }

  .single-advisor-box {
    .advisor-image {
      top: 0;
      margin-bottom: 0;

      img {
        width: 100%;
        display: inline-block !important;
      }
    }
    .advisor-content {
      padding: 25px 20px;

      h3 {
        font-size: 18px;
      }
      .sub-title {
        font-size: 13.5px;
      }
    }
  }
  .advisor-slides {
    &.owl-theme {
      .owl-dots {
        margin-top: 10px !important;
      }
    }
  }
  .owl-item {
    .single-advisor-box {
      margin-top: 0;
    }
  }
  .single-advisor-item {
    .advisor-image {
      .social-link {
        bottom: 10px;

        li {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .advisor-content {
      h3 {
        font-size: 19px;
      }
      span {
        font-size: 13.5px;
      }
    }
  }
  .advisor-slides-two {
    &.owl-theme {
      .owl-dots {
        margin-top: 10px !important;
      }
    }
  }

  .feedback-content {
    text-align: center;

    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      font-size: 25px;
    }
    .feedback-slides {
      margin-bottom: 20px;
    }
  }
  .single-feedback-item {
    text-align: left;
    padding: 25px;

    &::after {
      display: none;
    }
    p {
      font-size: 15px;
    }
    &::before {
      font-size: 60px;
      right: 25px;
      bottom: 60px;
    }
  }
  .feedback-slides {
    &.owl-theme {
      .owl-nav {
        &.disabled + .owl-dots {
          right: 0;
          top: 0;
          transform: unset;
          bottom: 0;
          left: 0;
          position: relative;
          margin-top: 30px;
        }
      }
      .owl-dots {
        .owl-dot {
          display: inline-block;

          span {
            margin: 0 3px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
  .feedback-info {
    margin-top: 0;

    p {
      margin-top: 0;
    }
  }
  .single-testimonials-item {
    padding-left: 0;

    .client-img {
      position: relative;
      left: 0;
      width: 120px !important;
      height: 120px;
      top: 0;
      transform: translateY(0);
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
    }
    .shape-img {
      display: none;
    }
    p {
      font-size: 16px;
      line-height: 1.8;
    }
  }
  .testimonials-slides {
    &.owl-theme {
      .owl-dots {
        margin-top: 0 !important;
      }
    }
  }
  .single-feedback-box {
    padding: 25px;

    &::before {
      right: 20px;
      bottom: 50px;
      font-size: 70px;
    }
  }
  .feedback-slides-two {
    &.owl-theme {
      .owl-dots {
        margin-top: 10px !important;
      }
    }
  }

  .funfacts-list {
    .single-funfacts-box {
      margin: {
        bottom: 0 !important;
        top: 30px !important;
      }
    }
  }
  .single-funfacts-box {
    padding: {
      top: 40px;
      bottom: 40px;
      left: 20px;
      right: 20px;
    }
    h3 {
      font-size: 30px;

      span {
        font-size: 35px;
      }
    }
    p {
      font-size: 13px;
    }
  }
  .funfacts-area {
    .col-lg-3 {
      border: {
        right: none;
      }
      &:first-child {
        .single-funfacts-item {
          padding-top: 60px;
        }
      }
      &:last-child {
        .single-funfacts-item {
          border-bottom: none;
          padding-bottom: 60px;
        }
      }
    }
  }
  .single-funfacts-item {
    border-bottom: 1px solid #eaecf0;
    padding: {
      top: 30px;
      bottom: 30px;
    }
    h3 {
      font-size: 30px;

      span {
        font-size: 35px;
      }
    }
    p {
      font-size: 13px;
    }
  }
  .funfacts-area-two {
    border: none;
    .col-lg-3 {
      border-right: none;

      &:first-child {
        .single-funfacts {
          padding-top: 0;
        }
      }
      &:last-child {
        .single-funfacts {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
  .single-funfacts {
    border-bottom: 1px solid #eaecf0;
    padding: {
      top: 30px;
      bottom: 30px;
    }
    img {
      display: none;
    }
    h3 {
      font-size: 30px;

      span {
        font-size: 35px;
      }
    }
    p {
      font-size: 13px;
    }
  }

  .video-box {
    max-width: 100%;
    margin: {
      left: 0;
      right: 0;
      top: 60px;
    }
    .video-btn {
      width: 65px;
      height: 65px;
      font-size: 30px;
      line-height: 65px;
    }
  }

  .get-instant-courses-content {
    padding: {
      left: 20px;
      right: 20px;
      top: 30px;
      bottom: 30px;
    }
    .sub-title {
      font-size: 13px;
    }
    h2 {
      font-size: 25px;
    }
  }

  .single-blog-post {
    .post-content {
      .category {
        font-size: 12px;
      }
      h3 {
        font-size: 18px;
      }
      .post-content-footer {
        margin-top: 15px;
      }
    }
  }
  .blog-post-info {
    padding-top: 25px;

    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
  .single-blog-post-item {
    .post-content {
      .category {
        font-size: 12px;
      }
      h3 {
        font-size: 18px;
      }
      .post-content-footer {
        margin-top: 15px;
      }
    }
  }
  .single-blog-post-box {
    .post-content {
      padding: 20px;

      .category {
        font-size: 12px;
      }
      h3 {
        font-size: 18px;
      }
      .post-content-footer {
        margin-top: 15px;

        li {
          font-size: 14px;

          .post-author {
            img {
              display: none;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .blog-slides {
    &.owl-theme {
      .owl-dots {
        margin: {
          top: 10px !important;
          bottom: 30px;
        }
      }
      .owl-nav {
        display: none;
      }
    }
  }

  .view-all-courses-area {
    padding-top: 60px;
  }
  .view-all-courses-content {
    text-align: center;

    .sub-title {
      font-size: 13px;
    }
    h2 {
      font-size: 25px;
    }
  }
  .view-all-courses-image {
    margin: {
      top: 30px;
      left: 0;
    }
  }
  .view-all-courses-area-two {
    &.ptb-70 {
      padding: {
        top: 60px;
        bottom: 60px;
      }
    }
  }

  .premium-access-content {
    max-width: 100%;
    margin: {
      left: 0;
      right: 0;
    }
    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      max-width: 100%;
      font-size: 25px;
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
        top: 10px;
      }
    }
  }

  .subscribe-content {
    max-width: 100%;
    margin: {
      left: 0;
      right: 0;
    }
    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      max-width: 100%;
      font-size: 25px;
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
        top: 10px;
      }
    }
    .newsletter-form {
      .input-newsletter {
        height: 48px;
        padding-left: 15px;
        font-size: 14px;
      }
      .default-btn {
        position: relative;
        border-radius: 5px;
        height: auto;
        margin-top: 15px;

        i {
          top: 10px;
        }
      }
    }
  }

  @import "_breadcrumbs/_responsive";
  @import "_page-title/_responsive";

  .single-testimonials-box {
    max-width: 100%;
    padding-right: 0;

    .client-img {
      margin-bottom: 35px;
    }
    p {
      font-size: 18px;
      line-height: 1.6;
    }
    .shape-img {
      img.shape-2 {
        display: none !important;
      }
    }
    &::before {
      bottom: -15px;
      font-size: 100px;
    }
  }

  .our-story-title {
    h3 {
      font-size: 18px;
      padding-left: 22px;

      .number {
        top: 1px;
        font-size: 20px;
      }
    }
  }
  .our-story-content {
    padding-right: 0;
  }
  .our-story-area {
    .col-lg-4,
    .col-lg-8 {
      margin-top: 20px;
    }
    .col-lg-8 {
      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
  .our-story-image {
    margin-top: 20px;
  }

  .start-with-success-area {
    .section-title {
      h2 {
        max-width: 100%;
      }
    }
  }
  .start-with-success-box {
    .content {
      padding: 25px 20px;

      h3 {
        font-size: 18px;
        margin-bottom: 7px;
      }
      span {
        font-size: 14px;
      }
      .link-btn {
        width: 50px;
        height: 50px;
        right: 20px;
        top: -25px;
        font-size: 25px;
      }
    }
  }

  .faq-accordion-tab {
    .react-tabs__tab-list {
      margin-bottom: 20px;

      .react-tabs__tab {
        margin-bottom: 20px;

        padding: 15px;
        font-size: 14px;

        i {
          font-size: 25px;
        }
      }
    }
  }
  .faq-accordion {
    .accordion {
      .accordion-title {
        padding: 15px 15px 15px 43px;
        font-size: 14px;

        i {
          left: 15px;
          top: 50%;
          font-size: 20px;
          transform: translateY(-50%);
        }
      }
      .accordion-content {
        padding: {
          bottom: 15px;
          right: 15px;
          left: 42px;
        }
      }
    }
  }

  .contact-info {
    text-align: center;

    .sub-title {
      font-size: 13px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 25px;
    }
    ul {
      li {
        padding: {
          right: 0;
          left: 0;
        }
        .icon {
          width: 60px;
          height: 60px;
          left: 0;
          position: relative;
          font-size: 30px;
          top: 0;
          margin: {
            left: auto;
            bottom: 17px;
            right: auto;
          }
        }
        h3 {
          font-size: 18px;
        }
        p {
          max-width: 100%;
        }
      }
    }
  }
  .contact-form {
    text-align: center;
    padding: 25px 15px;
    margin: {
      left: 0;
      top: 30px;
    }
    h2 {
      font-size: 25px;
    }
    form {
      input,
      textarea {
        height: 48px;
        font-size: 14.5px;
      }
    }
  }

  .login-form {
    padding: 30px 20px;
    margin-right: 0;

    h2 {
      margin-bottom: 20px;
      text-align: center;
      font-size: 25px;
    }
    form {
      .lost-your-password-wrap {
        text-align: left;
        margin-top: 15px;
      }
      button {
        padding: 13px 30px;
        font-size: 15px;
      }
    }
  }
  .register-form {
    margin-top: 40px;
    border-top: 1px solid #eeeeee;
    padding: {
      left: 0;
      top: 30px;
    }
    h2 {
      margin-bottom: 20px;
      text-align: center;
      font-size: 25px;
    }
    form {
      .lost-your-password-wrap {
        text-align: left;
        margin-top: 15px;
      }
      button {
        padding: 13px 30px;
        font-size: 15px;
      }
    }
  }

  .error-area {
    height: auto;
    padding: {
      top: 150px;
      bottom: 150px;
    }
  }
  .error-content {
    h3 {
      font-size: 25px;
    }
    p {
      max-width: 100%;
    }
    .btn-box {
      .default-btn {
        margin: {
          left: 3px;
          right: 3px;
        }
      }
    }
  }

  .coming-soon-area {
    height: 100%;
    padding: {
      top: 120px;
      bottom: 120px;
      left: 15px;
      right: 15px;
    }
  }
  .coming-soon-content {
    padding: 30px 20px;

    h2 {
      margin-top: 25px;
      font-size: 25px;
    }
    #timer {
      margin-top: 10px;

      div {
        width: 95px;
        height: 100px;
        font-size: 35px;
        margin-top: 20px;

        span {
          font-size: 14px;
        }
      }
    }
    form {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .purchase-guide-content {
    img {
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 12px;
      font-size: 18px;
    }
    ul,
    ol {
      li {
        font-size: 14.5px;
      }
    }
    .blockquote,
    blockquote {
      p {
        font-size: 15px !important;
      }
    }
  }

  .privacy-policy-content {
    img {
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 12px;
      font-size: 18px;
    }
    ul,
    ol {
      li {
        font-size: 14.5px;
      }
    }
    .blockquote,
    blockquote {
      p {
        font-size: 15px !important;
      }
    }
  }

  .terms-of-service-content {
    img {
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 12px;
      font-size: 18px;
    }
    ul,
    ol {
      li {
        font-size: 14.5px;
      }
    }
    .blockquote,
    blockquote {
      p {
        font-size: 15px !important;
      }
    }
  }

  .escolalms-grid-sorting {
    text-align: center;

    .result-count {
      margin-bottom: 13px;
    }
    .ordering {
      text-align: center;

      label {
        font-size: 14px;
      }
    }
  }

  .pagination-area {
    margin-top: 20px;
  }

  .load-more-btn {
    margin: {
      top: 20px;
      bottom: 0;
    }
    .load-more {
      padding: 12px 55px 12px 30px;
      font-size: 15px;

      i {
        right: 30px;
        font-size: 16px;
      }
    }
  }

  .courses-details-desc {
    .react-tabs__tab-list {
      padding: {
        left: 15px;
        right: 15px;
        top: 10px;
        bottom: 0;
      }
      .react-tabs__tab {
        margin: {
          right: 20px;
          bottom: 10px;
        }

        padding: 5px 0;
        font-size: 15px;
      }
    }
    .react-tabs__tab-panel {
      padding: {
        left: 0;
        right: 0;
        top: 30px;
      }
      .courses-overview {
        h3 {
          font-size: 18px;
        }
      }
      .courses-curriculum {
        padding: 20px;
        margin: {
          left: 0;
          right: 0;
        }
        h3 {
          font-size: 16px;
          margin-bottom: 15px;
        }
        ul {
          li {
            a {
              display: block !important;
              padding: {
                left: 45px;
                right: 20px;
                top: 13px;
                bottom: 13px;
              }
              margin: {
                left: -20px;
                right: -20px;
              }
              &::before {
                left: 20px;
                top: 13px;
                font-size: 15px;
              }
              .courses-name {
                font-size: 15px;
              }
              .courses-meta {
                text-align: left;
                margin-top: 5px;

                .questions {
                  font-size: 13.5px;
                  margin-top: 7px;
                }
                .duration {
                  font-size: 13.5px;
                  margin-top: 7px;
                }
                .status {
                  font-size: 13.5px;
                  margin-top: 7px;
                }
              }
            }
          }
        }
      }
      .courses-instructor {
        .single-advisor-box {
          .advisor-image {
            text-align: left;

            img {
              width: auto;
            }
          }
          .advisor-content {
            padding: 25px 0 0 0;
          }
        }
      }
      .courses-reviews {
        h3 {
          font-size: 18px;
        }
        .rating {
          top: 2px;

          span {
            font-size: 17px;
          }
        }
        .side {
          width: 20%;

          div {
            font-size: 15px;
          }
        }
        .middle {
          width: 60%;
        }
      }
      .courses-review-comments {
        h3 {
          padding-bottom: 10px;
          font-size: 18px;
        }
        .user-review {
          padding-left: 0;

          img {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 15px;
          }
          .review-rating {
            span {
              display: block !important;
              top: 0;
              font-size: 16px;
              margin: {
                left: 0;
                top: 5px;
              }
            }
          }
        }
      }
    }
  }
  .courses-details-info {
    margin: {
      left: 0;
      top: 30px;
    }
    .image {
      .content {
        i {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 20px;
        }
        span {
          font-size: 14.5px;
        }
      }
    }
    .info {
      padding: {
        left: 10px;
        right: 10px;
        top: 30px;
      }
      li {
        font-size: 15px;
        padding: {
          top: 13px;
          bottom: 12px;
        }
        &.price {
          font-size: 20px;

          span {
            font-size: 15px;
          }
        }
      }
    }
    .btn-box {
      margin-top: 30px;
      padding: {
        left: 10px;
        right: 10px;
      }
    }
    .courses-share {
      padding: {
        left: 10px;
        right: 10px;
      }
      .share-info {
        span {
          font-size: 15px;
        }
        .social-link {
          bottom: -44px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .courses-details-header {
    .courses-title {
      p {
        font-size: 15px;
      }
    }
    .courses-meta {
      ul {
        li {
          display: block;
          border-right: none;
          font-size: 14px;
          padding-right: 0;
          margin: {
            bottom: 15px;
            right: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          span {
            margin-bottom: 2px;
            font-size: 13px;
          }
        }
      }
    }
    .courses-price {
      text-align: left;
      margin-top: 22px;

      .courses-review {
        .review-stars {
          i {
            font-size: 16px;
          }
        }
      }
    }
  }
  .courses-details-desc-style-two {
    h3 {
      margin-bottom: 12px;
      font-size: 19px;
    }
    .courses-curriculum {
      padding: 20px;
      margin: {
        left: 0;
        right: 0;
      }
      h3 {
        font-size: 16px;
        margin-bottom: 15px;
      }
      ul {
        li {
          a {
            display: block !important;
            padding: {
              left: 45px;
              right: 20px;
              top: 13px;
              bottom: 13px;
            }
            margin: {
              left: -20px;
              right: -20px;
            }
            &::before {
              left: 20px;
              top: 13px;
              font-size: 15px;
            }
            .courses-name {
              font-size: 15px;
            }
            .courses-meta {
              text-align: left;
              margin-top: 5px;

              .questions {
                font-size: 13.5px;
                margin-top: 7px;
              }
              .duration {
                font-size: 13.5px;
                margin-top: 7px;
              }
              .status {
                font-size: 13.5px;
                margin-top: 7px;
              }
            }
          }
        }
      }
    }
    .why-you-learn {
      ul {
        li {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    .courses-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details {
            .author-profile-details {
              h4 {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .courses-reviews {
      h3 {
        font-size: 19px;
      }
      .rating {
        top: 2px;

        span {
          font-size: 17px;
        }
      }
      .side {
        width: 20%;

        div {
          font-size: 15px;
        }
      }
      .middle {
        width: 60%;
      }
    }
    .courses-review-comments {
      h3 {
        padding-bottom: 10px;
        font-size: 19px;
      }
      .user-review {
        padding-left: 0;

        img {
          position: relative;
          left: 0;
          top: 0;
          margin-bottom: 15px;
        }
        .review-rating {
          span {
            display: block !important;
            top: 0;
            font-size: 16px;
            margin: {
              left: 0;
              top: 5px;
            }
          }
        }
      }
    }
  }
  .related-courses {
    h3 {
      font-size: 20px;
    }
  }
  .courses-sidebar-information {
    margin-top: 40px;
    padding: 15px;

    .info {
      li {
        font-size: 15px;
        padding: {
          top: 13px;
          bottom: 12px;
        }
        &.price {
          font-size: 20px;

          span {
            font-size: 15px;
          }
        }
      }
    }
    .btn-box {
      margin-top: 30px;

      .default-btn {
        i {
          font-size: 16px;
        }
      }
    }
  }

  .membership-levels-table {
    .table {
      thead {
        th {
          h2 {
            font-size: 25px;
          }
          h3 {
            font-size: 14px;
          }
        }
      }
    }
  }

  .apply-instructor-image {
    text-align: center;

    h2 {
      margin-bottom: 20px;
      font-size: 25px;
    }
    img {
      width: 100%;
    }
  }
  .apply-instructor-content {
    padding: 10px 20px 20px;
    margin-top: 30px;

    .react-tabs__tab-list {
      margin-bottom: 30px;

      .react-tabs__tab {
        margin: {
          right: 15px;
          top: 10px;
        }
        padding: 0 0 5px;
        font-size: 15px;
      }
    }
    h3 {
      font-size: 18px;
    }
  }

  .teacher-register-box {
    max-width: 100%;
    padding: 25px;

    h2 {
      font-size: 25px;
    }
    form {
      textarea,
      input {
        height: 48px;
        font-size: 14.5px;
      }
    }
  }

  .profile-box {
    .image {
      padding-right: 0;
    }
    .content {
      padding: 25px 0 0 0;

      h3 {
        font-size: 19px;
      }
    }
  }
  .profile-courses-quizzes {
    .tab-content {
      .nav {
        margin-top: -10px;

        .nav-item {
          margin-top: 10px;
        }
      }
    }
  }

  .shorting-menu {
    &.shorting-menu-style-two {
      margin-bottom: 30px;
      padding: {
        left: 20px;
        right: 20px;
        top: 10px;
      }
      .filter {
        padding: {
          top: 0;
          bottom: 5px;
          left: 0;
          right: 0;
        }
      }
    }
  }
  .single-events-box {
    .image {
      .date {
        font-size: 14px;
      }
    }
    .content {
      h3 {
        font-size: 18px;
      }
      .location {
        font-size: 15px;
      }
    }
  }

  .events-details-image {
    &::before {
      display: none;
    }
    #timer {
      position: relative;
      top: 0;
      left: 0;
      transform: unset;

      div {
        width: 95px;
        height: 95px;
        font-size: 35px;
        margin-top: 20px;

        span {
          font-size: 14px;
          margin-top: 0px;
        }
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .events-details-header {
    margin-bottom: 30px;

    ul {
      padding: {
        left: 20px;
        right: 20px;
        top: 4px;
        bottom: 14px;
      }
      li {
        margin-top: 10px;
      }
    }
  }
  .events-details-location {
    margin-bottom: 30px;
  }
  .events-details-desc {
    padding: {
      left: 0;
      right: 0;
    }
    h3 {
      font-size: 19px;
    }
  }
  .events-details-info {
    margin-top: 30px;
    padding: 20px 15px;

    .info {
      li {
        font-size: 15px;
        padding: {
          top: 13px;
          bottom: 12px;
        }
        &.price {
          font-size: 20px;

          span {
            font-size: 15px;
          }
        }
      }
    }
  }

  .single-products-box {
    .products-content {
      h3 {
        font-size: 18px;
      }
      .price {
        font-size: 15px;
      }
    }
  }

  .cart-table {
    table {
      thead {
        tr {
          th {
            font-size: 14.5px;
            padding: {
              left: 15px;
              right: 15px;
            }
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14.5px;
            padding: {
              left: 15px;
              right: 15px;
            }
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  .cart-buttons {
    .shopping-coupon-code {
      text-align: center;

      button {
        position: relative;
        right: 0;
        top: 0;
        height: auto;
        padding: 12px 25px;
        line-height: initial;
        font-size: 15px;
        border-radius: 5px;
        margin-top: 15px;
      }
    }
    .text-right {
      text-align: center !important;
      margin-top: 15px;
    }
  }
  .cart-totals {
    padding: 20px;
    max-width: 100%;
    margin: {
      top: 40px;
    }
    h3 {
      font-size: 18px;
    }
    ul {
      margin-bottom: 20px;

      li {
        &:last-child {
          font-size: 18px;
        }
      }
    }
  }

  .billing-details {
    .title {
      font-size: 18px;
    }
  }
  .order-details {
    margin-top: 40px;

    .title {
      font-size: 18px;
    }
    .order-table {
      table {
        thead {
          tr {
            th {
              font-size: 14.5px;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 14px;
            }
          }
        }
      }
    }
    .payment-box {
      padding: 20px;
    }
  }
  .order-details .order-table table tbody tr td.order-subtotal span,
  .order-details .order-table table tbody tr td.order-shipping span,
  .order-details .order-table table tbody tr td.total-price span {
    font-size: 15px;
  }

  .products-details-desc {
    padding-left: 0;
    margin-top: 35px;

    h3 {
      font-size: 19px;
    }
    .products-review {
      .rating {
        font-size: 16px;

        .rating-count {
          top: -2px;
        }
      }
    }
    .products-add-to-cart {
      .input-counter {
        max-width: 110px;
        min-width: 110px;

        input {
          height: 47px;
          font-size: 16px;
        }
      }
      .default-btn {
        top: 0;

        i {
          top: 13px;
        }
      }
    }
  }
  .products-details-tabs {
    .react-tabs__tab-list {
      margin-bottom: 30px;

      .react-tabs__tab {
        margin: {
          left: 10px;
          right: 10px;
        }

        font-size: 16px;
      }
    }
    .react-tabs__tab-panel {
      .products-reviews {
        h3 {
          font-size: 18px;
        }
        .rating {
          top: 2px;

          span {
            font-size: 17px;
          }
        }
        .side {
          width: 20%;

          div {
            font-size: 15px;
          }
        }
        .middle {
          width: 60%;
        }
      }
      .products-review-comments {
        h3 {
          padding-bottom: 10px;
          font-size: 18px;
        }
        .user-review {
          padding-left: 0;

          img {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 15px;
          }
          .review-rating {
            span {
              display: block !important;
              top: 0;
              font-size: 16px;
              margin: {
                left: 0;
                top: 5px;
              }
            }
          }
        }
      }
      .review-form-wrapper {
        h3 {
          font-size: 18px;
        }
        form {
          button {
            margin-top: 20px;
            padding: 12px 45px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .blog-details-desc {
    .article-content {
      .entry-meta {
        ul {
          margin-bottom: -13px;

          li {
            display: inline-block;
            border-right: none;
            font-size: 15px;
            margin: {
              bottom: 13px;
              right: 13px;
            }
            padding: {
              right: 0;
            }
            &:last-child {
              margin: {
                right: 0;
              }
            }
          }
        }
      }
      h3 {
        font-size: 19px;
      }
    }
    .article-footer {
      text-align: center;

      .article-tags {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .article-share {
        flex: 0 0 100%;
        max-width: 100%;

        .social {
          text-align: center;
          margin-top: 15px;
        }
      }
    }
    .article-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details .author-profile-details h4 {
            font-size: 18px;
          }
        }
      }
    }
  }
  blockquote,
  .blockquote {
    padding: 20px !important;

    p {
      font-size: 17px !important;
    }
  }
  .prev-link-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;

    .prev-title {
      font-size: 15px;
    }
    .meta-wrapper {
      font-size: 13px;
    }
  }
  .next-link-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 30px;

    .next-title {
      font-size: 15px;
    }
    .meta-wrapper {
      font-size: 13px;
    }
  }
  .comments-area {
    .comments-title {
      font-size: 19px;
    }
    .comment-author {
      font-size: 16px;

      .avatar {
        height: 50px;
        left: 0;
        position: relative;
        width: 50px;
        display: block;
        margin-bottom: 15px;
      }
    }
    .comment-body {
      padding-left: 0;
    }
    .comment-respond {
      .comment-reply-title {
        font-size: 19px;
      }
      .comment-form-author {
        width: 100%;
        padding-right: 0;
      }
      .comment-form-email {
        width: 100%;
        padding-left: 0;
      }
      .form-submit input {
        font-size: 15px;
      }
    }
  }

  .widget-area {
    margin-top: 40px;
    padding-left: 0;

    .widget {
      .widget-title {
        font-size: 19px;
      }
    }
    .widget_insight {
      ul {
        li {
          a {
            font-size: 15px;
          }
        }
      }
    }
    .widget_recent_courses {
      .item {
        .info {
          span {
            font-size: 15px;
          }
          .title {
            font-size: 14.5px;
          }
        }
      }
    }
    .widget_escolalms_posts_thumb {
      .item {
        .info {
          .title {
            font-size: 15px;
          }
        }
      }
    }
    .widget_popular_products {
      .item {
        .info {
          span {
            font-size: 15px;
          }
          .title {
            font-size: 15px;
          }
        }
      }
    }
    .tagcloud {
      a {
        font-size: 13.5px !important;
      }
    }
  }

  .footer-area {
    padding-top: 60px;
  }
  .single-footer-widget {
    h3 {
      font-size: 20px;
    }
    &.pl-5,
    &.px-5 {
      padding-left: 0 !important;
    }
  }
  .footer-bottom-area {
    margin-top: 30px;
    text-align: center;
    padding: {
      top: 20px;
      bottom: 20px;
    }
    ul {
      text-align: center;
      margin-top: 15px;

      li {
        &::before {
          top: 3px;
        }
      }
    }
  }

  .go-top {
    right: 15px;
    width: 40px;
    height: 40px;
    font-size: 35px;
  }

  .main-banner-wrapper {
    padding: {
      top: 80px;
      bottom: 80px;
    }
    .container-fluid {
      max-width: 100%;
    }
  }
  .main-banner-wrapper-content {
    top: 0;
    text-align: center;

    h1 {
      margin-bottom: 15px;
      font-size: 30px;
    }
    p {
      font: {
        weight: 500;
        size: 15px;
      }
    }
    .default-btn {
      margin-top: 5px;
    }
  }
  .main-banner-wrapper-image {
    margin-top: 30px;
  }
  .banner-shape14,
  .banner-shape15,
  .banner-shape16,
  .banner-shape17,
  .banner-shape18 {
    display: none;
  }

  .single-language-courses-box {
    h3 {
      margin-bottom: 10px;
      font-size: 18px;
    }
    p {
      padding: {
        left: 0;
        right: 0;
      }
    }
    .default-btn {
      margin-top: 5px;
    }
  }

  .single-feedback-item-box {
    .client-info {
      .title {
        h3 {
          font-size: 18px;
        }
      }
    }
    &::after {
      right: 40px;
      bottom: 125px;
      font-size: 71px;
    }
  }
  .feedback-slides-style-two {
    &.feedback-slides {
      &.owl-theme {
        max-width: 100%;
        margin-left: 0;

        .single-feedback-item-box {
          padding: 35px 25px 80px;
        }
        .owl-nav.disabled + .owl-dots {
          left: 0;
          right: 0;
          bottom: 25px;
          position: absolute;
        }
      }
    }
  }

  .information-content {
    text-align: center;

    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      font-size: 25px;
      margin-bottom: 40px;
    }
    .apply-details {
      text-align: left;

      li {
        padding-left: 80px;

        h3 {
          margin-bottom: 8px;
          font-size: 18px;
        }
        .icon {
          width: 60px;
          height: 60px;
          line-height: 60px;
          top: 2px;
          transform: translateY(0);
          font-size: 25px;
        }
      }
    }
  }
  .information-image {
    margin-top: 30px;
  }

  .free-trial-form {
    margin-top: 30px;
    text-align: center;
    padding: 30px 20px;

    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      font-size: 25px;
    }
    form {
      .form-group {
        margin-bottom: 15px;
      }
      button {
        font-size: 15px;
      }
    }
  }

  .newsletter-modal {
    .newsletter-modal-content {
      max-width: 100%;
      margin: {
        left: 15px;
        right: 15px;
      }
      .modal-image {
        display: none;
      }
      .modal-inner-content {
        padding: 30px 25px;

        .sub-text {
          line-height: 1.6;
          font-size: 15px;
        }
        h2 {
          margin-bottom: 15px;
          font-size: 25px;
        }
        form {
          .input-newsletter {
            font-size: 14px;
          }
          button {
            height: auto;
            position: relative;
            font-size: 14px;
            display: block;
            width: 100%;
            margin-top: 15px;
          }
        }
      }
    }
  }

  .hero-banner-area {
    padding: {
      top: 60px;
      bottom: 60px;
    }
    .container-fluid {
      max-width: 100%;
    }
  }
  .hero-banner-content {
    text-align: center;

    p {
      padding-right: 0;
      font: {
        weight: 500;
        size: 15px;
      }
    }
    h1 {
      margin-bottom: 15px;
      line-height: 1.3;
      font-size: 28px;
    }
    .default-btn {
      margin-top: 5px;
    }
  }
  .hero-banner-image {
    margin-top: 30px;
  }
  .banner-shape19 {
    display: none;
  }

  .boxes-area {
    &.boxes-style-two {
      .single-box-item {
        top: 0;
        margin-bottom: 0;
      }
    }
  }

  .single-courses-item {
    &.without-box-shadow {
      .courses-image {
        img {
          border-radius: 5px;
        }
        &::before {
          border-radius: 5px;
        }
      }
    }
  }

  .overview-box {
    margin-top: 45px;

    .overview-content,
    .overview-image {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .overview-content {
      order: 2;
      text-align: center;

      .sub-title {
        font-size: 12.5px;
      }
      h2 {
        font-size: 25px;
      }
      .btn-box {
        .playstore-btn {
          font-size: 10px;
          text-align: left;
          padding: {
            bottom: 5px;
            left: 45px;
            right: 10px;
            top: 7px;
          }
          img {
            left: 10px;
          }
          span {
            font-size: 14px;
          }
        }
        .applestore-btn {
          font-size: 10px;
          text-align: left;
          margin-left: 8px;
          padding: {
            bottom: 5px;
            left: 55px;
            right: 10px;
            top: 7px;
          }
          img {
            left: 10px;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
    .overview-image {
      order: 1;
      margin-bottom: 35px;
    }
  }

  .yoga-main-banner {
    padding: {
      top: 60px;
      left: 0;
      bottom: 60px;
    }
  }
  .yoga-banner-content {
    max-width: 100%;

    .main-image {
      display: none;
    }
    &::before {
      display: block;
    }
    .content {
      padding: 30px 20px;
      position: relative;
      top: 0;
      transform: unset;

      .top-image {
        display: none;
      }
      h1 {
        margin-bottom: 15px;
        line-height: 1.3;
        font-size: 28px;
      }
      .default-btn {
        margin-top: 5px;
      }
      .bottom-image {
        display: none;
      }
    }
  }

  .single-training-box {
    h3 {
      margin-bottom: 13px;
      font-size: 19px;
    }
  }
  .tree-shape,
  .tree-shape2,
  .tree-shape3 {
    display: none;
  }

  .single-yoga-courses-box {
    .courses-content {
      h3 {
        margin-bottom: 10px;
        font-size: 19px;
      }
    }
  }

  .experience-area {
    &.extra-padding {
      padding-top: 60px;
    }
  }
  .experience-content {
    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      font-size: 25px;
    }
  }
  .experience-image {
    margin: {
      top: 35px;
    }
    padding: {
      left: 10px;
      top: 10px;
    }
    &::before {
      margin-right: 10px;
    }
    &::after {
      margin: {
        left: 10px;
        top: 10px;
      }
    }
    .video-btn {
      width: 60px;
      height: 60px;
      font-size: 30px;
      line-height: 60px;
    }
    .title {
      font-size: 20px;
      padding: {
        top: 12px;
        bottom: 10px;
        left: 25px;
        right: 30px;
      }
    }
  }

  .download-syllabus-form {
    padding: 20px;

    h2 {
      font-size: 25px;
    }
    form {
      .form-group {
        .nice-select {
          font-size: 14px;
        }
      }
    }
    .syllabus-shape1,
    .syllabus-shape2,
    .syllabus-shape3 {
      display: none;
    }
  }

  .single-pricing-box {
    .pricing-header {
      h3 {
        font-size: 18px;
        padding-top: 15px;
      }
    }
    .pricing-features {
      ul {
        li {
          font-size: 15.5px;
        }
      }
    }
    .price {
      font-size: 25px;

      span {
        margin-top: 0;
        font-size: 14px;
      }
    }
  }

  .subscribe-image {
    margin-bottom: 35px;
  }

  .health-coaching-banner-area {
    margin-bottom: -25px;
    padding-top: 80px;

    .col-lg-6 {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
    .divider {
      display: block;
      bottom: 25px;
      height: 70px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #f5f7fa;
      z-index: 1;
      width: 100%;
      height: 25px;
    }
  }
  .health-coaching-banner-content {
    text-align: center;
    margin-bottom: 35px;

    h1 {
      font-size: 27px;
      margin-bottom: 18px;

      br {
        display: none;
      }
    }
    p {
      font: {
        weight: 500;
        size: 15px;
      }
    }
    .btn-box {
      margin-top: 20px;

      .d-flex {
        justify-content: center;
      }
      img {
        margin-left: 15px;
        max-width: 90px;
      }
    }
  }
  .health-coaching-shape1,
  .health-coaching-shape2,
  .health-coaching-shape3,
  .health-coaching-shape4,
  .health-coaching-shape5,
  .health-coaching-shape6,
  .health-coaching-shape7 {
    display: none;
  }

  .single-lifestyle-box {
    padding: 25px 15px;

    .icon {
      width: 65px;
      height: 65px;
      line-height: 65px;
      font-size: 30px;
      margin-bottom: 20px;
    }
    h3 {
      margin-bottom: 15px;
      font-size: 18px;
    }
  }

  .program-section-title {
    padding-right: 0;
    text-align: center;

    h2 {
      font-size: 25px;
    }
  }
  .program-list {
    margin: {
      left: 0;
      top: 30px;
    }
    .program-circle-shape {
      display: none;
    }
    .single-program-box {
      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }
  }
  .single-program-box {
    margin-bottom: 30px;

    h3 {
      margin-bottom: 15px;
      font-size: 18px;
    }
    .icon {
      font-size: 45px;
      margin-bottom: 18px;
    }
  }

  .single-health-services-box {
    text-align: center;
    padding-left: 0;

    .icon {
      position: relative;
      top: 0;
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      margin: {
        left: auto;
        right: auto;
        bottom: 20px;
      }
    }
    h3 {
      font-size: 18px;
    }
  }

  .experience-content {
    .shape {
      display: none;
    }
  }
  .experience-img {
    margin: {
      left: 0;
      top: 30px;
    }
    .title {
      font-size: 22px;
    }
  }
  .experience-shape1,
  .experience-shape2 {
    display: none;
  }

  .single-events-box-item {
    .content {
      h3 {
        font-size: 18px;
      }
      .location {
        font-size: 15px;
      }
    }
  }

  .subscribe-area-two {
    padding-top: 60px;

    .col-lg-6 {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
    .subscribe-content {
      max-width: 100%;
      text-align: center;
    }
    .subscribe-image {
      margin: {
        bottom: 0;
        top: 30px;
      }
    }
  }

  .health-coaching-feedback-image {
    margin-right: 0;
  }
  .health-coaching-inner {
    max-width: 100%;
    margin-top: 35px;
    padding: {
      left: 15px;
      right: 15px;
      bottom: 60px;
    }
    h2 {
      margin-bottom: 40px;
      font-size: 25px;
    }
  }
  .object1,
  .object2,
  .object3,
  .object4 {
    display: none;
  }
  .feedback-quote {
    padding: 20px;

    p {
      font-size: 15px;
    }
    .client-info {
      margin-top: 20px;

      .title {
        h3 {
          font-size: 16px;
        }
      }
    }
  }

  // .navbar-area {
  //   &.is-sticky {
  //     &.pb-0 {
  //       padding-bottom: 20px !important;
  //     }
  //   }
  // }

  .default-btn-style-two {
    font-size: 14px;
    padding: {
      left: 50px;
      right: 25px;
      top: 13px;
      bottom: 13px;
    }
    i {
      left: 25px;
      top: 13px;
    }
    &::before {
      margin: 2px;
    }
  }

  .kindergarten-main-banner {
    padding-top: 100px;

    .container-fluid {
      padding: {
        left: 15px;
        right: 15px;
      }
    }
  }
  .kindergarten-banner-content {
    h1 {
      margin-bottom: 15px;
      line-height: 1.2;
      font-size: 30px;

      span {
        display: inline;
        font-size: 30px;
      }
    }
    .image {
      margin-bottom: 20px;
    }
    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
      }
    }
    .circle-shape {
      position: absolute;
      left: 0;
      right: 0;
      top: 15px;
      display: none;
      z-index: -1;
    }
    .default-btn-style-two {
      margin-top: 5px;
    }
  }
  .kindergarten-banner-image {
    margin-top: 30px;
  }
  .kindergarten-shape1,
  .kindergarten-shape2,
  .kindergarten-shape3,
  .kindergarten-shape4,
  .kindergarten-shape5,
  .kindergarten-shape6,
  .kindergarten-shape7,
  .kindergarten-shape8,
  .kindergarten-shape9,
  .kindergarten-shape10,
  .kindergarten-shape11,
  .kindergarten-shape12,
  .kindergarten-shape13,
  .kindergarten-shape14,
  .kindergarten-shape15,
  .kindergarten-shape16,
  .kindergarten-shape17,
  .kindergarten-shape18,
  .kindergarten-shape19,
  .kindergarten-shape20 {
    display: none;
  }

  .kindergarten-about-image {
    margin: {
      right: 0;
      bottom: 5px;
    }
    .shape {
      display: none;
    }
    .main-image {
      padding-bottom: 0;

      img {
        width: 100%;

        &:nth-child(2) {
          position: relative;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
  .about-content {
    .about-list {
      text-align: left;

      li {
        span {
          padding: {
            left: 30px;
            top: 3px;
          }
          i {
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 18px;
          }
        }
      }
    }
  }

  .kindergarten-services-area {
    padding-bottom: 60px;

    &::before {
      display: none;
    }
  }
  .single-kindergarten-services-box {
    background-color: #ffffff;
    padding: {
      left: 15px;
      right: 15px;
      top: 25px;
      bottom: 25px;
    }
    img {
      display: none !important;
    }
    .content {
      position: relative;
      top: 0;
      padding: {
        left: 0;
        right: 0;
      }
      h3 {
        font-size: 19px;
      }
      .icon {
        font-size: 40px;
        margin-bottom: 20px;
      }
    }
  }
  .kids-kite-image {
    display: none;
  }
  .services-slides {
    &.owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 5px;
            right: 5px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-kindergarten-courses-box {
    .courses-image {
      &::before {
        display: none;
      }
      .price {
        width: 50px;
        height: 50px;
        right: 15px;
        bottom: -25px;
        line-height: 52px;

        span {
          font-size: 15px;
        }
      }
    }
    .courses-content {
      padding: 20px;

      h3 {
        font-size: 18px;
      }
    }
  }
  .courses-slides-two {
    &.owl-theme {
      .owl-nav {
        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 5px;
            right: 5px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-kindergarten-feedback-item {
    background-color: #ffffff;
    padding: {
      left: 15px;
      right: 15px;
      top: 25px;
      bottom: 25px;
    }
    .content {
      img {
        display: none;
      }
      p {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        padding: 0;
      }
    }
    .client-info {
      margin: {
        top: 15px;
        left: 0;
      }
      h3 {
        font-size: 16px;
        color: #000000;
      }
      span {
        color: #000000;
      }
    }
  }
  .feedback-slides-three {
    &.owl-theme {
      .owl-nav {
        margin-bottom: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 5px;
            right: 5px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-selected-ages-box {
    h3 {
      padding-top: 9px;
      font-size: 20px;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }

  .events-area {
    &.bg-shape {
      padding: {
        top: 100px;
        bottom: 70px;
      }
    }
  }
  .events-box {
    .image {
      background-image: unset !important;
      height: auto;

      img {
        display: inline-block;
      }
    }
    .content {
      padding: 20px;

      h3 {
        font-size: 18px;
      }
      .location {
        font-size: 15px;
      }
    }
  }

  .single-blog-item {
    .post-image {
      &::before {
        display: none;
      }
    }
    .post-content {
      padding-top: 20px;

      .category {
        font-size: 13px;
      }
      h3 {
        font-size: 18px;
      }
    }
  }

  .subscribe-content {
    &.text-left {
      text-align: center !important;
    }
  }

  .escolalms-nav {
    .navbar {
      .navbar-nav {
        .nav-item {
          .col {
            width: 100%;
            flex-basis: inherit;
          }
        }
      }
    }
  }
  .td-sidebar {
    margin-bottom: 30px;
  }
  .border-box {
    padding: 20px;
  }

  .course-video-list {
    height: 100%;
    overflow: auto;
    margin-bottom: 15px;
    a {
      display: table-cell;
      min-width: 150px;
      margin-right: 20px;
      h4 {
        font-size: 13px;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      height: 8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .course-video-iframe {
    margin-top: 20px;
    video {
      height: 100%;
      width: 100%;
    }
  }

  /*new-css*/
  .main-banner-area {
    border-top: 1px solid #eeeeee;
    padding: {
      top: 60px;
      bottom: 60px;
    }
    .container-fluid {
      max-width: 100%;
    }
  }
  .main-banner-content-style-two {
    text-align: center;

    h1 {
      margin-bottom: 15px;
      font-size: 28px;
    }
    p {
      max-width: 100%;
      font: {
        weight: 500;
        size: 15px;
      }
    }
    .default-btn {
      margin-top: 5px;
    }
  }
  .main-banner-image-style-two {
    margin-top: 30px;
  }
  .bulb {
    display: none;
  }

  .features-box {
    text-align: center;
    padding: 20px 15px;

    h3 {
      font-size: 17px;
      margin-bottom: 12px;
    }
    .icon {
      font-size: 40px;
    }
    .link-btn {
      font-size: 14px;
    }
  }

  .testimonials-item {
    padding: 20px 15px;
    text-align: center;

    p {
      font-size: 14px;
    }
    img {
      margin-top: 25px;
    }
    span {
      font-size: 14px;
    }
    h3 {
      font-size: 17px;
    }
    &::before {
      font-size: 150px;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
  .testimonials-slides-two {
    max-width: 100%;

    &.owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          top: 0;
          left: 0;
          transform: translateY(0%);
          position: relative;
          margin: {
            left: 5px;
            right: 5px;
          }
          &.owl-next {
            right: 0;
            left: 0;
          }
        }
      }
    }
  }

  .get-instant-courses-area-two {
    padding: {
      top: 60px;
      bottom: 60px;
    }
    &::before,
    &::after {
      display: none;
    }
  }
  .get-instant-courses-content-style-two {
    text-align: center;

    .sub-title {
      font-size: 13px;
    }
    h2 {
      font-size: 25px;
    }
  }
  .get-instant-courses-image-style-two {
    margin-top: 30px;
    text-align: center;
  }

  .partner-area {
    &.ptb-100 {
      padding-bottom: 60px;
    }
  }

  .funfacts-box {
    width: auto;
    height: auto;
    border: none;
    background-color: transparent;

    .content {
      position: relative;
      transform: unset;
    }
    h3 {
      font-size: 30px;
      margin-bottom: 5px;

      span {
        font-size: 35px;
      }
    }
    p {
      font-size: 12px;
    }
  }

  .view-all-courses-area-three {
    padding: {
      top: 60px;
      bottom: 60px;
    }
  }
  .view-all-courses-image-style-two {
    text-align: center;
    margin: {
      left: 0;
      bottom: 30px;
    }
  }
  .view-all-courses-content-style-two {
    text-align: center;

    .sub-title {
      font-size: 13px;
    }
    h2 {
      font-size: 25px;
    }
  }

  .premium-access-area-two {
    padding: {
      top: 60px;
      bottom: 60px;
    }
    &::before {
      display: none;
    }
  }
  .premium-access-content-style-two {
    text-align: center;
    top: 0;

    .sub-title {
      font-size: 12.5px;
    }
    h2 {
      font-size: 25px;
    }
    p {
      margin-top: 10px;
    }
  }
  .premium-access-image-style-two {
    margin-top: 30px;
  }
}

/* Min width 576px Max width 767px */
@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {
  .escolalms-nav {
    .navbar {
      .navbar-nav {
        .nav-item {
          .col {
            width: 50%;
            flex-basis: inherit;
          }
        }
      }
    }
  }

  .container {
    max-width: 540px;
  }

  .escolalms-responsive-nav {
    .escolalms-responsive-menu {
      &.mean-container {
        .navbar-nav {
          .nav-item {
            &.megamenu {
              .dropdown-menu {
                .nav-item {
                  .row {
                    flex-wrap: wrap;
                    display: flex;

                    .col {
                      flex: 0 0 50%;
                      max-width: 50%;

                      &:nth-child(2) {
                        .megamenu-submenu {
                          li {
                            border-top: none;
                          }
                        }
                      }
                    }
                  }
                }
                .megamenu-submenu {
                  width: 70%;
                }
              }
            }
          }
        }
      }
    }
  }

  .main-banner {
    .container-fluid {
      max-width: 540px;
    }
  }
  .banner-section {
    .container-fluid {
      max-width: 540px;
    }
  }

  .funfacts-area {
    .col-lg-3 {
      &:nth-child(2) {
        .single-funfacts-item {
          padding-top: 60px;
        }
      }
      &:nth-child(3) {
        .single-funfacts-item {
          border-bottom: none;
        }
      }
    }
  }
  .funfacts-area-two {
    .col-lg-3 {
      &:nth-child(2) {
        .single-funfacts {
          padding-top: 0;
        }
      }
      &:nth-child(3) {
        .single-funfacts {
          border-bottom: none;
        }
      }
    }
  }

  .view-all-courses-area {
    .container-fluid {
      max-width: 540px;
    }
  }

  .about-img {
    .image {
      padding-bottom: 45px;

      img {
        width: auto;

        &:nth-child(2) {
          position: absolute;
          left: 40px;
          bottom: 0;
          margin-top: 0;
        }
      }
    }
  }

  .blog-details-desc {
    .article-footer {
      text-align: left;

      .article-tags {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .article-share {
        flex: 0 0 50%;
        max-width: 50%;

        .social {
          text-align: right;
          margin-top: 0;
        }
      }
    }
  }

  .main-banner-wrapper {
    .container-fluid {
      max-width: 540px;
    }
  }

  .hero-banner-area {
    .container-fluid {
      max-width: 540px;
    }
  }

  .yoga-main-banner {
    .container-fluid {
      max-width: 540px;
    }
  }

  .health-coaching-banner-area {
    margin-bottom: -35px;

    .divider {
      bottom: 35px;
    }
    &::before {
      height: 35px;
    }
  }

  .health-coaching-inner {
    max-width: 540px;
    margin: {
      left: auto;
      right: auto;
    }
  }

  .kindergarten-main-banner {
    .container-fluid {
      max-width: 540px;
    }
  }

  .kindergarten-about-image {
    .main-image {
      padding: {
        bottom: 115px;
        right: 100px;
      }
      img {
        width: auto;

        &:nth-child(2) {
          position: absolute;
          text-align: right;
          margin: {
            top: 0;
          }
        }
      }
    }
  }
  .about-content {
    .about-list {
      li {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
    }
  }
  /*new-css*/
  .main-banner-area {
    .container-fluid {
      max-width: 540px;
    }
  }
}

/* Min width 768px Max width 991px */
@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
  body {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }
  .ptb-100 {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-70 {
    padding: {
      top: 50px;
      bottom: 50px;
    }
  }
  .pt-70 {
    padding-top: 50px;
  }
  .pb-70 {
    padding-bottom: 50px;
  }
  .container {
    max-width: 720px;
  }
  .section-title {
    max-width: 650px;
    margin-bottom: 45px;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      max-width: 520px;
      font-size: 30px;
    }
    p {
      max-width: 515px;
    }
  }
  .default-btn {
    font-size: 15px;

    i {
      top: 11px;
    }
  }
  .mtb-100 {
    margin: {
      top: 0;
      bottom: 0;
    }
  }

  .main-banner {
    .container-fluid {
      max-width: 720px;
    }
  }
  .main-banner-content {
    max-width: 595px;
    top: 0;
    padding-right: 0;
    text-align: center;
    margin: 0 auto;

    h1 {
      margin-bottom: 15px;
      font-size: 35px;
    }
    p {
      max-width: 515px;
      font-size: 15px;
      margin: 0 auto 10px;
    }
  }
  .main-banner-courses-list {
    margin-top: 10px;

    .single-courses-box {
      margin-top: 30px !important;
    }
  }
  .banner-shape1,
  .banner-shape2,
  .banner-shape3,
  .banner-shape4,
  .banner-shape5,
  .banner-shape6,
  .banner-shape7,
  .banner-shape8,
  .banner-shape9,
  .banner-shape10,
  .banner-shape11,
  .banner-shape12,
  .banner-shape13 {
    display: none;
  }
  .banner-section {
    padding: {
      top: 110px;
      bottom: 100px;
    }
    .container-fluid {
      max-width: 720px;
    }
  }

  .single-banner-box {
    border-radius: 0 !important;
    padding: 25px 20px 25px 80px;

    h3 {
      font-size: 19px;
    }
    .icon {
      left: 20px;
      font-size: 40px;
    }
  }
  .banner-inner-area {
    .col-lg-4 {
      &:nth-child(2) {
        .single-banner-box {
          border-right: none;
        }
      }
      &:nth-child(3) {
        .single-banner-box {
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }

  .partner-area {
    padding-bottom: 50px;

    &.ptb-70 {
      padding-top: 80px;
    }
  }

  .single-features-box {
    padding: 25px;

    h3 {
      margin-bottom: 13px;
      font-size: 19px;
    }
    .icon {
      font-size: 35px;
      margin-bottom: 17px;
    }
    .link-btn {
      font-size: 14px;
    }
    &.without-padding {
      text-align: center;
    }
  }

  .about-image {
    .image {
      img {
        width: 100%;
      }
    }
  }
  .about-content {
    text-align: center;
    margin-top: 30px;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
    .features-list {
      li {
        flex: 0 0 25%;
        max-width: 25%;

        span {
          font-size: 16px;
          padding: {
            left: 0;
            top: 0;
            bottom: 0;
          }
          i {
            position: relative;
            top: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 20px;
            display: block;
            margin: {
              left: auto;
              right: auto;
              bottom: 10px;
            }
          }
        }
      }
    }
  }
  .shape1,
  .shape2,
  .shape3,
  .shape4,
  .shape5,
  .shape6,
  .shape7,
  .shape8,
  .shape9,
  .shape10,
  .shape11,
  .shape12,
  .shape13,
  .shape14,
  .shape15,
  .shape16,
  .shape17,
  .shape18,
  .shape19,
  .shape20,
  .shape21,
  .shape22,
  .shape23 {
    display: none;
  }
  .about-area-two {
    &.pt-70 {
      padding-top: 80px;
    }
  }
  .divider {
    display: none;
  }
  .about-content-box {
    text-align: center;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .about-video-box {
    margin: {
      left: 0;
      top: 30px;
    }
  }

  .courses-area {
    &.pt-100 {
      &.pb-70 {
        .courses-info {
          margin-bottom: 30px;
        }
      }
    }
  }
  .single-courses-box {
    .courses-content {
      padding: 20px;

      h3 {
        margin-bottom: 12px;
        font-size: 19px;
      }
    }
    &.without-boxshadow {
      box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    }
  }
  .courses-info {
    margin-top: 15px;
  }
  .single-courses-item {
    .courses-content {
      .price {
        font-size: 24px;
      }
      h3 {
        font-size: 19px;
      }
      .courses-content-footer {
        padding-right: 70px;
      }
    }
  }
  .shorting-menu {
    .filter {
      font-size: 15px;
      margin: {
        left: 8px;
        right: 8px;
      }
    }
  }
  .single-courses-item-box {
    .courses-content {
      h3 {
        font-size: 19px;
      }
    }
  }

  .boxes-area {
    .col-lg-4 {
      &:nth-child(1),
      &:nth-child(2) {
        top: -240px;
        margin: {
          bottom: -240px;
          top: 0;
        }
      }
    }
  }
  .single-box-item {
    padding: 60px 25px 30px;
    top: 0;
    margin: {
      bottom: 0;
      top: 30px;
    }
    .icon {
      font-size: 55px;
      margin-bottom: 45px;

      img {
        top: -25px;
      }
    }
    h3 {
      font-size: 19px;
    }
  }

  .feedback-content {
    text-align: center;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .single-feedback-item {
    text-align: left;
  }
  .single-testimonials-item {
    padding-left: 255px;

    p {
      font-size: 17px;
    }
    h3 {
      font-size: 19px;
    }
    span {
      font-size: 14px;
    }
  }
  .testimonials-slides {
    &.owl-theme {
      .owl-dots {
        margin-top: 5px !important;
      }
    }
  }
  .single-feedback-box {
    padding: 25px;
  }

  .funfacts-list {
    margin-top: 40px;
  }
  .single-funfacts-box {
    padding: {
      top: 80px;
      bottom: 80px;
      left: 25px;
      right: 25px;
    }
    h3 {
      font-size: 36px;

      span {
        font-size: 40px;
      }
    }
    p {
      font-size: 14px;
    }
  }
  .single-funfacts-item {
    padding: {
      top: 80px;
      bottom: 80px;
    }
    h3 {
      font-size: 36px;

      span {
        font-size: 40px;
      }
    }
    p {
      font-size: 15px;
      text-transform: capitalize;
    }
  }
  .single-funfacts {
    padding: {
      top: 80px;
      bottom: 80px;
    }
    h3 {
      font-size: 36px;

      span {
        font-size: 40px;
      }
    }
    p {
      font-size: 15px;
      text-transform: capitalize;
    }
  }

  .get-instant-courses-content {
    text-align: center;
    padding: 40px;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }

  .single-advisor-box {
    .advisor-content {
      h3 {
        font-size: 19px;
      }
      .sub-title {
        font-size: 14px;
      }
    }
  }

  .slogan-image {
    padding-right: 0;
    margin: {
      top: 0;
      bottom: 30px;
    }
  }
  .slogan-content {
    padding-left: 0;
    text-align: center;

    p {
      font-size: 18px;
    }
    h3 {
      font-size: 20px;
    }
    span {
      font-size: 14px;
    }
    &::before {
      right: 0;
      left: 0;
      top: 0;
      font-size: 160px;
    }
  }
  .divider2,
  .divider3 {
    display: none;
  }

  .single-categories-box {
    .content {
      h3 {
        font-size: 21px;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .single-advisor-item {
    .advisor-content {
      h3 {
        font-size: 19px;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .single-blog-post {
    .post-content {
      .category {
        font-size: 13px;
      }
      h3 {
        margin-bottom: 0;
        font-size: 19px;
      }
      .post-content-footer {
        margin-top: 15px;
      }
    }
  }
  .blog-post-info {
    padding-top: 25px;
  }
  .single-blog-post-item {
    .post-content {
      .category {
        font-size: 13px;
      }
      h3 {
        font-size: 19px;
      }
    }
  }
  .single-blog-post-box {
    .post-content {
      .category {
        font-size: 13px;
      }
      h3 {
        margin-bottom: 0;
        font-size: 19px;
      }
      .post-content-footer {
        margin-top: 15px;
      }
    }
  }

  .view-all-courses-area {
    padding-top: 80px;

    .container-fluid {
      max-width: 720px;
    }
  }
  .view-all-courses-content {
    max-width: 650px;
    text-align: center;
    margin: {
      left: auto;
      right: auto;
    }
    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      max-width: 520px;
      font-size: 30px;
      margin: {
        left: auto;
        right: auto;
      }
    }
    p {
      max-width: 515px;
      margin: {
        left: auto;
        right: auto;
      }
    }
  }
  .view-all-courses-image {
    margin: {
      left: 0;
      top: 30px;
    }
  }
  .view-all-courses-area-two {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }

  .premium-access-content {
    max-width: 650px;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      max-width: 520px;
      font-size: 30px;
    }
    p {
      max-width: 515px;
    }
  }

  .subscribe-content {
    max-width: 650px;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      max-width: 520px;
      font-size: 30px;
    }
    p {
      max-width: 515px;
    }
    .newsletter-form {
      max-width: 585px;
    }
  }

  .courses-details-desc {
    .react-tabs__tab-panel {
      .courses-overview {
        h3 {
          font-size: 20px;
        }
      }
      .courses-curriculum {
        h3 {
          font-size: 19px;
        }
      }
    }
  }
  .courses-details-header {
    .courses-price {
      text-align: left;
      margin-top: 25px;
    }
  }
  .courses-details-info {
    margin: {
      left: 0;
      top: 30px;
    }
  }
  .courses-details-desc-style-two {
    h3 {
      font-size: 21px;
    }
    .courses-curriculum {
      h3 {
        font-size: 17px;
      }
    }
    .courses-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details {
            .author-profile-details h4 {
              font-size: 19px;
            }
          }
        }
      }
    }
    .courses-reviews {
      h3 {
        font-size: 22px;
      }
    }
  }
  .related-courses {
    h3 {
      font-size: 22px;
    }
  }

  .single-events-box {
    .content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .events-details-image {
    #timer {
      div {
        width: 90px;
        height: 90px;
        font-size: 35px;
        margin: {
          left: 2px;
          right: 2px;
        }
        span {
          font-size: 15px;
          margin-top: -2px;
        }
      }
    }
  }
  .events-details-desc {
    h3 {
      font-size: 20px;
    }
  }
  .events-details-info {
    margin-top: 30px;
  }
  .events-details-info {
    .info {
      li {
        font-size: 15px;

        &.price {
          font-size: 22px;
        }
      }
    }
  }

  .single-products-box {
    .products-content {
      h3 {
        font-size: 18px;
      }
      .price {
        font-size: 15px;
      }
    }
  }

  .single-testimonials-box {
    max-width: 645px;
    padding-right: 50px;

    p {
      font-size: 18px;
    }
  }

  .our-story-title {
    h3 {
      padding-left: 28px;
      font-size: 22px;

      .number {
        font-size: 24px;
      }
    }
  }
  .our-story-content {
    padding-right: 0;
  }
  .our-story-area {
    .col-lg-8 {
      margin-top: 20px;

      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }

  .start-with-success-area {
    .section-title {
      h2 {
        max-width: 640px;
      }
    }
  }
  .start-with-success-box {
    .content {
      h3 {
        font-size: 19px;
        margin-bottom: 6px;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .widget-area {
    margin-top: 40px;
    padding-left: 0;

    .widget {
      .widget-title {
        font-size: 19px;
      }
    }
  }

  .cart-table {
    table {
      thead {
        tr {
          th {
            font-size: 14px;
          }
        }
      }
    }
  }
  .cart-totals {
    h3 {
      font-size: 20px;

      &:last-child {
        font-size: 18px;
      }
    }
  }

  .billing-details {
    .title {
      font-size: 19px;
    }
  }
  .order-details {
    margin-top: 30px;

    .title {
      font-size: 19px;
    }
  }

  .products-details-desc {
    margin-top: 35px;
    padding-left: 0;

    h3 {
      font-size: 19px;
    }
  }
  .products-details-tabs {
    .react-tabs__tab-list {
      .react-tabs__tab {
        font-size: 17px;
      }
    }
    .react-tabs__tab-panel {
      max-width: 645px;

      .products-reviews {
        h3 {
          font-size: 19px;
        }
      }
      .products-review-comments {
        h3 {
          font-size: 19px;
        }
      }
      .review-form-wrapper {
        h3 {
          font-size: 19px;
        }
      }
    }
  }

  .apply-instructor-image {
    h2 {
      margin-bottom: 25px;
      font-size: 30px;
      text-align: center;
    }
    img {
      width: 100%;
    }
  }
  .apply-instructor-content {
    padding: 25px;
    margin-top: 30px;

    .react-tabs__tab-list {
      .react-tabs__tab {
        margin-right: 35px;
        font-size: 17px;
      }
    }
    h3 {
      font-size: 20px;
    }
  }

  .blog-details-desc {
    .article-content {
      h3 {
        font-size: 20px;
      }
    }
    .article-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details {
            .author-profile-details {
              h4 {
                font-size: 19px;
              }
            }
          }
        }
      }
    }
  }
  blockquote,
  .blockquote {
    padding: 30px !important;

    p {
      font-size: 19px !important;
    }
  }
  .prev-link-wrapper {
    .prev-title {
      font-size: 15px;
    }
  }
  .next-link-wrapper {
    .next-title {
      font-size: 15px;
    }
  }
  .comments-area {
    .comments-title {
      font-size: 20px;
    }
    .comment-respond {
      .comment-reply-title {
        font-size: 20px;
      }
      .form-submit {
        input {
          font-size: 14px;
        }
      }
    }
  }

  .profile-box {
    .content {
      padding: 0 0 0 20px;

      h3 {
        font-size: 20px;
      }
    }
  }

  .privacy-policy-content {
    h3 {
      font-size: 19px;
    }
  }

  .terms-of-service-content {
    h3 {
      font-size: 19px;
    }
  }

  .purchase-guide-content {
    h3 {
      font-size: 19px;
    }
  }

  .teacher-register-box {
    max-width: 500px;
    padding: 35px;

    h2 {
      font-size: 30px;
    }
  }

  .coming-soon-area {
    height: auto;
    padding: {
      top: 120px;
      bottom: 120px;
    }
  }
  .coming-soon-content {
    max-width: 650px;

    h2 {
      font-size: 36px;
    }
  }

  .error-content {
    h3 {
      font-size: 36px;
    }
  }

  .login-form {
    margin-right: 0;

    h2 {
      font-size: 30px;
    }
  }
  .register-form {
    margin-top: 40px;
    padding: {
      top: 0;
      left: 0;
    }
    h2 {
      font-size: 30px;
    }
  }

  .contact-info {
    .sub-title {
      font-size: 13px;
    }
    ul {
      li {
        h3 {
          font-size: 19px;
        }
      }
    }
  }
  .contact-form {
    margin: {
      left: 0;
      top: 35px;
    }
    h2 {
      font-size: 30px;
    }
  }

  .faq-accordion-tab {
    .react-tabs__tab-list {
      margin-bottom: 30px;

      li {
        margin-bottom: 20px;
      }
    }
  }
  .faq-accordion {
    .accordion {
      .accordion-title {
        font-size: 16px;
      }
    }
  }

  .footer-area {
    padding-top: 80px;
  }
  .single-footer-widget {
    h3 {
      font-size: 21px;
      margin-bottom: 19px;
    }
  }
  .footer-bottom-area {
    margin-top: 50px;
  }

  .main-banner-wrapper {
    padding: {
      top: 100px;
      bottom: 100px;
    }
    .container-fluid {
      max-width: 768px;
    }
  }
  .main-banner-wrapper-content {
    top: 0;
    max-width: 595px;
    text-align: center;
    margin: 0 auto;

    h1 {
      margin-bottom: 15px;
      font-size: 38px;
    }
    p {
      max-width: 515px;
      font-size: 15.5px;
      margin: {
        left: auto;
        right: auto;
      }
    }
  }
  .main-banner-wrapper-image {
    margin-top: 30px;
  }
  .banner-shape14,
  .banner-shape15,
  .banner-shape16,
  .banner-shape17,
  .banner-shape18 {
    display: none;
  }

  .single-language-courses-box {
    h3 {
      margin-bottom: 10px;
      font-size: 19px;
    }
    p {
      padding: {
        left: 0;
        right: 0;
      }
    }
    .default-btn {
      margin-top: 5px;
    }
  }

  .feedback-slides-style-two {
    &.feedback-slides {
      &.owl-theme {
        max-width: 600px;
        margin-left: 0;
      }
    }
  }

  .information-content {
    text-align: center;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
    .apply-details {
      text-align: left;

      li {
        padding-left: 100px;

        h3 {
          margin-bottom: 8px;
          font-size: 19px;
        }
        .icon {
          width: 75px;
          height: 75px;
          line-height: 75px;
          font-size: 35px;
        }
      }
    }
  }
  .information-image {
    margin-top: 30px;
  }

  .free-trial-form {
    margin-top: 30px;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }

  .newsletter-modal {
    .newsletter-modal-content {
      max-width: 500px;

      .modal-image {
        display: none;
      }
      .modal-inner-content {
        padding: 40px 35px;

        h2 {
          font-size: 30px;
          margin-bottom: 12px;
        }
        .sub-text {
          font-size: 15px;
        }
      }
    }
  }

  .hero-banner-area {
    padding: {
      top: 100px;
      bottom: 200px;
    }
    .container-fluid {
      max-width: 720px;
    }
    .divider {
      display: block;
    }
  }
  .hero-banner-content {
    max-width: 595px;
    text-align: center;
    margin: 0 auto;

    h1 {
      margin-bottom: 15px;
      font-size: 35px;
    }
    p {
      max-width: 515px;
      font-size: 15px;
      padding-right: 0;
      margin: {
        left: auto;
        right: auto;
      }
    }
  }
  .hero-banner-image {
    margin-top: 30px;
  }
  .banner-shape19 {
    display: none;
  }

  .boxes-area {
    &.boxes-style-two {
      .single-box-item {
        top: 0;
        margin-bottom: 0;
      }
      .col-lg-4 {
        &:nth-child(1),
        &:nth-child(2) {
          top: -150px;
          margin: {
            bottom: -150px;
          }
        }
      }
    }
  }

  .overview-box {
    margin-top: 45px;

    .overview-content,
    .overview-image {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .overview-content {
      order: 2;
      text-align: center;

      .sub-title {
        font-size: 13.5px;
      }
      h2 {
        font-size: 30px;
      }
      .btn-box {
        .playstore-btn,
        .applestore-btn {
          text-align: left;
        }
      }
    }
    .overview-image {
      order: 1;
      margin-bottom: 40px;
    }
  }

  .yoga-main-banner {
    padding-left: 0;

    .container-fluid {
      max-width: 720px;
    }
  }
  .yoga-banner-content {
    max-width: 720px;
    margin: {
      left: auto;
      right: auto;
    }
    .content {
      padding: 100px;
      margin-top: -10px;

      .bottom-image {
        display: none;
      }
      h1 {
        font-size: 35px;
      }
      .default-btn {
        margin-top: 10px;
      }
    }
  }

  .single-training-box {
    h3 {
      font-size: 19px;
    }
  }
  .tree-shape2,
  .tree-shape3 {
    display: none;
  }

  .single-yoga-courses-box {
    .courses-content {
      h3 {
        font-size: 19px;
      }
    }
  }
  .tree-shape {
    bottom: 0;

    img {
      width: 120px;
    }
  }

  .experience-area {
    &.extra-padding {
      padding-top: 80px;
    }
  }
  .experience-content {
    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .experience-image {
    margin-top: 30px;

    .title {
      font-size: 30px;
    }
  }

  .download-syllabus-form {
    margin-top: 30px;
    padding: 40px;

    h2 {
      font-size: 30px;
    }
  }

  .single-pricing-box {
    .pricing-header {
      h3 {
        font-size: 19px;
      }
    }
    .pricing-features {
      ul {
        li {
          font-size: 16px;
        }
      }
    }
    .price {
      font-size: 30px;

      span {
        margin-top: 0;
        font-size: 15px;
      }
    }
  }

  .subscribe-image {
    margin-bottom: 35px;
  }

  .health-coaching-banner-area {
    margin-bottom: -40px;
    padding-top: 100px;

    .col-lg-6 {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
    .divider {
      bottom: 40px;
      height: 100px;
      display: block;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #f5f7fa;
      z-index: 1;
      width: 100%;
      height: 40px;
    }
  }
  .health-coaching-banner-content {
    text-align: center;
    max-width: 540px;
    margin: {
      bottom: 35px;
      left: auto;
      right: auto;
    }
    h1 {
      font-size: 40px;
      margin-bottom: 25px;

      br {
        display: none;
      }
    }
    p {
      font-size: 16px;
    }
    .btn-box {
      margin-top: 25px;

      .d-flex {
        justify-content: center;
      }
    }
  }
  .health-coaching-shape1,
  .health-coaching-shape2,
  .health-coaching-shape3,
  .health-coaching-shape4,
  .health-coaching-shape5,
  .health-coaching-shape6,
  .health-coaching-shape7 {
    display: none;
  }

  .single-lifestyle-box {
    h3 {
      font-size: 19px;
    }
    .icon {
      width: 80px;
      height: 80px;
      line-height: 80px;
      font-size: 40px;
      margin-bottom: 20px;
    }
  }

  .program-section-title {
    max-width: 555px;
    padding-right: 0;
    text-align: center;
    margin: {
      left: auto;
      right: auto;
      bottom: 40px;
    }
    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .program-list {
    margin: {
      left: 0;
      top: 30px;
    }
    .program-circle-shape {
      left: 0;
      right: 0;
      text-align: center;
    }
  }
  .single-program-box {
    .icon {
      font-size: 45px;
    }
    h3 {
      font-size: 19px;
    }
  }

  .single-health-services-box {
    text-align: center;
    padding-left: 0;

    .icon {
      position: relative;
      top: 0;
      margin: {
        left: auto;
        right: auto;
        bottom: 20px;
      }
    }
    h3 {
      font-size: 19px;
    }
  }

  .experience-img {
    margin: {
      top: 30px;
      left: 0;
    }
    .title {
      font-size: 30px;
    }
  }
  .experience-shape1,
  .experience-shape2 {
    display: none;
  }

  .single-events-box-item {
    .content {
      h3 {
        font-size: 19px;
      }
    }
  }

  .subscribe-area-two {
    padding-top: 80px;

    .col-lg-6 {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
    .subscribe-content {
      text-align: center;
      max-width: 650px;
      margin: {
        left: auto;
        right: auto;
      }
    }
    .subscribe-image {
      margin: {
        bottom: 0;
        top: 30px;
      }
    }
  }

  .health-coaching-feedback-area {
    padding-bottom: 80px;
  }
  .health-coaching-feedback-image {
    margin-right: 0;
  }
  .health-coaching-inner {
    max-width: 600px;
    margin: {
      left: auto;
      right: auto;
      top: 35px;
    }
    padding: {
      left: 15px;
      right: 15px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .feedback-quote {
    p {
      font-size: 17px;
    }
  }

  .default-btn-style-two {
    font-size: 15px;

    i {
      left: 35px;
      top: 15px;
    }
  }

  // .navbar-area.is-sticky {
  //   &.pb-0 {
  //     padding-bottom: 20px !important;
  //   }
  // }

  .kindergarten-main-banner {
    padding-top: 130px;

    .container-fluid {
      max-width: 720px;
      padding: {
        left: 15px;
        right: 15px;
      }
    }
  }
  .kindergarten-banner-content {
    max-width: 525px;
    margin: {
      left: auto;
      right: auto;
    }
    h1 {
      margin-bottom: 15px;
      line-height: 1.2;
      font-size: 45px;

      span {
        display: inline;
        font-size: 45px;
      }
    }
    .circle-shape {
      display: none;
    }
  }
  .kindergarten-banner-image {
    margin-top: 30px;
  }
  .kindergarten-shape3,
  .kindergarten-shape5,
  .kindergarten-shape7,
  .kindergarten-shape8,
  .kindergarten-shape9,
  .kindergarten-shape10,
  .kindergarten-shape11,
  .kindergarten-shape12,
  .kindergarten-shape13,
  .kindergarten-shape14,
  .kindergarten-shape15,
  .kindergarten-shape16,
  .kindergarten-shape17,
  .kindergarten-shape18,
  .kindergarten-shape19,
  .kindergarten-shape20 {
    display: none;
  }

  .kindergarten-about-image {
    margin-right: 0;

    .shape {
      right: 85px;
    }
  }
  .about-content {
    .about-list {
      text-align: left;

      li {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  .kindergarten-services-area {
    &::before {
      height: 160px;
    }
  }
  .single-kindergarten-services-box {
    background-color: #ffffff;
    padding: {
      left: 20px;
      right: 20px;
      top: 35px;
      bottom: 35px;
    }
    img {
      display: none !important;
    }
    .content {
      position: relative;
      top: 0;
      padding: {
        left: 0;
        right: 0;
      }
      .icon {
        font-size: 40px;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  .kids-kite-image {
    margin-top: -210px;
  }
  .services-slides {
    &.owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 5px;
            right: 5px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-kindergarten-courses-box {
    .courses-content {
      padding: 0 20px 20px;

      h3 {
        font-size: 20px;
      }
    }
  }
  .courses-slides-two {
    &.owl-theme {
      .owl-nav {
        margin-bottom: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 5px;
            right: 5px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-kindergarten-feedback-item {
    .client-info {
      h3 {
        font-size: 17px;
      }
    }
  }
  .feedback-slides-three {
    &.owl-theme {
      .owl-nav {
        margin-bottom: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 5px;
            right: 5px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-selected-ages-box {
    h3 {
      font-size: 22px;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }

  .events-box {
    .content {
      padding: 30px;

      h3 {
        font-size: 20px;
      }
      .location {
        font-size: 15px;
      }
    }
  }

  .single-blog-item {
    .post-content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .subscribe-content {
    &.text-left {
      text-align: center !important;
      max-width: 650px;
      margin: {
        left: auto;
        right: auto;
      }
      p {
        max-width: 515px;
        margin: {
          left: auto;
          right: auto;
        }
      }
      .newsletter-form {
        max-width: 585px;
        margin: {
          left: auto;
          right: auto;
        }
      }
    }
  }

  .course-video-list {
    height: 100%;
    overflow: auto;
    margin-bottom: 15px;
    a {
      display: table-cell;
      min-width: 200px;
      margin-right: 20px;
      h4 {
        font-size: 13px;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      height: 8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .course-video-iframe {
    margin-top: 30px;
    video {
      height: 100%;
      width: 100%;
    }
  }
  /*new-css*/
  .main-banner-area {
    border-top: 1px solid #eeeeee;
    padding: {
      top: 80px;
      bottom: 80px;
    }
    .container-fluid {
      max-width: 720px;
    }
  }
  .main-banner-content-style-two {
    text-align: center;

    h1 {
      margin-bottom: 15px;
      font-size: 37px;
    }
    p {
      margin: 0 auto 10px;
      max-width: 515px;
      font-size: 15px;
    }
  }
  .main-banner-image-style-two {
    text-align: center;
    margin-top: 30px;
  }
  .bulb {
    display: none;
  }

  .features-box {
    padding: 30px;

    h3 {
      font-size: 19px;
    }
  }

  .testimonials-slides-two {
    max-width: 100%;
  }
  .testimonials-item {
    padding: 50px;
    text-align: center;

    img {
      margin-top: 25px;
    }
    p {
      font-size: 16px;
    }
    h3 {
      margin-bottom: 2px;
      font-size: 18px;
    }
    span {
      font-size: 14px;
    }
    &::before {
      opacity: 0.03;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }

  .get-instant-courses-area-two {
    padding: {
      top: 80px;
      bottom: 80px;
    }
    &::before,
    &::after {
      display: none;
    }
  }
  .get-instant-courses-content-style-two {
    text-align: center;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .get-instant-courses-image-style-two {
    margin-top: 30px;
    text-align: center;
  }

  .funfacts-box {
    width: auto;
    height: auto;
    border: none;
    background-color: transparent;

    .content {
      transform: translateY(0);
      position: relative;
      top: 0;
    }
    h3 {
      font-size: 36px;

      span {
        font-size: 40px;
      }
    }
    p {
      font-size: 14px;
    }
  }

  .view-all-courses-area-three {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }
  .view-all-courses-image-style-two {
    text-align: center;
    margin: {
      left: 0;
      bottom: 30px;
    }
  }
  .view-all-courses-content-style-two {
    text-align: center;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
  }

  .premium-access-area-two {
    padding: {
      top: 80px;
      bottom: 80px;
    }
    &::before {
      display: none;
    }
  }
  .premium-access-content-style-two {
    top: 0;
    text-align: center;

    .sub-title {
      font-size: 13.5px;
    }
    h2 {
      font-size: 30px;
    }
    p {
      max-width: 515px;
      margin: {
        left: auto;
        right: auto;
      }
    }
  }
  .premium-access-image-style-two {
    margin-top: 30px;
  }
}

/* Min width 992px Max width 1199px */
@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {
  .container {
    max-width: 960px;
  }
  .section-title {
    h2 {
      font-size: 30px;
    }
  }
  .mtb-100 {
    margin: {
      top: 0;
      bottom: 0;
    }
  }

  .escolalms-responsive-nav {
    .logo {
      width: 25%;
    }
    .escolalms-responsive-menu {
      &.mean-container {
        .mean-nav {
          margin-top: 51px;

          ul {
            font-size: 16px;

            li {
              li {
                a {
                  font-size: 15.5px;
                }
              }
            }
          }
        }
        .navbar-nav {
          overflow-y: scroll;
          height: 325px;
        }
        .mean-nav {
          margin-top: 50px;
        }
        .search-box {
          display: block !important;
        }
        .others-option {
          display: flex !important;
        }
        .navbar-nav {
          .nav-item {
            &.megamenu {
              .dropdown-menu {
                .nav-item {
                  .row {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 83%;
                    margin: {
                      left: auto;
                      right: auto;
                    }
                    .col {
                      flex-basis: 0;
                      flex-grow: 1;
                      max-width: 100%;
                    }
                    &:nth-child(2) {
                      max-width: 100%;
                    }
                  }
                }
                .megamenu-submenu {
                  width: 100%;

                  li {
                    a {
                      font-size: 15.5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // .navbar-area {
  //   padding: {
  //     top: 25px;
  //     bottom: 25px;
  //   }
  // }

  .shape1,
  .shape2,
  .shape3,
  .shape4,
  .shape5,
  .shape6,
  .shape7,
  .shape8,
  .shape9,
  .shape10,
  .shape11,
  .shape12,
  .shape13,
  .shape14,
  .shape15,
  .shape16,
  .shape17,
  .shape18,
  .shape19,
  .shape20,
  .shape21,
  .shape22,
  .shape23 {
    display: none;
  }

  .main-banner {
    padding-top: 170px;

    .container-fluid {
      max-width: 850px;
    }
    .col-lg-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .main-banner-content {
    top: 0;
    padding-right: 0;
    margin-bottom: 60px;
    max-width: 605px;

    p {
      max-width: 565px;
    }
  }
  .main-banner-courses-list {
    .row {
      .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-lg-6 {
        &:nth-child(1) {
          .single-courses-box {
            margin-top: 0;
          }
        }
        &:nth-child(2) {
          .single-courses-box {
            margin-top: -60px;
          }
        }
      }
    }
  }
  .banner-section {
    padding-top: 150px;

    .container-fluid {
      max-width: 800px;
    }
    .col-lg-5,
    .col-lg-7 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .single-features-box {
    padding: 30px 15px;

    .icon {
      font-size: 40px;
    }
    h3 {
      font-size: 20px;
    }
  }

  .about-content {
    h2 {
      font-size: 30px;
    }
    .features-list {
      li {
        span {
          font-size: 17px;
        }
      }
    }
  }
  .about-content-box {
    h2 {
      font-size: 30px;
    }
  }

  .slogan-area {
    .divider2,
    .divider3 {
      display: none;
    }
  }
  .slogan-image {
    padding-right: 0;
    margin: {
      top: 0;
      bottom: 0;
    }
  }

  .single-courses-box {
    .courses-content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .feedback-content {
    h2 {
      font-size: 29px;
    }
  }
  .single-feedback-item {
    padding: 50px 80px 50px 40px;
  }

  .single-advisor-box {
    .advisor-content {
      p {
        display: none;
      }
    }
  }

  .single-banner-box {
    padding: 30px 20px 30px 80px;

    .icon {
      left: 20px;
      font-size: 40px;
    }
    h3 {
      font-size: 19px;
    }
  }

  .single-funfacts-box {
    padding: {
      top: 75px;
      bottom: 75px;
      left: 20px;
      right: 20px;
    }
    h3 {
      font-size: 35px;

      span {
        font-size: 40px;
      }
    }
    p {
      font-size: 14px;
    }
  }
  .single-funfacts-item {
    h3 {
      font-size: 35px;

      span {
        font-size: 40px;
      }
    }
    p {
      font-size: 14px;
    }
  }

  .get-instant-courses-content {
    padding: 30px;

    h2 {
      font-size: 30px;
    }
  }

  .single-blog-post {
    .post-content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .view-all-courses-area {
    .container-fluid {
      max-width: 960px;
    }
  }
  .view-all-courses-content {
    h2 {
      font-size: 30px;
    }
  }
  .view-all-courses-image {
    margin-top: 85px;
  }
  .view-all-courses-area-two {
    .view-all-courses-image {
      margin-top: 0;
    }
  }

  .single-courses-item {
    .courses-content {
      .price {
        font-size: 20px;
      }
      h3 {
        font-size: 19px;
      }
      .courses-content-footer {
        padding-right: 0;
      }
    }
  }

  .single-footer-widget {
    &.pl-5,
    &.px-5 {
      padding-left: 0 !important;
    }
  }

  .single-categories-box {
    .content {
      h3 {
        font-size: 21px;
      }
    }
  }

  .single-blog-post-item {
    .post-content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .premium-access-content {
    h2 {
      font-size: 30px;
    }
  }

  .subscribe-content {
    h2 {
      font-size: 30px;
    }
  }

  .single-box-item {
    padding: 60px 15px 30px;

    h3 {
      font-size: 20px;
    }
  }

  .single-courses-item-box {
    .courses-content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .single-funfacts {
    h3 {
      font-size: 35px;

      span {
        font-size: 40px;
      }
    }
    p {
      font-size: 14px;
    }
  }

  .single-blog-post-box {
    .post-content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .single-testimonials-box {
    max-width: 800px;
    padding-right: 50px;

    &::before {
      bottom: 120px;
      font-size: 150px;
    }
    p {
      font-size: 20px;
    }
    .shape-img {
      img.shape-2 {
        left: -85px;
        top: -55px;
      }
    }
  }

  .our-story-title {
    h3 {
      font-size: 22px;
    }
  }

  .start-with-success-box {
    .content {
      h3 {
        font-size: 20px;
        margin-bottom: 6px;
      }
    }
  }

  .contact-info {
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    ul {
      li {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
  .contact-form {
    padding: 30px;

    h2 {
      font-size: 30px;
    }
  }

  .login-form {
    padding: 30px 40px;

    h2 {
      font-size: 30px;
    }
  }
  .register-form {
    padding-top: 25px;

    h2 {
      font-size: 30px;
    }
  }

  .purchase-guide-content {
    h3 {
      font-size: 20px;
    }
  }
  .privacy-policy-content {
    h3 {
      font-size: 20px;
    }
  }
  .terms-of-service-content {
    h3 {
      font-size: 20px;
    }
  }

  .widget-area {
    .widget {
      .widget-title {
        font-size: 20px;
      }
    }
    .widget_recent_courses {
      .item {
        .info {
          span {
            font-size: 15px;
          }
          .title {
            font-size: 15px;
          }
        }
      }
    }
    .tagcloud {
      a {
        padding: 7px 10px;
        font-size: 13.5px !important;
      }
    }
    .widget_popular_products {
      .item {
        .info {
          span {
            font-size: 16px;
          }
          .title {
            font-size: 15px;
          }
        }
      }
    }
  }

  .courses-details-desc {
    .react-tabs__tab-list {
      .react-tabs__tab {
        padding: 12px 0;
        font-size: 17px;
      }
    }
    .react-tabs__tab-panel {
      .courses-overview {
        h3 {
          font-size: 21px;
        }
      }
      .courses-curriculum {
        h3 {
          font-size: 18px;
        }
        ul {
          li {
            a {
              .courses-name {
                font-size: 14px;
              }
              &::before {
                top: 15px;
                font-size: 17px;
              }
            }
          }
        }
      }
      .courses-instructor {
        .single-advisor-box {
          .advisor-content {
            padding: 0 0 0 20px;
          }
        }
      }
      .courses-reviews {
        h3 {
          font-size: 20px;
        }
      }
      .courses-review-comments {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
  .courses-details-info {
    margin-left: 0;
    margin-top: -190px;

    .info {
      padding: {
        left: 15px;
        right: 15px;
      }
      li {
        font-size: 16px;

        &.price {
          font-size: 25px;
        }
      }
    }
  }
  .courses-details-desc-style-two {
    h3 {
      font-size: 22px;
    }
    .courses-reviews {
      h3 {
        font-size: 22px;
      }
    }
  }
  .related-courses {
    h3 {
      font-size: 22px;
    }
  }
  .courses-sidebar-information {
    padding: 20px;

    .info {
      li {
        font-size: 16px;
      }
    }
  }

  .apply-instructor-image {
    h2 {
      font-size: 30px;
    }
  }
  .apply-instructor-content {
    padding: 20px;

    .react-tabs__tab-list {
      .react-tabs__tab {
        margin-right: 30px;
        padding: 0 0 8px;
        font-size: 16px;
      }
    }
    h3 {
      font-size: 20px;
    }
  }

  .teacher-register-box {
    h2 {
      font-size: 30px;
    }
  }

  .profile-box {
    .content {
      padding: 0 0 0 30px;

      h3 {
        font-size: 21px;
      }
    }
  }

  .single-events-box {
    .content {
      h3 {
        font-size: 20px;
      }
    }
  }

  .events-details-image {
    #timer {
      div {
        width: 110px;
        height: 110px;
        font-size: 45px;
        margin: {
          left: 5px;
          right: 5px;
        }
        span {
          font-size: 17px;
        }
      }
    }
  }
  .events-details-desc {
    h3 {
      font-size: 21px;
    }
  }
  .events-details-info {
    padding: 20px;

    .info {
      li {
        font-size: 16px;

        &.price {
          font-size: 25px;
        }
      }
    }
  }

  .single-products-box {
    .products-content {
      h3 {
        font-size: 19px;
      }
    }
  }

  .cart-totals {
    h3 {
      font-size: 22px;
    }
  }

  .billing-details {
    .title {
      font-size: 21px;
    }
  }
  .order-details {
    .title {
      font-size: 21px;
    }
  }

  .products-details-desc {
    padding-left: 0;

    h3 {
      font-size: 21px;
    }
    .products-meta {
      span {
        font-size: 15px;
      }
    }
  }
  .products-details-tabs {
    .react-tabs__tab-panel {
      .products-reviews {
        h3 {
          font-size: 21px;
        }
      }
      .products-review-comments {
        h3 {
          font-size: 21px;
        }
      }
      .review-form-wrapper {
        h3 {
          font-size: 21px;
        }
      }
    }
  }

  .blog-details-desc {
    .article-content {
      h3 {
        font-size: 22px;
      }
    }
    .article-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details {
            .author-profile-details {
              h4 {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
  blockquote,
  .blockquote {
    padding: 40px !important;

    p {
      font-size: 20px !important;
    }
  }
  .prev-link-wrapper {
    .prev-title {
      font-size: 16px;
    }
  }
  .next-link-wrapper {
    .next-title {
      font-size: 16px;
    }
  }

  .comments-area {
    .comments-title {
      font-size: 22px;
    }
    .comment-respond {
      .comment-reply-title {
        font-size: 22px;
      }
    }
  }

  .main-banner-wrapper {
    padding-bottom: 100px;

    .container-fluid {
      max-width: 960px;
    }
  }
  .main-banner-wrapper-content {
    top: 50px;

    h1 {
      font-size: 45px;
    }
    p {
      font-size: 16px;
    }
  }
  .banner-shape14,
  .banner-shape15,
  .banner-shape16,
  .banner-shape17,
  .banner-shape18 {
    display: none;
  }

  .single-language-courses-box {
    h3 {
      font-size: 22px;
    }
  }

  .information-content {
    h2 {
      font-size: 30px;
    }
    .apply-details {
      li {
        padding-left: 100px;

        h3 {
          font-size: 22px;
        }
        .icon {
          width: 75px;
          height: 75px;
          line-height: 75px;
          font-size: 30px;
        }
      }
    }
  }

  .free-trial-form {
    h2 {
      font-size: 30px;
    }
  }

  .hero-banner-area {
    padding: {
      bottom: 230px;
      top: 100px;
    }
    .container-fluid {
      max-width: 960px;
    }
  }
  .hero-banner-content {
    h1 {
      margin-bottom: 20px;
      font-size: 38px;
    }
    p {
      padding-right: 0;
    }
  }

  .overview-box {
    .overview-content {
      h2 {
        font-size: 30px;
      }
    }
  }

  .yoga-main-banner {
    padding-left: 0;

    .container-fluid {
      max-width: 960px;
    }
    .banner-shape2,
    .banner-shape3 {
      display: none;
    }
  }
  .yoga-banner-content {
    max-width: 900px;
    margin: {
      left: auto;
      right: auto;
    }
  }

  .single-training-box {
    h3 {
      margin-bottom: 12px;
      font-size: 22px;
    }
  }

  .single-yoga-courses-box {
    .courses-content {
      h3 {
        font-size: 22px;
      }
    }
  }

  .experience-content {
    h2 {
      font-size: 30px;
    }
  }

  .experience-image {
    .title {
      font-size: 25px;
    }
  }

  .download-syllabus-form {
    padding: 30px;

    h2 {
      font-size: 30px;
    }
  }

  .single-pricing-box {
    .pricing-header {
      h3 {
        font-size: 22px;
      }
    }
    .pricing-features {
      ul {
        li {
          font-size: 16px;
        }
      }
    }
    .price {
      font-size: 30px;

      span {
        margin-top: 0;
        font-size: 15px;
      }
    }
  }

  .health-coaching-banner-area {
    .divider {
      height: 105px;
    }
  }
  .health-coaching-banner-content {
    h1 {
      font-size: 50px;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
    }
    .btn-box {
      margin-top: 20px;
    }
  }
  .health-coaching-shape1,
  .health-coaching-shape2,
  .health-coaching-shape3,
  .health-coaching-shape4,
  .health-coaching-shape5,
  .health-coaching-shape6,
  .health-coaching-shape7 {
    display: none;
  }

  .single-lifestyle-box {
    padding: 30px 20px;

    .icon {
      width: 95px;
      height: 95px;
      line-height: 95px;
      font-size: 60px;
    }
    h3 {
      margin-bottom: 15px;
      font-size: 22px;
    }
  }

  .program-section-title {
    padding-right: 20px;

    h2 {
      font-size: 30px;
    }
  }
  .program-list {
    .program-circle-shape {
      img {
        animation: unset !important;
      }
    }
  }
  .single-program-box {
    padding: 20px;

    .shape {
      display: none;
    }
    .icon {
      font-size: 50px;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 20px;
    }
  }

  .single-health-services-box {
    h3 {
      font-size: 22px;
    }
  }

  .experience-content {
    .shape {
      display: none;
    }
  }
  .experience-img {
    .title {
      font-size: 25px;
    }
  }

  .single-events-box-item {
    .content {
      padding: 17px;

      h3 {
        font-size: 20px;
      }
    }
  }

  .health-coaching-feedback-image {
    margin-right: -230px;
  }
  .health-coaching-inner {
    max-width: 475px;

    h2 {
      font-size: 30px;
      margin-bottom: 40px;
    }
  }
  .object1,
  .object2,
  .object3,
  .object4 {
    display: none;
  }
  .feedback-quote {
    padding: 20px;

    p {
      font-size: 17px;
    }
  }

  .kindergarten-main-banner {
    padding-top: 150px;

    .container-fluid {
      max-width: 960px;
      padding: {
        left: 15px;
        right: 15px;
      }
    }
    &::after {
      bottom: -1px;
    }
  }
  .kindergarten-banner-content {
    .image {
      img {
        max-width: 60px;
      }
    }
    .circle-shape {
      display: none;
    }
    h1 {
      line-height: 1.1;
      font-size: 45px;

      span {
        display: inline;
        font-size: 50px;
      }
    }
  }
  .kindergarten-banner-image {
    margin-top: -15px;

    .mt-4,
    .my-4 {
      margin-top: 0 !important;
    }
  }
  .kindergarten-shape3 {
    img {
      max-width: 150px;
    }
  }

  .kindergarten-about-image {
    margin-right: 0;

    .main-image {
      padding: {
        bottom: 115px;
        right: 80px;
      }
      img {
        &:nth-child(2) {
          max-width: 300px;
        }
      }
    }
    .shape {
      img {
        max-width: 105px;
      }
    }
  }
  .kindergarten-shape7 {
    display: none;
  }

  .single-kindergarten-services-box {
    background-color: #ffffff;
    padding: {
      left: 20px;
      right: 20px;
      top: 35px;
      bottom: 35px;
    }
    img {
      display: none !important;
    }
    .content {
      position: relative;
      top: 0;
      padding: {
        left: 0;
        right: 0;
      }
      .icon {
        font-size: 45px;
      }
      h3 {
        font-size: 21px;
      }
    }
  }
  .services-slides {
    &.owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 6px;
            right: 6px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-kindergarten-courses-box {
    .courses-content {
      padding: 0 20px 20px;

      h3 {
        font-size: 21px;
      }
    }
  }
  .courses-slides-two {
    &.owl-theme {
      .owl-nav {
        margin-bottom: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 6px;
            right: 6px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-kindergarten-feedback-item {
    background-color: #ffffff;
    padding: {
      left: 20px;
      right: 20px;
      top: 35px;
      bottom: 35px;
    }
    .content {
      img {
        display: none !important;
      }
      p {
        padding: 0;
        top: 0;
        position: relative;
      }
    }
    .client-info {
      margin: {
        left: 0;
        top: 15px;
      }
      h3 {
        color: #000000;
        font-size: 18px;
      }
      span {
        color: #000000;
      }
    }
  }
  .feedback-slides-three {
    &.owl-theme {
      .owl-nav {
        margin-bottom: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 6px;
            right: 6px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-selected-ages-box {
    h3 {
      font-size: 21px;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
  .kindergarten-shape15,
  .kindergarten-shape16,
  .kindergarten-shape18 {
    display: none;
  }
  .kindergarten-shape19 {
    img {
      max-width: 50px;
    }
  }
  .kindergarten-shape20 {
    img {
      max-width: 150px;
    }
  }

  .events-box {
    .content {
      padding: 30px;

      h3 {
        font-size: 21px;
      }
    }
  }

  .single-blog-item {
    .post-content {
      h3 {
        font-size: 21px;
      }
    }
  }

  // .navbar-area {
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  // }

  .escolalms-nav {
    .navbar {
      .navbar-nav {
        .nav-item {
          a {
            font-size: 15px;
            margin-left: 8px;
            margin-right: 8px;
            padding-top: 15px;
            padding-bottom: 15px;
          }

          &:hover {
            .dropdown-menu {
              margin-top: 0;
              width: 250px;
              li {
                a {
                  font-size: 14px;
                }
              }
            }
          }

          &.megamenu {
            .dropdown-menu {
              .megamenu-submenu {
                li {
                  a {
                    font-size: 14px;
                  }
                }
              }
              .single-category-widget {
                .icon {
                  width: 60px;
                  height: 60px;
                  font-size: 30px;
                }
                h3 {
                  font-size: 18px;
                }
                .sub-title {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .search-box {
        margin-left: 30px;
        width: 160px;
        display: none;
        .input-search {
          height: 40px;
          font-size: 12px;
        }
        button {
          height: 40px;
        }
      }

      .others-option {
        margin-left: 15px;
        .option-item {
          margin-left: 25px;
        }
        .default-btn {
          font-size: 13px;
          padding-left: 40px;
          padding-right: 15px;
          i {
            left: 20px;
          }
        }
      }
    }
  }
  /*new-css*/
  .main-banner-area {
    .container-fluid {
      max-width: 960px;
    }
  }
  .main-banner-content-style-two {
    h1 {
      font-size: 45px;
    }
    p {
      max-width: 100%;
    }
  }
  .bulb {
    display: none;
  }

  .funfacts-box {
    height: 215px;
    width: 215px;
  }

  .testimonials-item {
    padding: 70px;
  }

  .get-instant-courses-content-style-two {
    h2 {
      font-size: 30px;
    }
  }

  .view-all-courses-content-style-two {
    h2 {
      font-size: 30px;
    }
  }

  .premium-access-content-style-two {
    h2 {
      font-size: 30px;
    }
  }
}

/* Min width 1200px Max width 1355px */
@media only #{$media} and ($feature_min : $value_seven) and ($feature_max : $value_eight) {
  .container {
    max-width: 1140px;
  }

  .escolalms-nav {
    .container-fluid {
      max-width: 1200px;
      padding: {
        left: 15px;
        right: 60px;
      }
    }
    .navbar {
      .search-box {
        display: none;
      }
      .others-option {
        display: flex !important;
      }
      .navbar-nav {
        .nav-item {
          .dropdown-menu {
            left: auto;
            right: 0;

            li {
              .dropdown-menu {
                left: -250px;
                right: auto;

                li {
                  .dropdown-menu {
                    left: 250px;

                    li {
                      .dropdown-menu {
                        left: -250px;

                        li {
                          .dropdown-menu {
                            left: 250px;

                            li {
                              .dropdown-menu {
                                left: -250px;

                                li {
                                  .dropdown-menu {
                                    left: 250px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .main-banner {
    .container-fluid {
      max-width: 1165px;
    }
  }
  .banner-section {
    .container-fluid {
      max-width: 1165px;
    }
  }
  .main-banner-courses-list {
    .single-courses-box {
      .courses-content {
        h3 {
          font-size: 22px;
        }
      }
    }
  }

  .about-area {
    .shape3 {
      display: none;
    }
  }

  .slogan-area {
    .shape3 {
      display: none;
    }
  }

  .hero-banner-area {
    .container-fluid {
      max-width: 1200px;
    }
  }
  .hero-banner-content {
    h1 {
      margin-bottom: 20px;
      font-size: 39px;
    }
  }

  .health-coaching-banner-content {
    h1 {
      font-size: 65px;
    }
  }
  .health-coaching-shape1,
  .health-coaching-shape2,
  .health-coaching-shape3 {
    display: none;
  }

  .kindergarten-main-banner {
    padding-top: 150px;

    .container-fluid {
      max-width: 1200px;
      padding: {
        left: 15px;
        right: 15px;
      }
    }
  }
  .kindergarten-banner-content {
    .image {
      img {
        max-width: 100px;
      }
    }
    h1 {
      font-size: 50px;

      span {
        font-size: 70px;
        display: inline;
      }
    }
    .circle-shape {
      display: none;
    }
  }
  .kindergarten-banner-image {
    margin-top: -50px;

    .mt-4,
    .my-4 {
      margin-top: 0 !important;
    }
  }
  .kindergarten-shape7 {
    right: 5%;
    top: 3%;
  }

  .services-slides {
    &.owl-theme {
      .owl-nav {
        margin-top: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 6px;
            right: 6px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .courses-slides-two {
    &.owl-theme {
      .owl-nav {
        margin-bottom: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 6px;
            right: 6px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .single-kindergarten-feedback-item {
    background-color: #ffffff;
    padding: {
      left: 20px;
      right: 20px;
      top: 35px;
      bottom: 35px;
    }
    .content {
      img {
        display: none !important;
      }
      p {
        padding: 0;
        top: 0;
        position: relative;
      }
    }
    .client-info {
      margin: {
        left: 0;
        top: 15px;
      }
      h3 {
        color: #000000;
      }
      span {
        color: #000000;
      }
    }
  }
  .feedback-slides-three {
    &.owl-theme {
      .owl-nav {
        margin-bottom: 30px;

        [class*="owl-"] {
          position: relative;
          left: 0;
          top: 0;
          transform: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          padding-left: 2px !important;
          margin: {
            left: 6px;
            right: 6px;
          }
          &::before {
            margin: 2px;
          }
          &.owl-next {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .kindergarten-shape19 {
    bottom: 220px;

    img {
      max-width: 150px;
    }
  }
  .kindergarten-shape20 {
    img {
      max-width: 240px;
    }
  }

  .admin-nav {
    .navbar {
      .others-option {
        display: flex !important;
      }
    }
  }
  /*new-css*/
  .testimonials-slides-two {
    max-width: 1050px;
  }

  .funfacts-box {
    width: 260px;
    height: 260px;
  }
}

/* Min width 1550px */
@media only #{$media} and ($feature_min : $value_nine) {
  .escolalms-nav {
    .container-fluid {
      padding: {
        left: 120px;
        right: 120px;
      }
    }
    .navbar {
      .search-box {
        margin-left: 90px;
      }
    }
  }

  .main-banner {
    .container-fluid {
      max-width: 1480px;
    }
  }
  .banner-section {
    .container-fluid {
      max-width: 1500px;
    }
  }
  .main-banner-wrapper {
    .container-fluid {
      max-width: 1480px;
    }
  }
  .main-banner-wrapper-content {
    h1 {
      font: {
        size: 55px;
      }
    }
  }
  .hero-banner-area {
    .container-fluid {
      max-width: 1480px;
    }
  }

  .health-coaching-shape1 {
    img {
      max-width: 100%;
    }
  }
  .health-coaching-shape2 {
    img {
      max-width: 100%;
    }
  }
  .health-coaching-shape3 {
    img {
      max-width: 100%;
    }
  }

  .kindergarten-main-banner {
    .container-fluid {
      padding: {
        left: 120px;
        right: 120px;
      }
    }
  }
  .kindergarten-banner-content {
    .image {
      img {
        max-width: 100%;
      }
    }
    h1 {
      font: {
        size: 60px;
      }
      span {
        font-size: 80px;
      }
    }
  }
  .kindergarten-shape19 {
    img {
      max-width: 100%;
    }
  }
  .kindergarten-shape20 {
    img {
      max-width: 100%;
    }
  }

  .feedback-slides-three {
    &.owl-theme {
      .owl-nav {
        [class*="owl-"] {
          left: 18.1%;

          &.owl-next {
            left: auto;
            right: 18.1%;
          }
        }
      }
    }
  }
  /*new-css*/
  .main-banner-area {
    .container-fluid {
      max-width: 1480px;
    }
  }
  .bulb {
    left: 20%;
  }

  .about-area {
    .bulb {
      width: auto;
    }
  }

  .get-instant-courses-area-two {
    .bulb {
      width: auto;
    }
  }
}

@media only screen and (max-width: 991px) {
  .escolalms-nav {
    .container-fluid {
      padding-left: 20px;
      padding-right: 20px;
    }
    .navbar {
      .search-box {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 10px;
        width: 100%;
        .input-search {
          height: 45px;
          font-size: 14px;
        }
      }
      .navbar-collapse {
        max-height: 70vh;

        margin-top: 20px;
        padding: 0 8px;

        /* width */
        &::-webkit-scrollbar {
          width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      .navbar-nav {
        .nav-item {
          a {
            font-size: 15px;
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-left: 0;
            margin-right: 0;
            i {
              display: none;
            }
          }

          .dropdown-menu {
            border: none;
            top: 0;
            border-top: 1px solid #fe4a55;
            position: relative;
            opacity: 1;
            visibility: visible;
            margin-top: 0;
            width: 95%;

            li {
              a {
                padding: 6px 20px;
                font-size: 14px;
              }
              .dropdown-menu {
                left: 20px;
                border: none;
                top: 0;
                border-top: 1px solid #fe4a55;
                position: relative;
                opacity: 1;
                visibility: visible;
                margin-top: 0;
                width: 88%;
              }
            }
          }

          &.megamenu {
            .dropdown-menu {
              padding: 15px 0;
              left: 0;
              border: none;
              top: 0;
              border-top: 1px solid #fe4a55;
              position: relative;
              opacity: 1;
              visibility: visible;
              margin-top: 0;
              width: 95%;

              .megamenu-submenu {
                li {
                  a {
                    margin-top: 10px;
                    font-size: 13px;
                  }
                }
              }

              .mobile-none {
                display: none;
              }
            }
          }
        }
      }

      .others-option {
        margin-left: 0;
      }
    }
  }

  .admin-nav {
    .navbar {
      .others-option {
        .user-dropdown {
          .dropdown-menu {
            opacity: 1;
            visibility: visible;
            left: 0;
            right: auto;
            top: 32px;
            width: 210px;
            li {
              a {
                padding: 6px 20px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
