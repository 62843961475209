/*
@File: eDemy Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Partner Area CSS
** - Boxes Area CSS
** - Features Area CSS
** - About Area CSS
** - Language Courses Area CSS
** - Success Story Area CSS
** - Our Story Area CSS
** - Courses Area CSS
** - Courses Details Area CSS
** - Slogan Area CSS
** - Categories Area CSS
** - Advisor Area CSS
** - Start With Success Area CSS
** - Funfacts Area CSS
** - Feedback Area CSS
** - Profile Area CSS
** - Video Area CSS
** - Get Instant Courses Area CSS
** - Events Area CSS
** - Events Details Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Become Instructor & Partner CSS
** - View All Courses Area CSS
** - Information Area CSS
** - Free Trial Area CSS
** - Newsletter Modal CSS
** - Teacher Register Area CSS
** - Premium Access Area CSS
** - Apply Instructor Area CSS
** - Page Title Area CSS
** - Subscribe Area CSS
** - Gallery Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Widget Sidebar CSS
** - Privacy Policy Area CSS
** - Terms of Service Area CSS
** - Purchase Guide Area CSS
** - Membership Levels Area CSS
** - Pagination CSS
** - 404 Error Area CSS
** - FAQ Area CSS
** - Coming Soon Area CSS
** - Profile Authentication Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/

$font-family: "Poppins", sans-serif;
$font-family2: "Playfair Display", serif;
$main-color: #fe4a55;
$main-color2: #6ba292;
$optional-color: #606060;
$white-color: #ffffff;
$black-color: #221638;
$font-size: 16px;
$transition: 0.5s;

body {
  padding: 0;
  margin: 0;
  font: {
    size: $font-size;
    family: $font-family;
  }
}
img {
  max-width: 100%;
  height: auto;
}
p {
  color: $optional-color;
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: $font-size;

  &:last-child {
    margin-bottom: 0;
  }
}

ul, ol {
  font-size: $font-size;
}

:focus {
  outline: 0 !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-color;
  font-family: $font-family;
}
.container {
  max-width: 1230px;
}
.bg-fef8ef {
  background-color: #fef8ef;
}
.bg-fe4a55 {
  background-color: $main-color;
}
.bg-f5f1ed {
  background-color: #f5f1ed;
}
.bg-f0f2f5 {
  background-color: #f0f2f5;
}
.bg-f8f9f8 {
  background-color: #f8f9f8;
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
.bg-f7ebeb {
  background-color: #f7ebeb;
}
.bg-6dbbbd {
  background-color: #6dbbbd;
}
.font-weight-black {
  font-weight: 900 !important;
}
.bg-f9f9f9 {
  background-color: #f9f9f9;
}
.bg-6ba292 {
  background-color: $main-color2;
}
.bg-f5f7fa {
  background-color: #f5f7fa;
}
.bg-fffaf3 {
  background-color: #fffaf3;
}
.bg-fff7f4 {
  background-color: #fff7f4;
}
.bg-f6f7fb {
  background-color: #f6f7fb;
}
.bg-fcf7f3 {
  background-color: #fcf7f3;
}
.bg-eee8df {
  background-color: #eee8df;
}
.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.ptb-70 {
  padding: {
    top: 70px;
    bottom: 70px;
  }
}
.pt-70 {
  padding-top: 70px;
}
.pb-70 {
  padding-bottom: 70px;
}

.mtb-50 {
  margin: {
    top: 50px;
    bottom: 50px;
  }
}

.mtb-100 {
  margin: {
    top: 100px;
    bottom: 100px;
  }
}
.mxw-200 {
  max-width: 200px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mr-05 {
  margin-right: 5px;
}

.full-width {
  width: 100%;
}

.center-text {
  text-align: center;
}

a {
  color: $black-color;
  transition: $transition;
  text-decoration: none;

  &:hover {
    color: $main-color;
    text-decoration: none;
  }
}
.playfair-display-font {
  font-family: $font-family2;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
.text-right {
  text-align: center;
}
.vertical-align-top {
  td,
  th {
    vertical-align: middle;
  }
}
.text-center {
  text-align: center;
}

/* section-title */
.section-title {
  text-align: center;
  max-width: 720px;
  margin: {
    left: auto;
    right: auto;
    bottom: 55px;
  }
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 10px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    max-width: 615px;
    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    max-width: 615px;
    margin: {
      left: auto;
      right: auto;
      top: 12px;
    }
  }
}

/* form-control */
.form-control {
  height: 50px;
  color: $black-color;
  box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  transition: $transition;
  border-radius: 3px;
  padding: 1px 0 0 15px;
  font: {
    size: $font-size;
    weight: 400;
  }
  &::placeholder {
    color: #7e7e7e;
    transition: $transition;
  }
  &:focus {
    border-color: $main-color;
    background-color: transparent;

    &::placeholder {
      color: transparent;
    }
  }
}
textarea.form-control {
  height: auto;
  padding-top: 15px;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  position: relative;
  z-index: 1;
  background: {
    image: url(../../images/gray-bg.jpg);
    position: bottom right;
    size: cover;
    repeat: no-repeat;
  }
  padding: {
    top: 100px;
  }
  .container-fluid {
    max-width: 1395px;

    .row {
      margin: {
        left: 0;
        right: 0;
      }
      .col-lg-6 {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
}
.main-banner-content {
  max-width: 595px;
  position: relative;
  top: -60px;
  padding-right: 30px;

  h1 {
    margin-bottom: 25px;
    font: {
      size: 48px;
      weight: 800;
    }
  }
  p {
    max-width: 450px;
    line-height: 1.8;
    font: {
      weight: 600;
      size: 17px;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.main-banner-courses-list {
  position: relative;
  z-index: 1;

  .row {
    margin: {
      left: -15px !important;
      right: -15px !important;
    }
    .col-lg-6 {
      padding: {
        left: 15px !important;
        right: 15px !important;
      }
      &:nth-child(1) {
        .single-courses-box {
          margin-top: 90px;
        }
      }
    }
  }
  .single-courses-box {
    margin-bottom: 0;

    .courses-content {
      padding: 20px;
    }
  }
}
.banner-shape1 {
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: -1;
  text-align: center;
  right: 0;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}
.banner-shape2 {
  position: absolute;
  left: -85px;
  top: 17px;
  z-index: -1;
  right: 0;
  text-align: center;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.banner-shape3 {
  position: absolute;
  left: -40px;
  top: -35px;
  z-index: -2;
  right: 0;
  text-align: center;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.banner-section {
  position: relative;
  z-index: 1;
  background: {
    image: url(../../images/banner-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  padding: {
    top: 60px;
    bottom: 60px;
  }
  .container-fluid {
    max-width: 1395px;
  }
}

.banner-shape4 {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 40px;
  right: 0;
  text-align: center;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}
.banner-shape5 {
  position: absolute;
  z-index: -1;
  right: 24.5%;
  bottom: 10.5%;
  text-align: center;

  img {
    animation: movescale 4s linear infinite;
  }
}
.banner-shape6 {
  position: absolute;
  z-index: -1;
  left: 12%;
  bottom: 8%;

  img {
    animation: movebounce 4s linear infinite;
  }
}
.banner-shape7 {
  position: absolute;
  z-index: -1;
  top: 30%;
  left: -12%;

  img {
    animation: moveleftbounce 8s linear infinite;
  }
}
.banner-wrapper-area {
  position: relative;
  z-index: 1;
  background-color: #f8f9f8;
  padding: {
    top: 40px;
  }
  .divider {
    background-color: #f5f7fa;
    top: auto;
    bottom: 0;
  }
}
.banner-wrapper-content {
  h1 {
    margin-bottom: 18px;
    font: {
      size: 48px;
      weight: 800;
    }
  }
  p {
    font: {
      size: 17px;
    }
  }
  form {
    margin-top: 25px;
    position: relative;

    label {
      position: absolute;
      display: inline-block;
      margin-bottom: 0;
      left: 15px;
      top: 13px;
      color: $main-color;
      line-height: 1;
      font-size: 22px;
    }
    .input-search {
      display: block;
      width: 100%;
      height: 48px;
      border: 1px solid #eee8e2;
      border-radius: 5px;
      transition: $transition;
      color: $black-color;
      font: {
        size: $font-size;
        weight: 400;
      }
      padding: {
        left: 50px;
        top: 1px;
        bottom: 0;
        right: 0;
      }
      &::placeholder {
        color: #999999;
        transition: $transition;
      }
      &:focus {
        border-color: $main-color;

        &::placeholder {
          color: transparent;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      border-radius: 0 5px 5px 0;
      height: 48px;
      background-color: $main-color;
      color: $white-color;
      border: none;
      transition: $transition;
      padding: {
        top: 0;
        bottom: 0;
        left: 25px;
        right: 25px;
      }
      top: 0;
      font: {
        size: $font-size;
        weight: 600;
      }
      &:hover {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
  .popular-search-list {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
      top: 20px;
    }
    li {
      display: inline-block;
      margin-right: 12px;
      color: $optional-color;
      font: {
        size: 15px;
        weight: 600;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        display: block;
        color: $black-color;
        font-weight: 700;
        text-decoration: underline;

        &:hover {
          color: $main-color;
        }
      }
    }
  }
}
.banner-wrapper-image {
  text-align: center;
}
.banner-inner-area {
  margin-top: 50px;
  border-radius: 10px;

  .row {
    margin: {
      left: 0;
      right: 0;
    }
    .col-lg-4 {
      padding: {
        left: 0;
        right: 0;
      }
      &:last-child {
        .single-banner-box {
          border-right: none;
        }
      }
      &:nth-child(1) {
        .single-banner-box {
          border-radius: 10px 0 0 10px;
        }
      }
      &:nth-child(3) {
        .single-banner-box {
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }
}
.single-banner-box {
  border-right: 1px solid #eeeeee;
  background-color: $white-color;
  padding: 30px 30px 30px 90px;
  position: relative;

  .icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    transition: $transition;
    font-size: 45px;

    i {
      &.flaticon-world {
        font-size: 40px;
      }
    }
  }
  h3 {
    margin-bottom: 5px;
    font: {
      size: 21px;
      weight: 700;
    }
  }
  &:hover {
    .icon {
      color: $main-color;
    }
  }
}
.banner-shape8 {
  position: absolute;
  z-index: -1;
  right: 5%;
  bottom: 7%;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.banner-shape9 {
  position: absolute;
  z-index: -1;
  top: 5%;
  right: 15%;

  img {
    animation: movescale 4s linear infinite;
  }
}
.banner-shape10 {
  position: absolute;
  z-index: -1;
  bottom: 30px;
  left: 35px;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.home-banner-area {
  background: {
    color: $main-color;
    position: center center;
    size: cover;
    image: url(../../images/main-banner2.jpg);
    repeat: no-repeat;
  }
  margin: {
    bottom: 50px;
    top: 25px;
  }
  padding: {
    top: 70px;
    bottom: 70px;
    left: 40px;
    right: 40px;
  }
  h1 {
    margin-bottom: 12px;
    max-width: 600px;
    color: $white-color;
    font: {
      size: 48px;
      weight: 800;
    }
  }
  p {
    max-width: 500px;
    color: $white-color;
    font: {
      weight: 600;
      size: 18px;
    }
  }
}
.banner-shape11 {
  position: absolute;
  z-index: -1;
  right: 21%;
  bottom: 24%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.banner-shape12 {
  position: absolute;
  z-index: -1;
  right: 19%;
  top: 14%;

  img {
    animation: movescale 4s linear infinite;
  }
}
.banner-shape13 {
  position: absolute;
  z-index: -1;
  bottom: 5%;
  left: 4%;

  img {
    animation: {
      name: rotateme;
      duration: 100s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.main-banner-wrapper {
  position: relative;
  z-index: 2;
  background-color: #eae1d6;
  padding-bottom: 120px;

  .container-fluid {
    max-width: 1395px;
  }
}
.main-banner-wrapper-content {
  position: relative;
  top: 80px;

  h1 {
    margin-bottom: 18px;
    font: {
      size: 52px;
      weight: 800;
    }
  }
  p {
    font: {
      weight: 600;
      size: 17px;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.banner-shape14 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  img {
    animation: movebounce 4s linear infinite;
  }
}
.banner-shape15 {
  position: absolute;
  left: 0;
  bottom: -10px;
  z-index: -1;

  img {
    animation: movebounce 4s linear infinite;
  }
}
.banner-shape16 {
  position: absolute;
  left: 130px;
  bottom: -25px;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.banner-shape17 {
  position: absolute;
  right: 0;
  bottom: -10px;
  z-index: -1;

  img {
    animation: movebounce 4s linear infinite;
  }
}
.banner-shape18 {
  position: absolute;
  right: 400px;
  bottom: 0;
  z-index: -1;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}
.hero-banner-area {
  position: relative;
  z-index: 1;
  background-color: #fffaf3;
  padding-bottom: 200px;

  .container-fluid {
    max-width: 1395px;
  }
  .divider {
    top: auto;
    bottom: 0;
    background: #f5f7fa;
  }
}
.hero-banner-content {
  position: relative;

  h1 {
    margin-bottom: 25px;
    font: {
      size: 45px;
      weight: 800;
    }
  }
  p {
    padding-right: 50px;
    line-height: 1.8;
    font: {
      weight: 600;
      size: 17px;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.hero-banner-image {
  text-align: center;
}
.banner-shape19 {
  position: absolute;
  z-index: -1;
  left: 40%;
  bottom: 30%;

  img {
    animation: {
      name: rotateme;
      duration: 30s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}

/*================================================
Yoga Main Banner Area CSS
=================================================*/
.yoga-main-banner {
  position: relative;
  z-index: 1;
  background: {
    image: url(../../images/main-banner3.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  padding: {
    top: 100px;
    bottom: 100px;
    left: 40px;
  }
  .banner-shape2 {
    left: auto;
    top: 12%;
    right: 0;
  }
  .banner-shape3 {
    left: 52%;
    top: 30%;
    right: auto;
  }
}
.yoga-banner-content {
  position: relative;
  text-align: center;
  z-index: 1;
  max-width: 900px;

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 140px;

    .top-image {
      margin-bottom: 20px;
    }
    h1 {
      margin-bottom: 15px;
      color: $white-color;
      font: {
        size: 55px;
        weight: 800;
      }
    }
    p {
      color: #ececec;
    }
    .default-btn {
      margin-top: 15px;
    }
    .bottom-image {
      margin-top: 15px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px;
    bottom: 0;
    z-index: -1;
    background-color: $main-color2;
    display: none;
  }
}

/*================================================
Health Coaching Banner Area CSS
=================================================*/
.health-coaching-banner-area {
  background-color: $main-color2;
  position: relative;
  z-index: 1;
  padding-top: 40px;
  overflow: hidden;

  .divider {
    background-color: #f5f7fa;
    top: auto;
    bottom: -1px;
    height: 195px;
    z-index: 1;
  }
}
.health-coaching-banner-image {
  text-align: center;
}
.health-coaching-banner-content {
  h1 {
    color: $white-color;
    margin-bottom: 35px;
    font: {
      weight: 800;
      size: 70px;
    }
  }
  p {
    color: $white-color;
    font: {
      weight: 600;
      size: 17px;
    }
  }
  .btn-box {
    margin-top: 30px;

    img {
      margin-left: 30px;
    }
  }
}
.health-coaching-shape1 {
  position: absolute;
  left: 0;
  bottom: 125px;
  z-index: -1;

  img {
    max-width: 45%;
  }
}
.health-coaching-shape2 {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: -1;
  text-align: right;

  img {
    max-width: 80%;
  }
}
.health-coaching-shape3 {
  position: absolute;
  right: 0;
  bottom: 81px;
  z-index: 2;

  img {
    max-width: 271px;
  }
}
.health-coaching-shape4 {
  position: absolute;
  z-index: -1;
  right: 22%;
  top: 28%;
}
.health-coaching-shape5 {
  position: absolute;
  z-index: -1;
  bottom: 34%;
  left: 46%;
}
.health-coaching-shape6 {
  position: absolute;
  z-index: -1;
  left: 50%;
  bottom: 5%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.health-coaching-shape7 {
  position: absolute;
  z-index: -1;
  right: 25%;
  bottom: 11%;

  img {
    animation: {
      name: rotateme;
      duration: 30s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  &.border-bottom {
    border-bottom-color: #efeff1 !important;
  }
}
.partner-slides {
  &.owl-carousel {
    .owl-item {
      .single-partner-item {
        text-align: center;
        img {
          margin: auto;
          max-width: 100%;
        }
      }
    }
    &.owl-theme {
      .owl-nav {
        margin: 0;
        .owl-prev,
        .owl-next {
          background-color: #fff;
          border: 1px solid $main-color;
          height: 30px;
          width: 30px;
          line-height: 32px;
          text-align: center;
          color: $main-color;
          padding: 0;
          margin: 0;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          position: absolute;
          border-radius: 50%;
          font-size: 20px;
          display: none;
          &:hover {
            background-color: $main-color;
            color: $white-color;
          }
        }
        .owl-next {
          left: auto;
          right: 0;
        }
      }
    }
    &:hover {
      &.owl-theme {
        .owl-nav {
          .owl-prev,
          .owl-next {
            display: block;
          }
        }
      }
    }
  }
}

/*================================================
Boxes Area CSS
=================================================*/
.boxes-area {
  position: relative;
  z-index: 2;
}
.single-box-item {
  text-align: center;
  border-radius: 8px;
  background-color: $white-color;
  padding: 70px 30px 40px;
  transition: $transition;
  top: -250px;
  position: relative;
  margin-bottom: -250px;

  .icon {
    font-size: 65px;
    line-height: 1;
    position: relative;
    margin-bottom: 40px;
    z-index: 1;

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: -22px;
      z-index: -1;
      text-align: center;
      margin: {
        left: auto;
        right: auto;
      }
    }
  }
  h3 {
    margin-bottom: 12px;
    font: {
      size: 23px;
      weight: 800;
    }
  }
  p {
    margin-bottom: 12px;
  }
  .link-btn {
    display: inline-block;
    color: $main-color;
    font: {
      size: 15px;
      weight: 700;
    }
    &:hover {
      color: $black-color;
    }
  }
  &:hover {
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    transform: translateY(-10px);
  }
}
.boxes-area {
  &.boxes-style-two {
    .single-box-item {
      top: -130px;
      margin-bottom: -130px;
    }
  }
}
.single-box-item {
  .image {
    margin-bottom: 30px;
  }
}
.boxes-info {
  text-align: center;
  margin-top: 30px;

  p {
    line-height: initial;
    font-weight: 600;

    a {
      font-weight: 700;
      color: $main-color;

      &:hover {
        color: $black-color;
      }
    }
  }
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
  position: relative;
  z-index: 1;
}
.single-features-box {
  text-align: center;
  margin-bottom: 30px;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 30px 31px;
  transition: $transition;

  .icon {
    font-size: 50px;
    line-height: 1;
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: 15px;
    font: {
      size: 22px;
      weight: 800;
    }
  }
  p {
    margin-bottom: 12px;
  }
  .link-btn {
    display: inline-block;
    color: $main-color;
    font: {
      size: 15px;
      weight: 700;
    }
    &:hover {
      color: $black-color;
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
  &.without-padding {
    text-align: left;
    padding: 0;
    background-color: transparent;

    p {
      max-width: 315px;
    }
  }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}
.about-image {
  overflow: hidden;
  text-align: center;

  .row {
    margin: {
      left: -5px;
      right: -5px;
    }
    .col-lg-6 {
      padding: {
        left: 5px;
        right: 5px;
      }
      &:nth-child(3) {
        .image {
          margin-top: -15px;
        }
      }
      &:nth-child(2) {
        .image {
          margin-bottom: 20px;
        }
      }
    }
  }
}
.about-content {
  padding-left: 15px;

  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 10px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 15px;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  .features-list {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: {
      bottom: 0;
      top: -5px;
      left: -8px;
      right: -8px;
    }
    li {
      flex: 0 0 50%;
      max-width: 50%;
      padding: {
        left: 8px;
        right: 8px;
        top: 16px;
      }
      span {
        display: block;
        position: relative;
        color: $black-color;
        font: {
          size: 18px;
          weight: 700;
        }
        padding: {
          left: 50px;
          top: 5px;
          bottom: 5px;
        }
        i {
          position: absolute;
          left: 0;
          top: -2px;
          width: 40px;
          height: 40px;
          overflow: hidden;
          text-align: center;
          font-weight: 500;
          line-height: 40px;
          color: $main-color;
          font-size: 25px;
          background: {
            image: url(../../images/circle-border.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
          }
        }
      }
    }
  }
  .default-btn {
    margin-top: 30px;
  }
}
.about-area-two {
  position: relative;
  z-index: 1;

  .shape3 {
    right: 12%;
    bottom: auto;
    top: 25%;
  }
  .shape4 {
    right: 8%;
    bottom: auto;
    top: 7%;
  }
  .shape2 {
    left: auto;
    bottom: 5%;
    right: 15%;
  }
}
.about-content-box {
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 10px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 16px;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  .link-btn {
    display: inline-block;
    color: $main-color;
    position: relative;
    border-bottom: 1px solid transparent;
    font: {
      weight: 700;
    }
    &:hover {
      color: $black-color;
      border-color: $black-color;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.about-video-box {
  margin-left: 30px;
  position: relative;
  z-index: 1;
  border-radius: 5px;

  .image {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      background-color: $black-color;
      opacity: 0.3;
      z-index: 1;
    }
    img {
      border-radius: 5px;
      transition: $transition;
    }
  }
  .video-btn {
    display: inline-block;
    width: 80px;
    height: 80px;
    font-size: 35px;
    text-align: center;
    line-height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%) translateX(-50%);
    background-color: $main-color;
    border-radius: 50%;
    color: $white-color;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      transition: $transition;
      border-radius: 50%;
      border: 1px solid $main-color;
    }
    &::before {
      animation: ripple 2s linear infinite;
    }
    &::after {
      animation: ripple 2s linear 1s infinite;
    }
    &:hover {
      color: $white-color;
      background-color: $black-color;

      &::before,
      &::after {
        border-color: $black-color;
      }
    }
  }
  &:hover {
    .image {
      img {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }
  .shape10 {
    left: -6.5%;
    bottom: -13.5%;
    opacity: 0.5;
  }
}
.about-area-three {
  position: relative;
  z-index: 1;

  .shape3 {
    right: 12%;
    bottom: auto;
    top: 38%;
  }
  .shape4 {
    right: 8%;
    bottom: auto;
    top: 20%;
  }
}
.about-img {
  position: relative;
  z-index: 1;

  .image {
    text-align: right;
    position: relative;
    padding-bottom: 45px;

    img {
      &:nth-child(2) {
        position: absolute;
        left: 80px;
        bottom: 0;
      }
    }
  }
}
.divider {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #fffaf3;
  top: -100px;
  left: 0;
  z-index: -1;
}
.shape1 {
  position: absolute;
  left: 12%;
  top: 0;
  z-index: -1;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}
.shape2 {
  position: absolute;
  left: 5%;
  bottom: 8%;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.shape3 {
  position: absolute;
  right: 9%;
  bottom: 12%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 100s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape4 {
  position: absolute;
  right: 5%;
  bottom: 40%;
  z-index: -1;

  img {
    animation: movescale 4s linear infinite;
  }
}
.shape17 {
  position: absolute;
  z-index: -1;
  top: -45px;
  left: 160px;
  right: 0;
  text-align: center;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}
.shape18 {
  position: absolute;
  z-index: -1;
  top: 0;
  left: -150px;
  right: 0;
  text-align: center;

  img {
    animation: {
      name: rotateme;
      duration: 100s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape19 {
  position: absolute;
  right: -15px;
  top: 40%;

  img {
    animation: movescale 4s linear infinite;
  }
}
.shape20 {
  position: absolute;
  z-index: -1;
  left: 7%;
  top: 57%;

  img {
    animation: movescale 4s linear infinite;
  }
}
.shape21 {
  position: absolute;
  z-index: -1;
  left: 11%;
  bottom: 15%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.shape22 {
  position: absolute;
  z-index: -1;
  left: 55%;
  bottom: 10px;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape23 {
  position: absolute;
  z-index: -1;
  right: 5%;
  bottom: 5%;

  img {
    animation: {
      name: rotateme;
      duration: 100s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes movescale {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.9);
  }
}
.about-content {
  .about-list {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: {
      bottom: 0;
      top: -5px;
      left: -8px;
      right: -8px;
    }
    li {
      flex: 0 0 50%;
      max-width: 50%;
      padding: {
        left: 8px;
        right: 8px;
        top: 16px;
      }
      span {
        display: block;
        position: relative;
        font-weight: 700;
        padding: {
          left: 40px;
          top: 3px;
        }
        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          color: $white-color;
          background-color: #a3c5bb;
          z-index: 1;
          border-radius: 50%;
          font-size: 20px;
          transition: $transition;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $main-color2;
            border-radius: 50%;
            z-index: -1;
            margin: 2px 1px 1px 2px;
            transition: $transition;
          }
        }
        &:hover {
          i {
            background-color: $main-color;
            color: $main-color;

            &::after {
              background-color: $white-color;
            }
          }
        }
      }
    }
  }
  .default-btn-style-two {
    margin-top: 35px;
  }
}
.kindergarten-about-image {
  position: relative;
  z-index: 1;
  margin-right: 15px;

  .main-image {
    position: relative;
    padding-bottom: 115px;

    img {
      &:nth-child(2) {
        position: absolute;
        text-align: right;
        right: 0;
        bottom: 0;
      }
    }
  }
  .shape {
    position: absolute;
    right: -15px;
    top: 0;
    z-index: -1;

    img {
      animation: movebounce 5s linear infinite;
    }
  }
}

/*================================================
Language Courses Area CSS
=================================================*/
.single-language-courses-box {
  margin-bottom: 30px;
  text-align: center;

  img {
    margin-bottom: 30px;
  }
  h3 {
    margin-bottom: 12px;
    font: {
      size: 25px;
      weight: 800;
    }
  }
  p {
    padding: {
      left: 20px;
      right: 20px;
    }
  }
  .default-btn {
    background-color: transparent;
    color: $main-color;
    border: 1px solid $main-color;
    margin-top: 10px;

    span {
      background-color: $main-color;
    }
  }
  &:hover {
    .default-btn {
      color: $white-color;

      span {
        width: 225%;
        height: 562.5px;
      }
    }
  }
}

/*================================================
Success Story Area CSS
=================================================*/
.success-story-area {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40%;
    background-color: #f8f9f8;
    z-index: -2;
  }
  .shape4 {
    right: 21%;
    bottom: 85%;
  }
  .shape3 {
    right: 23%;
    bottom: 55%;
  }
  .shape2 {
    left: auto;
    bottom: 3%;
    right: 25%;
  }
}

/*================================================
Kindergarten Main Banner Area CSS
=================================================*/
.kindergarten-main-banner {
  background-color: #f8e8e9;
  position: relative;
  z-index: 1;
  padding: {
    top: 180px;
  }
  .container-fluid {
    padding: {
      left: 40px;
      right: 40px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: {
      image: url(../../images/events-rectangle1.png);
      position: top;
      repeat: no-repeat;
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: {
      image: url(../../images/events-rectangle2.png);
      position: bottom;
      repeat: no-repeat;
    }
  }
}
.kindergarten-banner-content {
  position: relative;
  z-index: 1;
  text-align: center;

  .image {
    margin-bottom: 15px;

    img {
      max-width: 115px;
    }
  }
  .circle-shape {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    z-index: -1;
  }
  h1 {
    margin-bottom: 18px;
    line-height: 1.04;
    font: {
      size: 55px;
      weight: 900;
    }
    span {
      display: block;
      font-size: 70px;
    }
  }
  p {
    max-width: 525px;
    margin: {
      left: auto;
      right: auto;
    }
  }
  .default-btn-style-two {
    margin-top: 10px;
  }
}
.kindergarten-banner-image {
  margin-top: -70px;
  position: relative;
  z-index: 2;

  .row {
    margin: {
      left: -10px;
      right: -10px;
    }
    .col-lg-6 {
      padding: {
        left: 10px;
        right: 10px;
      }
    }
  }
  .image {
    text-align: center;
  }
}
.kindergarten-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}
.kindergarten-shape2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}
.kindergarten-shape3 {
  position: absolute;
  bottom: -60px;
  left: 0;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.kindergarten-shape4 {
  position: absolute;
  z-index: -1;
  left: 44%;
  bottom: 12%;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.kindergarten-shape5 {
  position: absolute;
  top: 16%;
  z-index: -1;
  left: 48%;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.kindergarten-shape6 {
  position: absolute;
  left: 50%;
  z-index: -1;
  bottom: 25%;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.kindergarten-shape7 {
  position: absolute;
  right: 7%;
  top: 18%;
  z-index: -1;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.kindergarten-shape8 {
  position: absolute;
  left: 2%;
  bottom: 7%;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.kindergarten-shape9 {
  position: absolute;
  right: 3%;
  top: -80px;
  z-index: 1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.kindergarten-shape10 {
  position: absolute;
  left: 5%;
  bottom: 30%;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.kindergarten-shape11 {
  position: absolute;
  left: 4%;
  bottom: 0;
  z-index: -1;
}
.kindergarten-shape12 {
  position: absolute;
  right: 10%;
  bottom: 0;
  z-index: -1;
}
.kindergarten-shape13 {
  position: absolute;
  right: 12%;
  top: 12%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.kindergarten-shape14 {
  position: absolute;
  left: 3%;
  z-index: -1;
  bottom: -80px;

  img {
    animation: moveleftbounce 5s linear infinite;
  }
}
.kindergarten-shape15 {
  position: absolute;
  right: 0;
  top: 12%;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.kindergarten-shape16 {
  position: absolute;
  left: 3%;
  bottom: 5%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.kindergarten-shape17 {
  position: absolute;
  right: 5%;
  top: 8%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.kindergarten-shape18 {
  position: absolute;
  left: 10px;
  bottom: -60px;
}
.kindergarten-shape19 {
  position: absolute;
  bottom: 150px;
  left: 0;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
    max-width: 200px;
  }
}
.kindergarten-shape20 {
  position: absolute;
  right: 0;
  top: -20px;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
    max-width: 300px;
  }
}

/*================================================
Kindergarten Services Area CSS
=================================================*/
.kindergarten-services-area {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 200px;
    z-index: -1;
    background-color: $white-color;
    width: 100%;
  }
}
.single-kindergarten-services-box {
  text-align: center;
  position: relative;

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 85px;
    z-index: 1;
    padding: {
      left: 70px;
      right: 50px;
    }
    .icon {
      color: $main-color;
      font-size: 55px;
      line-height: 1;
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 10px;
      font: {
        size: 24px;
        weight: 800;
      }
    }
  }
}
.services-slides {
  &.owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        margin: 0;
        position: absolute;
        left: -45px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 55px;
        height: 55px;
        line-height: 54px;
        border: 1px dashed $main-color;
        border-radius: 50%;
        background-color: transparent !important;
        color: #d1a1a1;
        font-size: 20px;
        transition: $transition;
        padding-left: 2px !important;

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          background-color: $white-color;
          content: "";
          border-radius: 50%;
          margin: 4px;
          transition: $transition;
        }
        &.owl-next {
          left: auto;
          right: -65px;
        }
        &:hover {
          color: $white-color;

          &::before {
            background-color: $main-color;
          }
        }
      }
    }
  }
}
.kids-kite-image {
  text-align: center;
  margin-top: -280px;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}

/*================================================
Kindergarten Courses Area CSS
=================================================*/
.single-kindergarten-courses-box {
  margin-bottom: 30px;
  position: relative;
  border-radius: 5px;
  background-color: $black-color;

  .courses-image {
    border-radius: 5px 5px 0 0;
    position: relative;
    z-index: 1;

    .image {
      border-radius: 5px 5px 0 0;

      img {
        border-radius: 5px 5px 0 0;
      }
    }
    .fav {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 20px;
      color: $white-color;
      font-size: 30px;
      line-height: 1;

      &:hover {
        color: $main-color;
      }
    }
    .price {
      display: inline-block;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: absolute;
      right: 20px;
      z-index: 1;
      bottom: 0;
      text-align: center;
      line-height: 67px;

      span {
        color: $white-color;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        font: {
          size: 20px;
          weight: 800;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: {
        image: url(../../images/box-shape7.png);
        position: bottom;
        repeat: no-repeat;
      }
    }
    .link-btn {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
      z-index: 3;
    }
  }
  .courses-content {
    border-radius: 0 0 5px 5px;
    padding: 0 30px 30px;

    .course-author {
      margin-bottom: 20px;

      img {
        width: 35px !important;
        height: 35px !important;
        margin-right: 10px;
      }
      span {
        color: $white-color;
        font: {
          weight: 600;
          size: 15px;
        }
      }
    }
    h3 {
      color: $white-color;
      margin-bottom: 12px;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
        color: $white-color;
      }
    }
    p {
      color: $white-color;
      opacity: 0.8;
    }
  }
}
.owl-item {
  &:nth-child(1) {
    .single-kindergarten-courses-box {
      background-color: $main-color2;

      .courses-image {
        &::before {
          background: {
            image: url(../../images/box-shape4.png);
          }
        }
      }
    }
  }
  &:nth-child(2) {
    .single-kindergarten-courses-box {
      background-color: $main-color;

      .courses-image {
        &::before {
          background: {
            image: url(../../images/box-shape5.png);
          }
        }
      }
    }
  }
  &:nth-child(3) {
    .single-kindergarten-courses-box {
      background-color: #d9a416;

      .courses-image {
        &::before {
          background: {
            image: url(../../images/box-shape6.png);
          }
        }
      }
    }
  }
}
.courses-slides-two {
  &.owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        margin: 0;
        position: absolute;
        left: -75px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 55px;
        height: 55px;
        line-height: 54px;
        border: 1px dashed $main-color;
        border-radius: 50%;
        background-color: transparent !important;
        color: #d1a1a1;
        font-size: 20px;
        transition: $transition;
        padding-left: 3px !important;

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          background-color: #e8e8e8;
          content: "";
          border-radius: 50%;
          margin: 4px;
          transition: $transition;
        }
        &.owl-next {
          left: auto;
          right: -75px;
        }
        &:hover {
          color: $white-color;

          &::before {
            background-color: $main-color;
          }
        }
        &.disabled {
          color: #d1a1a1;
          cursor: not-allowed;

          &::before {
            background-color: #e8e8e8;
          }
        }
      }
    }
  }
}

/*================================================
Our Story Area CSS
=================================================*/
.our-story-area {
  .col-lg-4,
  .col-lg-8 {
    margin-top: 40px;

    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}
.our-story-title {
  h3 {
    margin-bottom: 0;
    position: relative;
    padding-left: 45px;
    font: {
      size: 28px;
      weight: 800;
    }
    .number {
      opacity: 0.38;
      position: absolute;
      left: 0;
      line-height: 1;
      top: 1px;
      font: {
        size: 45px;
        weight: 900;
        style: italic;
      }
    }
  }
}
.our-story-image {
  margin-top: 40px;
  border-radius: 5px;

  img {
    border-radius: 5px;
  }
}
.our-story-content {
  padding-right: 90px;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*================================================
Slogan Area CSS
=================================================*/
.slogan-area {
  position: relative;
  z-index: 1;

  .shape2 {
    left: 9%;
    bottom: -20%;
  }
  .shape9 {
    left: 8%;
    bottom: auto;
    top: 20%;
  }
}
.slogan-image {
  padding-right: 15px;
  border-radius: 5px;
  text-align: center;
  margin: {
    top: -200px;
    bottom: -200px;
  }
  img {
    border-radius: 5px;
    width: 100%;
  }
}
.slogan-content {
  position: relative;
  z-index: 1;
  padding-left: 30px;

  p {
    margin-bottom: 0;
    font: {
      size: 20px;
      weight: 600;
    }
  }
  h3 {
    margin: {
      top: 20px;
      bottom: 0;
    }
    font: {
      size: 20px;
      weight: 800;
    }
  }
  span {
    display: block;
    color: $main-color;
    font-size: 15px;
    margin-top: 7px;
  }
  &::before {
    content: "\f10a";
    position: absolute;
    right: 30px;
    color: #e8e8e8;
    z-index: -1;
    top: -50px;
    line-height: 1;
    opacity: 0.5;
    font: {
      family: Flaticon;
      size: 200px;
    }
  }
}
.divider2 {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #fffaf3;
  top: -100px;
  left: 0;
  z-index: -1;
}
.divider3 {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #fffaf3;
  transform: scaleY(-1);
  bottom: -100px;
  left: 0;
  z-index: -1;
}

/*================================================
Categories Area CSS
=================================================*/
.single-categories-box {
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;

  img {
    border-radius: 5px;
    transition: $transition;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: $black-color;
    opacity: 0.65;
    z-index: 1;
  }
  .content {
    position: absolute;
    left: 0;
    right: 0;
    height: auto;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);

    h3 {
      margin-bottom: 8px;
      color: $white-color;
      font: {
        size: 24px;
        weight: 800;
      }
    }
    span {
      color: $white-color;
      display: block;
      font: {
        size: 15px;
        weight: 600;
      }
    }
  }
  .link-btn {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
  }
  &:hover {
    img {
      transform: scale3d(1.2, 1.2, 1.2);
    }
  }
}
.categories-btn-box {
  margin-top: 15px;
  text-align: center;
}

/*================================================
Advisor Area CSS
=================================================*/
.single-advisor-box {
  background-color: $white-color;
  margin-bottom: 30px;

  .row {
    margin: {
      left: 0;
      right: 0;
    }
    .col-lg-4,
    .col-lg-8 {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
  .advisor-image {
    text-align: center;
    border-radius: 5px;
    position: relative;
    top: -30px;
    margin-bottom: -30px;

    img {
      border-radius: 5px;
    }
  }
  .advisor-content {
    padding: 25px 30px;

    h3 {
      margin-bottom: 0;
      font: {
        size: 22px;
        weight: 800;
      }
    }
    .sub-title {
      display: block;
      color: $main-color;
      margin-top: 7px;
      font: {
        size: 15px;
        weight: 600;
      }
    }
    p {
      margin: {
        bottom: 0;
        top: 13px;
      }
    }
    .social-link {
      padding-left: 0;
      list-style-type: none;
      margin: {
        top: 17px;
        bottom: 0;
      }
      li {
        display: inline-block;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
        a {
          width: 30px;
          height: 30px;
          text-align: center;
          background-color: #e1e1e1;
          font-size: 18px;
          color: $black-color;
          position: relative;
          border-radius: 2px;

          i {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            transform: translateY(-48%);
          }
          &:hover {
            background-color: $main-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.owl-item {
  .single-advisor-box {
    margin-top: 30px;
  }
}
.advisor-slides {
  &.owl-theme {
    .owl-dots {
      margin-top: 20px !important;

      .owl-dot {
        span {
          margin: 0 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $main-color;

            &::before {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
}
.single-advisor-item {
  margin-bottom: 30px;
  border-radius: 5px 5px 0 0;
  background-color: $white-color;
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.1);
  text-align: center;

  .advisor-image {
    border-radius: 5px 5px 0 0;
    position: relative;

    img {
      border-radius: 5px 5px 0 0;
    }
    .social-link {
      padding-left: 0;
      list-style-type: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 15px;
      margin: {
        left: auto;
        right: auto;
        bottom: 0;
      }
      li {
        display: inline-block;
        transform: translateY(30px);
        transition: all 0.3s ease-in-out 0s;
        opacity: 0;
        visibility: hidden;
        margin: {
          left: 5px;
          right: 5px;
        }
        a {
          display: block;
          width: 33px;
          height: 33px;
          background-color: $white-color;
          text-align: center;
          position: relative;
          font-size: 20px;
          color: $black-color;
          border-radius: 2px;
          transition: $transition;

          i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: {
              left: auto;
              right: auto;
            }
          }
          &:hover {
            color: $white-color;
            background-color: $main-color;
          }
        }
        &:nth-child(1) {
          transition-delay: 0.1s;
        }
        &:nth-child(2) {
          transition-delay: 0.2s;
        }
        &:nth-child(3) {
          transition-delay: 0.3s;
        }
        &:nth-child(4) {
          transition-delay: 0.4s;
        }
      }
    }
  }
  .advisor-content {
    padding: 22px;

    h3 {
      margin-bottom: 0;
      font: {
        size: 23px;
        weight: 800;
      }
    }
    span {
      display: block;
      color: $main-color;
      margin-top: 7px;
      font: {
        size: 15px;
        weight: 600;
      }
    }
  }
  &:hover {
    .advisor-image {
      .social-link {
        li {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
.owl-item {
  .single-advisor-item {
    box-shadow: unset;
  }
}
.advisor-slides-two {
  &.owl-theme {
    .owl-dots {
      margin-top: 20px !important;

      .owl-dot {
        span {
          margin: 0 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $main-color;

            &::before {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
}

/*================================================
Start With Success Area CSS
=================================================*/
.start-with-success-area {
  .section-title {
    h2 {
      max-width: 713px;
    }
  }
}
.start-with-success-box {
  margin-bottom: 30px;
  border-radius: 5px;

  .image {
    border-radius: 5px 5px 0 0;

    a {
      display: block;
      border-radius: 5px 5px 0 0;

      img {
        border-radius: 5px 5px 0 0;
      }
    }
  }
  .content {
    background-color: $white-color;
    border-radius: 0 0 5px 5px;
    padding: 30px;
    position: relative;

    .link-btn {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: absolute;
      right: 30px;
      top: -30px;
      background-color: #f6f7f6;
      text-align: center;
      font-size: 32px;
      color: $main-color;

      i {
        position: absolute;
        left: 0;
        right: 0;
        top: 53%;
        transform: translateY(-53%);
      }
      &:hover {
        background-color: $main-color;
        color: $white-color;
      }
    }
    h3 {
      font: {
        weight: 800;
        size: 24px;
      }
    }
    span {
      display: block;
      color: $main-color;
      font: {
        size: 15px;
        weight: 600;
      }
    }
  }
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-and-feedback-area {
  position: relative;
  z-index: 1;
  background: {
    image: url(../../images/white-bg.jpg);
    position: bottom right;
    size: cover;
    repeat: no-repeat;
  }
  .shape4 {
    right: 21%;
    bottom: 41%;
  }
  .shape3 {
    right: 23%;
    bottom: 22%;
  }
  .shape2 {
    left: auto;
    bottom: 3%;
    right: 25%;
  }
}
.funfacts-list {
  .single-funfacts-box {
    margin-bottom: 0;
  }
  .row {
    .col-lg-6 {
      &:nth-child(1) {
        .single-funfacts-box {
          margin: {
            top: 60px;
            bottom: 30px;
          }
          &::before {
            animation: {
              duration: 60s;
            }
          }
        }
      }
      &:nth-child(2) {
        .single-funfacts-box {
          border-color: $main-color;

          &::before {
            animation: {
              duration: 70s;
            }
          }
        }
      }
      &:nth-child(3) {
        .single-funfacts-box {
          border-color: #ad8360;

          &::before {
            animation: {
              duration: 80s;
            }
          }
        }
      }
      &:nth-child(4) {
        .single-funfacts-box {
          border-color: #221638;
          margin: {
            top: -60px;
          }

          &::before {
            animation: {
              duration: 90s;
            }
          }
        }
      }
    }
  }
}
.single-funfacts-box {
  margin-bottom: 30px;
  background-color: #eee8e2;
  text-align: center;
  border: 1px dashed #308800;
  transition: $transition;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: {
    top: 100px;
    bottom: 100px;
    left: 40px;
    right: 40px;
  }
  h3 {
    margin-bottom: 0;
    color: $main-color;
    font: {
      size: 44px;
      weight: 800;
    }
    span {
      font-size: 48px;
    }
    .odometer {
      position: relative;
      top: -2px;
    }
  }
  p {
    text-transform: uppercase;
    color: $black-color;
    line-height: 1;
    margin-top: 10px;
    font: {
      weight: 700;
      size: 15px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 10px;
    opacity: 0.7;
    z-index: -1;
    background: {
      image: url(../../images/funfacts-shape.png);
      position: center center;
      size: contain;
      repeat: no-repeat;
    }
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
  &:hover {
    border-color: $main-color;
  }
}
.single-funfacts-item {
  text-align: center;
  padding: {
    top: 100px;
    bottom: 100px;
  }
  h3 {
    margin-bottom: 0;
    color: $main-color;
    font: {
      size: 44px;
      weight: 800;
    }
    span {
      font-size: 48px;
    }
    .odometer {
      position: relative;
      top: -2px;
    }
  }
  p {
    text-transform: uppercase;
    color: $black-color;
    line-height: 1;
    margin-top: 10px;
    font: {
      weight: 700;
      size: 16px;
    }
  }
}
.funfacts-area {
  .col-lg-3 {
    border-right: 1px solid #eaecf0;

    &:last-child {
      border-right: none;
    }
  }
}
.funfacts-area-two {
  border-top: 1px solid #f4f3f5;
  border-bottom: 1px solid #f4f3f5;
  .col-lg-3 {
    border-right: 1px solid #f4f3f5;

    &:last-child {
      border-right: none;
    }
  }
}
.single-funfacts {
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: {
    top: 100px;
    bottom: 100px;
  }
  img {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0.8;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    margin: {
      left: auto;
      right: auto;
    }
  }
  h3 {
    margin-bottom: 0;
    color: $main-color;
    font: {
      size: 44px;
      weight: 800;
    }
    span {
      font-size: 48px;
    }
    .odometer {
      position: relative;
      top: -2px;
    }
  }
  p {
    text-transform: uppercase;
    color: $black-color;
    line-height: 1;
    margin-top: 8px;
    font: {
      weight: 700;
      size: 16px;
    }
  }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-content {
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 0;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    margin: {
      top: 12px;
    }
  }
  .feedback-info {
    p {
      font-weight: 600;
      margin: {
        bottom: 0;
      }
      a {
        color: $main-color;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
          color: $black-color;
        }
      }
    }
  }
  .feedback-slides {
    margin: {
      top: 30px;
      bottom: 30px;
    }
  }
}
.single-feedback-item {
  background-color: $white-color;
  position: relative;
  z-index: 1;
  padding: 50px 105px 50px 50px;
  border-radius: 5px;
  overflow: hidden;

  p {
    color: #606060;
    line-height: 1.8;
    font: {
      size: $font-size;
      weight: 600;
    }
    margin: {
      top: 0;
      bottom: 0;
    }
  }
  .client-info {
    margin-top: 25px;

    img {
      width: 55px !important;
      height: 55px;
    }
    .title {
      margin-left: 15px;

      h3 {
        margin-bottom: 0;
        color: $main-color;
        font: {
          size: 17px;
          weight: 700;
        }
      }
      span {
        display: block;
        color: $optional-color;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
  &::after {
    width: 75px;
    height: 75px;
    content: "";
    border-radius: 50%;
    background-color: $main-color;
    position: absolute;
    left: -25px;
    top: -25px;
    z-index: -1;
  }
  &::before {
    content: "\f10a";
    font-family: Flaticon;
    font-size: 100px;
    position: absolute;
    right: 50px;
    color: #e8e8e8;
    z-index: -1;
    bottom: 25px;
    line-height: 1;
  }
}
.feedback-slides {
  &.owl-theme {
    .owl-nav {
      &.disabled + .owl-dots {
        margin-top: 0;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .owl-dots {
      .owl-dot {
        display: block;

        span {
          margin: 3px 0;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $main-color;

            &::before {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
}
.testimonials-slides {
  max-width: 900px;
  margin: {
    left: auto;
    right: auto;
  }
  &.owl-theme {
    .owl-dots {
      margin-top: 30px !important;

      .owl-dot {
        span {
          margin: 0 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $main-color;

            &::before {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
}
.single-testimonials-item {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding-left: 280px;

  .client-img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 150px !important;
    height: 150px;
    border-radius: 50%;
    display: inline-block !important;
    left: 35px;
  }
  p {
    color: $black-color;
    font: {
      size: 20px;
      weight: 600;
    }
    margin: {
      top: 0;
      bottom: 0;
    }
  }
  h3 {
    color: $main-color;
    margin: {
      top: 18px;
      bottom: 0;
    }
    font: {
      size: 20px;
      weight: 700;
    }
  }
  span {
    display: block;
    color: $optional-color;
    font-size: 15px;
    margin-top: 4px;
  }
  &::before {
    content: "\f10a";
    position: absolute;
    right: 0;
    color: #e8e8e8;
    z-index: -1;
    bottom: 0;
    line-height: 1;
    font: {
      family: Flaticon;
      size: 100px;
    }
  }
  .shape-img {
    img {
      width: auto !important;
      display: inline-block !important;
      position: absolute;

      &.shape-1 {
        position: absolute;
        left: 13%;
        top: 11%;
        z-index: -1;
        animation: movescale 4s linear infinite;
      }
      &.shape-2 {
        left: 0;
        top: 8px;
        z-index: -3;
        animation: {
          name: rotateme;
          duration: 100s;
          iteration-count: infinite;
          timing-function: linear;
        }
      }
      &.shape-3 {
        left: 20px;
        animation: moveleftbounce 4s linear infinite;
        bottom: -30px;
        z-index: -2;
      }
    }
  }
}
.feedback-area {
  position: relative;
  z-index: 1;

  .shape2 {
    bottom: 2%;
  }
  .shape9 {
    left: 8%;
    bottom: auto;
    top: 15%;
  }
}
.single-feedback-box {
  border: 1px solid #ffe8e3;
  position: relative;
  z-index: 1;
  padding: 40px;
  background-color: #fffaf3;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;

  p {
    color: #606060;
    line-height: 1.8;
    font: {
      size: $font-size;
      weight: 600;
    }
    margin: {
      top: 0;
      bottom: 0;
    }
  }
  .client-info {
    margin-top: 25px;

    img {
      width: 55px !important;
      height: 55px;
    }
    .title {
      margin-left: 15px;

      h3 {
        margin-bottom: 0;
        color: $main-color;
        font: {
          size: 17px;
          weight: 700;
        }
      }
      span {
        display: block;
        color: $optional-color;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
  &::before {
    content: "\f10a";
    position: absolute;
    right: 50px;
    color: #ebe6e0;
    z-index: -1;
    bottom: 25px;
    line-height: 1;
    font: {
      family: Flaticon;
      size: 100px;
    }
  }
}
.feedback-slides-two {
  &.owl-theme {
    .owl-dots {
      margin-top: 20px !important;

      .owl-dot {
        span {
          margin: 0 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $main-color;

            &::before {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
}
.single-testimonials-box {
  max-width: 1110px;
  text-align: center;
  padding-right: 90px;
  position: relative;
  z-index: 1;
  margin: {
    left: auto;
    right: auto;
  }
  .client-img {
    width: 150px !important;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 50px;
  }
  p {
    margin-bottom: 0;
    color: $black-color;
    font: {
      size: 24px;
      weight: 600;
    }
  }
  h3 {
    color: $main-color;
    margin: {
      top: 20px;
      bottom: 0;
    }
    font: {
      size: 20px;
      weight: 700;
    }
  }
  span {
    display: block;
    color: $optional-color;
    font-size: 15px;
    margin-top: 4px;
  }
  &::before {
    content: "\f10a";
    position: absolute;
    right: 0;
    color: #e7e2d9;
    z-index: -1;
    bottom: 120px;
    line-height: 1;
    font: {
      family: Flaticon;
      size: 195px;
    }
  }
  .shape-img {
    img {
      width: auto !important;
      display: inline-block !important;
      position: absolute;

      &.shape-1 {
        position: absolute;
        left: 165px;
        top: 0;
        z-index: -1;
        animation: movescale 4s linear infinite;
        right: 0;
        margin: 0 auto;
      }
      &.shape-2 {
        left: -115px;
        top: -80px;
        z-index: -3;
        right: 0;
        margin: 0 auto;
        animation: {
          name: rotateme;
          duration: 100s;
          iteration-count: infinite;
          timing-function: linear;
        }
      }
      &.shape-3 {
        left: 0;
        animation: moveleftbounce 4s linear infinite;
        top: 50px;
        z-index: -2;
        right: 35px;
        margin: 0 auto;
      }
    }
  }
}
.feedback-with-bg-image {
  position: relative;
  z-index: 1;
  background: {
    image: url(../../images/feedback-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.5;
    z-index: -1;
  }
}
.single-feedback-item-box {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  padding: 30px;
  background-color: $main-color;

  &::after {
    content: "\f10a";
    position: absolute;
    right: 90px;
    color: $white-color;
    z-index: -1;
    bottom: 125px;
    line-height: 1;
    opacity: 0.35;
    font: {
      family: Flaticon;
      size: 100px;
    }
  }
  p {
    color: $white-color;
  }
  .client-info {
    margin-top: 25px;

    img {
      width: 55px !important;
      height: 55px;
      border-radius: 50%;
      display: inline-block !important;
    }
    .title {
      margin-left: 15px;

      h3 {
        color: $white-color;
        margin-bottom: 5px;
        font: {
          size: 20px;
          weight: 800;
        }
      }
      span {
        color: $white-color;
        display: block;
      }
    }
  }
}
.feedback-slides-style-two {
  &.feedback-slides {
    &.owl-theme {
      position: relative;
      z-index: 1;
      max-width: 600px;
      border-radius: 5px;
      margin-left: 100px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        z-index: -1;
        background-color: $main-color;
        opacity: 0.85;
      }
      .single-feedback-item-box {
        background-color: transparent;
        border-radius: 0;
        padding: 85px 85px 150px;
      }
      .owl-nav {
        &.disabled + .owl-dots {
          right: auto;
          top: auto;
          transform: unset;
          left: 82px;
          bottom: 80px;
        }
      }
      .owl-dots {
        .owl-dot {
          display: inline-block;

          span {
            border-color: $white-color;
            margin: 0 3px;
          }
          &.active,
          &:hover {
            span {
              &::before {
                background-color: $white-color;
              }
            }
          }
        }
      }
    }
  }
}
.feedback-area {
  &.bg-6ba292 {
    .divider2,
    .divider3 {
      background-color: $main-color2;
    }
    .single-feedback-box {
      background-color: transparent;
      border-color: #8ab6a9;

      p {
        color: $white-color;
      }
      .client-info {
        .title {
          h3 {
            color: $white-color;
          }
          span {
            color: $white-color;
          }
        }
      }
      &::before {
        color: #909279;
      }
    }
    .feedback-slides-two {
      &.owl-theme {
        .owl-dots {
          .owl-dot {
            span {
              border-color: #8ab6a9;

              &::before {
                background-color: #8ab6a9;
              }
            }
            &:hover,
            &.active {
              span {
                border-color: $white-color;

                &::before {
                  background-color: $white-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
.tree-shape {
  position: absolute;
  right: 0;
  bottom: -100px;
  z-index: -1;
}
.health-coaching-feedback-area {
  background-color: #f8fafb;

  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
    .row {
      margin: {
        left: 0;
        right: 0;
      }
      .col-lg-6 {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
}
.health-coaching-feedback-image {
  margin-right: -250px;
}
.health-coaching-inner {
  text-align: center;
  max-width: 615px;

  h2 {
    margin-bottom: 60px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
}
.feedback-quote {
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  background-color: #f9f9f2;
  position: relative;
  padding: 55px 45px;
  z-index: 1;

  p {
    font: {
      size: 19px;
      weight: 600;
    }
  }
  .client-info {
    margin-top: 25px;
    text-align: left;

    img {
      width: 60px !important;
      height: 60px;
      border-radius: 50%;
    }
    .title {
      margin-left: 15px;
      text-align: left;

      h3 {
        margin-bottom: 0;
        color: $main-color;
        font: {
          size: 17px;
          weight: 700;
        }
      }
      span {
        display: block;
        color: $optional-color;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
}
.object1 {
  position: absolute;
  left: -35px;
  top: -30px;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.object2 {
  position: absolute;
  right: 0;
  top: -20px;
  z-index: -1;
}
.object3 {
  position: absolute;
  left: -15px;
  bottom: -15px;
  z-index: -1;
}
.object4 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.feedback-area {
  &.bg-6dbbbd {
    .section-title {
      .sub-title {
        color: $white-color;
      }
      h2 {
        color: $white-color;
      }
      p {
        color: $white-color;
      }
    }
  }
}
.single-kindergarten-feedback-item {
  text-align: center;
  margin-bottom: 30px;

  .content {
    position: relative;

    p {
      position: absolute;
      left: 0;
      right: 0;
      top: 60px;
      padding: 0 80px;
    }
  }
  .client-info {
    margin-top: 10px;
    margin-left: 70px;

    img {
      width: 60px !important;
      display: inline-block !important;
      width: 60px;
      border: 3px solid #d9a416;
      border-radius: 50%;
    }
    h3 {
      color: $white-color;
      margin: {
        top: 15px;
        bottom: 0;
      }
      font: {
        size: 20px;
        weight: 700;
      }
    }
    span {
      display: block;
      color: $white-color;
      margin-top: 4px;
      opacity: 0.9;
    }
  }
}
.feedback-slides-three {
  &.owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        margin: -60px 0 0;
        position: absolute;
        left: 23%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 55px;
        height: 55px;
        line-height: 54px;
        border: 1px dashed $main-color;
        border-radius: 50%;
        background-color: transparent !important;
        color: #d1a1a1;
        font-size: 20px;
        transition: $transition;
        padding-left: 2px !important;

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          background-color: $white-color;
          content: "";
          border-radius: 50%;
          margin: 4px;
          transition: $transition;
        }
        &.owl-next {
          left: auto;
          right: 23%;
        }
        &:hover {
          color: $white-color;

          &::before {
            background-color: $main-color;
          }
        }
      }
    }
  }
}

/*================================================
Profile Area CSS
=================================================*/
.profile-box {
  max-width: 1110px;

  .row {
    margin: {
      left: 0;
      right: 0;
    }
    .col-lg-4,
    .col-lg-8 {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
  .image {
    border-radius: 5px;
    padding-right: 15px;

    img {
      border-radius: 5px;
    }
  }
  .content {
    padding: 20px 0 20px 50px;

    h3 {
      margin-bottom: 7px;
      font: {
        size: 23px;
        weight: 800;
      }
    }
    .sub-title {
      display: block;
      color: $main-color;
      margin-bottom: 15px;
      font: {
        size: 15px;
        weight: 600;
      }
    }
    .info {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-bottom: 8px;
        font-weight: 600;

        span {
          color: $optional-color;
          font-weight: 700;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .social-link {
      padding-left: 0;
      list-style-type: none;
      margin: {
        top: 20px;
        bottom: 0;
      }
      li {
        display: inline-block;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
        a {
          width: 30px;
          height: 30px;
          text-align: center;
          background-color: #e1e1e1;
          font-size: 18px;
          color: $black-color;
          position: relative;
          border-radius: 2px;

          i {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            transform: translateY(-48%);
          }
          &:hover {
            background-color: $main-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.profile-courses-quizzes {
  margin-top: 80px;

  .nav {
    margin-bottom: 0;
    list-style-type: none;
    background-color: #f8f9f8;
    border-radius: 5px;
    justify-content: center;
    border: none;
    text-align: center;
    padding: {
      left: 30px;
      right: 30px;
    }
    .nav-item {
      margin-bottom: 0;

      .nav-link {
        position: relative;
        color: $optional-color;
        border: none;
        background-color: transparent;
        padding: {
          top: 16px;
          bottom: 14px;
          left: 7px;
          right: 7px;
        }
        font: {
          size: 17px;
          weight: 800;
        }
        margin: {
          left: 10px;
          right: 10px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: $main-color;
          transition: $transition;
          transform: scaleX(0);
        }
        &:hover,
        &.active {
          color: $black-color;

          &::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .tab-content {
    margin-top: 30px;

    .nav {
      background-color: transparent;
      border-radius: 0;
      padding: {
        left: 0;
        right: 0;
      }
      .nav-item {
        .nav-link {
          color: $black-color;
          background-color: #f8f9f8;
          border-radius: 30px;
          padding: {
            top: 8px;
            bottom: 8px;
            left: 35px;
            right: 35px;
          }
          font: {
            size: $font-size;
            weight: 600;
          }
          margin: {
            left: 5px;
            right: 5px;
          }
          &::before {
            display: none;
          }
          &:hover,
          &.active {
            color: $white-color;
            background-color: $main-color;
          }
        }
      }
    }
    .tab-content {
      .nav {
        .nav-item {
          .nav-link {
            color: $optional-color;
            background-color: transparent;
            border-radius: 0;
            padding: {
              top: 0;
              bottom: 3px;
              left: 5px;
              right: 5px;
            }
            font: {
              size: 17px;
              weight: 600;
            }
            margin: {
              left: 10px;
              right: 10px;
            }
            &::before {
              display: block;
            }
            &:hover,
            &.active {
              color: $black-color;

              &::before {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
    .table {
      margin-bottom: 30px;

      thead {
        background-color: $black-color;
        color: $white-color;

        tr {
          th {
            white-space: nowrap;
            vertical-align: middle;
            border: none;
            font: {
              size: 18px;
              weight: 800;
            }
            padding: {
              top: 14px;
              bottom: 14px;
              left: 25px;
              right: 25px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            white-space: nowrap;
            color: $optional-color;
            border: none;
            font: {
              size: $font-size;
              weight: 600;
            }
            padding: {
              top: 15px;
              bottom: 14px;
              left: 25px;
              right: 25px;
            }
            a {
              display: inline-block;
              color: $optional-color;

              &:hover {
                color: $main-color;
              }
            }
            .progress {
              display: inline-block;
              border-radius: 30px;
              height: auto;
              padding: 4px 15px 3.5px;
              background-color: $black-color;
              overflow: unset;
              margin-left: 5px;
              position: relative;
              top: -1px;
              color: $white-color;
              font: {
                size: 13px;
                weight: 700;
              }
              &.completed {
                background-color: #539a00;
              }
              &.pending {
                background-color: #ffc107;
              }
              &.passed {
                background-color: #007bff;
              }
              &.failed {
                background-color: #dc3545;
              }
            }
          }
          &:nth-of-type(odd) {
            background-color: #f8f9f8;
          }
        }
      }
    }
  }
  .pagination-area {
    margin-bottom: 30px;
  }
}

.profile-courses {
  .title {
    background-color: #f8f9f8;
    color: #221638;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 800;
    padding: 15px 0;
    font-size: 22px;
  }
}

.profile-quizzes {
  .title {
    background-color: #f8f9f8;
    color: #221638;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 800;
    padding: 15px 0;
    font-size: 22px;
  }

  .table {
    margin-bottom: 30px;

    thead {
      background-color: $black-color;
      color: $white-color;

      tr {
        th {
          white-space: nowrap;
          vertical-align: middle;
          border: none;
          font: {
            size: 18px;
            weight: 800;
          }
          padding: {
            top: 14px;
            bottom: 14px;
            left: 25px;
            right: 25px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          white-space: nowrap;
          color: $optional-color;
          border: none;
          font: {
            size: $font-size;
            weight: 600;
          }
          padding: {
            top: 15px;
            bottom: 14px;
            left: 25px;
            right: 25px;
          }
          a {
            display: inline-block;
            color: $optional-color;

            &:hover {
              color: $main-color;
            }
          }
          .progress {
            display: inline-block;
            border-radius: 30px;
            height: auto;
            padding: 4px 12px 3.5px;
            background-color: $black-color;
            overflow: unset;
            margin-left: 5px;
            position: relative;
            top: -2px;
            color: $white-color;
            font: {
              size: 11px;
              weight: 700;
            }
            &.completed {
              background-color: #539a00;
            }
            &.pending {
              background-color: #ffc107;
            }
            &.passed {
              background-color: #007bff;
            }
            &.failed {
              background-color: #dc3545;
            }
          }
        }
        &:nth-of-type(odd) {
          background-color: #f8f9f8;
        }
      }
    }
  }
}

/*================================================
Selected Ages Area CSS
=================================================*/
.selected-ages-area {
  position: relative;
  z-index: 1;

  .section-title {
    max-width: 500px;
  }
}
.single-selected-ages-box {
  text-align: center;
  margin-bottom: 30px;

  .image {
    margin-bottom: 30px;
  }
  h3 {
    clip-path: polygon(0 0, 100% 0, 99% 100%, 3% 100%);
    background-color: #cce7e8;
    color: #5caaac;
    padding: {
      left: 10px;
      right: 10px;
      top: 10px;
      bottom: 6px;
    }
    font: {
      size: 28px;
      weight: 900;
    }
    margin: {
      left: 15px;
      right: 15px;
      bottom: 20px;
    }
  }
  .ages-number {
    display: block;
    color: #5caaac;
    margin-top: 5px;
    font: {
      size: 16px;
      weight: 700;
    }
  }
}
.col-lg-3 {
  &:nth-child(2) {
    .single-selected-ages-box {
      h3 {
        background-color: #ffb0b4;
        color: #fe4a55;
      }
      .ages-number {
        color: #fe4a55;
      }
    }
  }
  &:nth-child(3) {
    .single-selected-ages-box {
      h3 {
        background-color: #ffeac4;
        color: #ffa70a;
      }
      .ages-number {
        color: #ffa70a;
      }
    }
  }
  &:nth-child(4) {
    .single-selected-ages-box {
      h3 {
        background-color: #ebbcff;
        color: #be22ff;
      }
      .ages-number {
        color: #be22ff;
      }
    }
  }
}

/*================================================
Video Area CSS
=================================================*/
.video-box {
  position: relative;
  border-radius: 5px;
  max-width: 800px;
  z-index: 1;
  margin: {
    left: auto;
    right: auto;
    top: 100px;
  }
  .image {
    overflow: hidden;
    border-radius: 5px;

    img {
      border-radius: 5px;
      transition: $transition;
    }
  }
  .video-btn {
    display: inline-block;
    width: 80px;
    height: 80px;
    font-size: 35px;
    text-align: center;
    line-height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%) translateX(-50%);
    background-color: $main-color;
    border-radius: 50%;
    color: $white-color;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      transition: $transition;
      border-radius: 50%;
      border: 1px solid $main-color;
    }
    &::before {
      animation: ripple 2s linear infinite;
    }
    &::after {
      animation: ripple 2s linear 1s infinite;
    }
    &:hover {
      color: $white-color;
      background-color: $black-color;

      &::before,
      &::after {
        border-color: $black-color;
      }
    }
  }
  &:hover {
    .image {
      img {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.shape10 {
  position: absolute;
  left: -11%;
  bottom: -11%;
  z-index: -1;

  img {
    border-radius: 0;
    animation: moveleftbounce 4s linear infinite;
  }
}

/*================================================
Get Instant Courses Area CSS
=================================================*/
.get-instant-courses-inner-area {
  position: relative;
  z-index: 1;
  background-color: #f9f9f9;
  border-radius: 5px;

  .row {
    margin: {
      left: 0;
      right: 0;
    }
    .col-lg-8,
    .col-lg-4 {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
}
.get-instant-courses-content {
  padding: {
    left: 70px;
    right: 70px;
    top: 70px;
    bottom: 30px;
  }
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 10px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 12px;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  .default-btn {
    margin-top: 5px;
  }
}
.get-instant-courses-image {
  position: relative;
  z-index: 1;
  border-radius: 0 5px 5px 0;

  img {
    border-radius: 0 5px 5px 0;
  }
}
.shape5 {
  position: absolute;
  left: 5.5%;
  top: 0;
  z-index: -1;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}
.shape6 {
  position: absolute;
  left: -65px;
  bottom: 10%;

  img {
    animation: {
      name: rotateme;
      duration: 100s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape7 {
  position: absolute;
  left: 5%;
  bottom: 38%;

  img {
    animation: movescale 4s linear infinite;
  }
}

/*================================================
Events Area CSS
=================================================*/
.single-events-box {
  transition: $transition;
  background-color: $white-color;
  margin-bottom: 30px;

  .image {
    position: relative;
    border-radius: 5px 5px 5px 0;

    img {
      border-radius: 5px 5px 5px 0;
    }
    .date {
      position: absolute;
      left: 0;
      bottom: 0;
      display: inline-block;
      background-color: $white-color;
      color: $main-color;
      padding: 8px 20px 7px;
      border-radius: 0 5px 0 0;
      font: {
        size: 15px;
        weight: 700;
      }
      &::before {
        content: "";
        position: absolute;
        left: 20px;
        bottom: 0;
        width: 60px;
        height: 1px;
        background-color: #acacac;
      }
    }
  }
  .content {
    border-radius: 0 0 5px 5px;
    padding: 20px;
    position: relative;

    h3 {
      margin-bottom: 0;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .location {
      display: block;
      color: $optional-color;
      margin-top: 13px;
      position: relative;
      padding-left: 22px;
      font: {
        size: $font-size;
        weight: 700;
      }
      i {
        color: $main-color;
        position: absolute;
        left: 0;
        top: 2px;
        font-size: 18px;
      }
    }
    &::before {
      left: 20px;
      position: absolute;
      bottom: 0;
      content: "";
      height: 2px;
      width: 45px;
      background-color: #d6d6d6;
    }
  }
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
}
.events-area {
  &.bg-image {
    background: {
      image: url(../../images/events-bg.jpg);
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }
}
.single-events-box-item {
  transition: $transition;
  background-color: $white-color;
  margin-bottom: 30px;

  .image {
    position: relative;
    border-radius: 5px 5px 5px 0;

    img {
      border-radius: 5px 5px 5px 0;
    }
    .date {
      position: absolute;
      left: 0;
      bottom: 0;
      display: inline-block;
      background-color: $main-color2;
      color: $white-color;
      padding: 9px 20px 7px;
      border-radius: 0 5px 0 0;
      font: {
        size: 15px;
        weight: 700;
      }
    }
  }
  .content {
    border-radius: 0 0 5px 5px;
    padding: 20px;
    position: relative;

    h3 {
      margin-bottom: 0;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .location {
      display: block;
      color: $optional-color;
      margin-top: 13px;
      position: relative;
      padding-left: 22px;
      font: {
        size: $font-size;
        weight: 700;
      }
      i {
        color: $main-color;
        position: absolute;
        left: 0;
        top: 2px;
        font-size: 18px;
      }
    }
  }
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
}
.events-area {
  &.bg-shape {
    position: relative;
    z-index: 1;
    padding: {
      top: 140px;
      bottom: 110px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: {
        image: url(../../images/events-rectangle1.png);
        position: top;
        repeat: no-repeat;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: {
        image: url(../../images/events-rectangle2.png);
        position: bottom;
        repeat: no-repeat;
      }
    }
  }
}
.events-box {
  margin-bottom: 30px;
  background-color: $white-color;
  border-radius: 5px;

  .image {
    height: 100%;
    border-radius: 5px;
    background: {
      position: center center;
      size: cover;
      repeat: no-repeat;
    }
    &.bg1 {
      background-image: url(../../images/events/kindergarten-img1.jpg);
    }
    &.bg2 {
      background-image: url(../../images/events/kindergarten-img2.jpg);
    }
    &.bg3 {
      background-image: url(../../images/events/kindergarten-img3.jpg);
    }
    &.bg4 {
      background-image: url(../../images/events/kindergarten-img4.jpg);
    }
    img {
      display: none;
      border-radius: 5px;
    }
  }
  .content {
    padding: 40px;

    .date {
      margin-bottom: 20px;
      display: inline-block;
      position: relative;

      span {
        position: absolute;
        left: 0;
        top: 21px;
        right: 0;
        text-align: center;
        color: $white-color;
        font: {
          size: 16px;
          weight: 700;
        }
      }
    }
    h3 {
      margin-bottom: 12px;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .location {
      display: block;
      color: $optional-color;
      margin-top: 12px;
      position: relative;
      padding-left: 22px;
      font: {
        size: $font-size;
        weight: 700;
      }
      i {
        color: $main-color;
        position: absolute;
        left: 0;
        top: 2px;
        font-size: 18px;
      }
    }
  }
}

/*================================================
Events Details Area CSS
=================================================*/
.events-details-header {
  margin-bottom: 40px;

  ul {
    margin-bottom: 0;
    list-style-type: none;
    background-color: #f8f9f8;
    padding: {
      left: 30px;
      right: 30px;
      top: 14px;
      bottom: 14px;
    }
    li {
      display: inline-block;
      margin-right: 18px;
      color: $optional-color;
      font-weight: 600;
      position: relative;
      padding-left: 22px;

      i {
        color: $main-color;
        position: absolute;
        left: 0;
        top: 1px;
        font-size: 18px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.events-details-desc {
  padding: {
    left: 25px;
    right: 25px;
  }
  h3 {
    margin-bottom: 12px;
    font: {
      size: 24px;
      weight: 800;
    }
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}
.events-details-image {
  position: relative;
  margin-bottom: 50px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: $black-color;
    opacity: 0.4;
  }
  #timer {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%) translateX(-50%);

    div {
      background-color: transparent;
      color: $white-color;
      width: 130px;
      height: 130px;
      position: relative;
      z-index: 1;
      border-radius: 5px;
      font: {
        size: 50px;
        weight: 800;
      }
      margin: {
        left: 8px;
        right: 8px;
      }
      span {
        display: block;
        margin-top: -6px;
        text-transform: uppercase;
        font: {
          size: 19px;
          weight: 500;
        }
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: $main-color;
        opacity: 0.7;
        z-index: -1;
      }
    }
  }
}
.events-details-info {
  background-color: #f8f9f8;
  border-radius: 5px;
  padding: 35px;

  .info {
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0;

    li {
      border-bottom: 1px solid #e5e5e7;
      color: $optional-color;
      font: {
        size: 17px;
        weight: 600;
      }
      padding: {
        top: 15px;
        bottom: 14px;
      }
      span {
        font-weight: 800;
        color: $black-color;
        position: relative;
      }
      &.price {
        padding-bottom: 10px;
        color: $main-color;
        font: {
          size: 28px;
          weight: 800;
        }
        span {
          color: $black-color;
          font: {
            size: 17px;
            weight: 800;
          }
        }
      }
      &:first-child {
        padding-top: 0;
      }

      .payment-method {
        img {
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .btn-box {
    text-align: center;
    margin-top: 30px;

    .default-btn {
      display: block;
      padding: {
        right: 30px;
        left: 30px;
        top: 12px;
        bottom: 12px;
      }
      i {
        position: relative;
        left: 0;
        top: 2px;
        margin-right: 8px;
        line-height: 1;
        font: {
          weight: normal;
          size: 20px;
        }
      }
    }
    p {
      margin-top: 15px;
      font-weight: 600;

      a {
        color: $main-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .events-share {
    text-align: center;
    margin-top: 25px;

    .share-info {
      display: inline-block;
      position: relative;
      cursor: pointer;

      span {
        display: inline-block;
        color: $main-color;
        line-height: 1;
        font: {
          size: $font-size;
          weight: 700;
        }
        i {
          color: $black-color;
        }
      }
      .social-link {
        padding-left: 0;
        list-style-type: none;
        margin: {
          bottom: 0;
          top: 12px;
        }
        li {
          display: inline-block;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
          a {
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: #e1e1e1;
            font-size: 18px;
            color: $black-color;
            position: relative;
            border-radius: 2px;

            i {
              position: absolute;
              left: 0;
              top: 50%;
              right: 0;
              transform: translateY(-48%);
            }
            &:hover {
              background-color: $main-color;
              color: $white-color;
            }
          }
        }
      }
    }
  }
}
.events-details-location {
  margin-bottom: 40px;

  #map {
    iframe {
      height: 400px;
      width: 100%;
    }
  }
}

/*================================================
Lifestyle Area CSS
=================================================*/
.lifestyle-area {
  .section-title {
    h2 {
      max-width: 650px;
    }
  }
}
.single-lifestyle-box {
  margin-bottom: 30px;
  text-align: center;
  transition: $transition;
  border-radius: 5px;
  padding: 40px 20px;
  background-color: #f5f7fa;
  border: 1px dashed $main-color2;

  .icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
    background-color: $white-color;
    font-size: 65px;
    color: $main-color2;
    border-radius: 50%;
    margin: {
      left: auto;
      bottom: 25px;
      right: auto;
    }
  }
  h3 {
    margin-bottom: 17px;
    font: {
      size: 24px;
      weight: 800;
    }
  }
  p {
    transition: $transition;
  }
  .link-btn {
    display: inline-block;
    color: $main-color;
    font: {
      size: 15px;
      weight: 700;
    }
  }
  &:hover {
    background-color: $main-color2;

    h3 {
      color: $white-color;

      a {
        color: $white-color;
      }
    }
    p {
      color: $white-color;
    }
    .link-btn {
      color: $white-color;
    }
  }
}

/*================================================
Program Area CSS
=================================================*/
.program-area {
  position: relative;
  z-index: 1;
  background: {
    image: url(../../images/program-bg.jpg);
    repeat: no-repeat;
    position: center center;
    size: cover;
  }
}
.program-section-title {
  padding-right: 80px;

  h2 {
    margin: {
      bottom: 0;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    margin-top: 20px;
  }
  .default-btn {
    margin-top: 8px;
  }
}
.program-list {
  margin-left: -20px;
  position: relative;
  z-index: 1;

  .row {
    margin: {
      left: -10px;
      right: -10px;
    }
    .col-lg-6 {
      padding: {
        left: 10px;
        right: 10px;
      }
      &:nth-child(2) {
        .single-program-box {
          margin-bottom: 0;

          .shape {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
  .program-circle-shape {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    img {
      animation: {
        name: rotateme;
        duration: 30s;
        iteration-count: infinite;
        timing-function: linear;
      }
    }
  }
  .single-program-box {
    &:nth-child(2) {
      margin-bottom: 0;
    }
  }
}
.single-program-box {
  margin-bottom: 20px;
  text-align: center;
  border-radius: 5px;
  padding: 40px 20px;
  position: relative;
  z-index: 1;
  background-color: $white-color;
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);

  .icon {
    font-size: 60px;
    line-height: 1;
    color: $main-color2;
    margin: {
      bottom: 17px;
    }
  }
  h3 {
    margin-bottom: 15px;
    font: {
      size: 22px;
      weight: 800;
    }
  }
  .link-btn {
    display: inline-block;
    color: $main-color;
    font: {
      size: 15px;
      weight: 700;
    }
    &:hover {
      color: $main-color2;
    }
  }
  .shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
}

/*================================================
Health Services Area CSS
=================================================*/
.health-services-area {
  position: relative;
  z-index: 1;
  background: {
    image: url(../../images/health-services-bg.jpg);
    repeat: no-repeat;
    position: center center;
    size: cover;
  }
}
.single-health-services-box {
  margin-bottom: 30px;
  position: relative;
  padding-left: 92px;

  .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    background-color: $white-color;
    font-size: 40px;
    color: $main-color2;
    animation: border-transform 10s linear infinite alternate forwards;
    border-radius: 50%;
    transition: $transition;
    text-align: center;
    border: 1px solid $main-color2;
    position: absolute;
    left: 0;
    top: 2px;
  }
  h3 {
    margin-bottom: 12px;
    font: {
      size: 24px;
      weight: 800;
    }
  }
  &:hover {
    .icon {
      background-color: $main-color2;
      color: $white-color;
    }
  }
}
@keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  .row {
    .col-lg-8 {
      .row {
        .col-lg-12 {
          .pagination-area {
            margin-top: 25px;
          }
        }
      }
    }
  }
}
.single-blog-post {
  margin-bottom: 30px;
  position: relative;

  .post-image {
    border-radius: 5px 5px 0 0;

    a {
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      img {
        border-radius: 5px 5px 0 0;
        transition: $transition;
        width: 100%;
      }
    }
  }
  .post-content {
    margin-top: 25px;

    .category {
      display: inline-block;
      margin-bottom: 10px;
      color: $optional-color;
      text-transform: uppercase;
      font: {
        size: 14px;
        weight: 700;
      }
      &:hover {
        color: $main-color;
      }
    }
    h3 {
      margin-bottom: 12px;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .post-content-footer {
      list-style-type: none;
      padding-left: 0;
      margin: {
        bottom: 0;
        left: -7px;
        right: -7px;
        top: 18px;
      }
      li {
        color: $optional-color;
        font-size: 15px;
        position: relative;
        padding: {
          left: 29px;
          right: 7px;
        }
        .post-author {
          img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
          span {
            color: $main-color;
            font: {
              weight: 600;
              size: 15px;
            }
          }
        }
        i {
          color: $main-color;
          position: absolute;
          left: 7px;
          top: 0;
        }
        &:first-child {
          padding-left: 7px;
        }
      }
    }
  }
  &:hover {
    .post-image {
      a {
        img {
          transform: rotate(5deg) scale(1.3);
        }
      }
    }
  }
}
.single-blog-post-item {
  margin-bottom: 30px;
  position: relative;

  .post-image {
    border-radius: 5px 5px 0 0;

    a {
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      img {
        border-radius: 5px 5px 0 0;
        transition: $transition;
      }
    }
  }
  .post-content {
    margin-top: 25px;

    .category {
      display: inline-block;
      margin-bottom: 7px;
      color: $optional-color;
      text-transform: uppercase;
      font: {
        size: 14px;
        weight: 700;
      }
      &:hover {
        color: $main-color;
      }
    }
    h3 {
      margin-bottom: 0;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .post-content-footer {
      list-style-type: none;
      padding-left: 0;
      margin: {
        bottom: 0;
        left: -7px;
        right: -7px;
        top: 20px;
      }
      li {
        color: $optional-color;
        font-size: 15px;
        position: relative;
        margin-left: 15px;
        padding: {
          left: 29px;
          right: 7px;
        }
        .post-author {
          img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
          span {
            color: $main-color;
            font: {
              weight: 600;
              size: 15px;
            }
          }
        }
        i {
          color: $main-color;
          position: absolute;
          left: 7px;
          top: 0;
        }
        &:first-child {
          padding-left: 7px;
          margin-left: 0;
        }
      }
    }
  }
  &:hover {
    .post-image {
      a {
        img {
          transform: rotate(5deg) scale(1.3);
        }
      }
    }
  }
}
.blog-post-info {
  border-top: 1px solid #f2f1f3;
  text-align: center;
  padding-top: 30px;

  p {
    max-width: 600px;
    margin: {
      left: auto;
      right: auto;
      bottom: 0;
    }
    a {
      color: $main-color;

      &:hover {
        text-decoration: underline;
        color: $black-color;
      }
    }
  }
}
.blog-post-list {
  .single-blog-post-item {
    .post-content {
      margin-top: 20px;
    }
  }
}
.single-blog-post-box {
  margin-bottom: 30px;
  position: relative;

  .post-image {
    border-radius: 5px 5px 0 0;

    a {
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      img {
        border-radius: 5px 5px 0 0;
        transition: $transition;
      }
    }
  }
  .post-content {
    background-color: #fffaf3;
    padding: 25px;

    .category {
      display: inline-block;
      margin-bottom: 7px;
      color: $optional-color;
      text-transform: uppercase;
      font: {
        size: 14px;
        weight: 700;
      }
      &:hover {
        color: $main-color;
      }
    }
    h3 {
      margin-bottom: 12px;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .post-content-footer {
      list-style-type: none;
      padding-left: 0;
      margin: {
        bottom: 0;
        left: -7px;
        right: -7px;
        top: 20px;
      }
      li {
        color: $optional-color;
        font-size: 15px;
        position: relative;
        padding: {
          left: 29px;
          right: 7px;
        }
        .post-author {
          img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
          span {
            color: $main-color;
            font: {
              weight: 600;
              size: 15px;
            }
          }
        }
        i {
          color: $main-color;
          position: absolute;
          left: 7px;
          top: 0;
        }
        &:first-child {
          padding-left: 7px;
        }
      }
    }
  }
  &:hover {
    .post-image {
      a {
        img {
          transform: rotate(5deg) scale(1.3);
        }
      }
    }
  }
}
.blog-slides {
  &.owl-theme {
    .owl-dots {
      margin: {
        top: 20px !important;
        bottom: 35px;
      }
      .owl-dot {
        span {
          margin: 0 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $main-color;

            &::before {
              background-color: $main-color;
            }
          }
        }
      }
    }
    .owl-nav {
      margin-top: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;

      [class*="owl-"] {
        margin: 0 40px;
        width: 35px;
        height: 35px;
        border-radius: 3px;
        transition: $transition;
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        line-height: 42px;
        padding: 0;
        font-size: 25px;
        color: $main-color;

        &:hover {
          background-color: $main-color;
          color: $white-color;
        }
      }
    }
  }
}
.blog-items {
  margin: {
    left: -15px;
    right: -15px;
  }
}
.blog-area {
  position: relative;
  z-index: 1;
}
.blog-shape1 {
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.blog-shape2 {
  position: absolute;
  right: 0;
  top: 35%;
  transform: translateY(-35%);
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.single-blog-item {
  margin-bottom: 30px;
  position: relative;

  .post-image {
    border-radius: 5px 5px 0 0;
    position: relative;
    z-index: 1;

    a {
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      img {
        border-radius: 5px 5px 0 0;
        transition: $transition;
        width: 100%;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: {
        image: url(../../images/shape-style1.png);
        position: bottom;
        repeat: no-repeat;
      }
    }
    .link-btn {
      border-radius: 0;
      overflow: unset;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  .post-content {
    .category {
      display: inline-block;
      margin-bottom: 10px;
      color: $optional-color;
      text-transform: uppercase;
      font: {
        size: 14px;
        weight: 700;
      }
      &:hover {
        color: $main-color;
      }
    }
    h3 {
      margin-bottom: 0;
      line-height: 1.3;
      font: {
        size: 24px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
  }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
  .article-image {
    img {
      width: 100%;
    }
  }
  .article-image-slides {
    &.owl-theme {
      .owl-nav {
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        [class*="owl-"] {
          color: #87a1c1;
          font-size: 18px;
          margin: 0;
          padding: 0;
          background: $white-color;
          width: 45px;
          height: 45px;
          line-height: 40px;
          position: absolute;
          left: 20px;
          transition: $transition;
          top: 50%;
          transform: translateY(-50%);
          padding: {
            left: 5px !important;
          }
          border: {
            radius: 50%;
            style: solid;
            color: $white-color;
            width: 1.5px;
          }
          &.owl-next {
            left: auto;
            right: 20px;
            padding: {
              left: 3px !important;
            }
          }
          &:hover {
            background-color: $main-color;
            color: $white-color;
            border-color: $main-color;
          }
        }
      }
      &:hover {
        .owl-nav {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .article-video {
    iframe {
      width: 100%;
      height: 400px;
      border: none !important;
    }
  }
  .article-content {
    margin-top: 30px;

    .entry-meta {
      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          margin-right: 20px;
          position: relative;
          display: inline-block;
          border-right: 1px solid #eeeeee;
          font: {
            weight: 600;
            size: $font-size;
          }
          padding: {
            right: 20px;
            left: 45px;
          }
          i {
            font-size: 32px;
            color: #cfcfcf;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          span {
            display: block;
            color: $main-color;
            text-transform: uppercase;
            margin-bottom: 2px;
            font: {
              size: 14px;
              weight: 600;
            }
          }
          a {
            display: inline-block;
            font: {
              size: $font-size;
              weight: 700;
            }
          }
          &:last-child {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
          }
        }
      }
    }
    h3 {
      margin: {
        bottom: 16px;
        top: 28px;
      }
      font: {
        size: 24px;
        weight: 800;
      }
    }
    .wp-block-gallery {
      &.columns-3 {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
          right: -10px;
          left: -10px;
          bottom: 30px;
          top: 30px;
        }
        li {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
          padding: {
            right: 10px;
            left: 10px;
          }
          figure {
            margin-bottom: 0;
          }
        }
      }
    }
    .features-list {
      padding-left: 0;
      list-style-type: none;
      margin: {
        top: 20px;
        bottom: 30px;
      }
      li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 26px;
        color: $optional-color;

        i {
          color: $main-color;
          display: inline-block;
          font-size: 20px;
          position: absolute;
          left: 0;
          top: 1px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .article-footer {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #eeeeee;
    padding-top: 30px;
    margin: {
      top: 30px;
    }
    .article-tags {
      flex: 0 0 50%;
      max-width: 50%;
      color: $optional-color;

      span {
        display: inline-block;
        color: $black-color;
        margin-right: 3px;
        position: relative;
        top: 3px;
        font: {
          size: 20px;
        }
      }
      a {
        display: inline-block;
        font: {
          weight: 700;
        }
        position: relative;
        padding-right: 3px;
        margin-right: 3px;

        &::before {
          content: ",";
          position: absolute;
          right: 0;
        }

        &:last-child::before {
          display: none;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
    .article-share {
      flex: 0 0 50%;
      max-width: 50%;

      .social {
        padding-left: 0;
        list-style-type: none;
        text-align: right;
        margin: {
          bottom: 0;
        }
        li {
          display: inline-block;

          span {
            display: inline-block;
            margin-right: 3px;
            font-weight: 700;
            position: relative;
            top: -1px;
          }
          a {
            display: block;
            width: 32px;
            height: 32px;
            line-height: 34px;
            border-radius: 50%;
            background-color: $main-color;
            color: $white-color;
            border: 1px solid $main-color;
            text-align: center;
            font-size: 17px;
            margin-left: 5px;

            &:hover,
            &:focus {
              color: $main-color;
              background-color: transparent;
            }
            &.facebook {
              background-color: #3b5998;
              border-color: #3b5998;
              color: $white-color;

              &:hover,
              &:focus {
                color: #3b5998;
                background-color: transparent;
              }
            }
            &.twitter {
              background-color: #1da1f2;
              border-color: #1da1f2;
              color: $white-color;

              &:hover,
              &:focus {
                color: #1da1f2;
                background-color: transparent;
              }
            }
            &.linkedin {
              background-color: #007bb5;
              border-color: #007bb5;
              color: $white-color;

              &:hover,
              &:focus {
                color: #007bb5;
                background-color: transparent;
              }
            }
            &.instagram {
              background-color: #c13584;
              border-color: #c13584;
              color: $white-color;

              &:hover,
              &:focus {
                color: #c13584;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  .article-author {
    margin-top: 30px;
    border-radius: 5px;
    background: #fdfcfc;

    .author-profile-header {
      height: 115px;
      border-radius: 5px 5px 0 0;
      background: {
        color: $main-color;
        image: url(../../images/bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
      }
    }
    .author-profile {
      padding: 0 25px 25px;

      .author-profile-title {
        position: relative;
        z-index: 1;
        margin: {
          bottom: 15px;
          top: -45px;
        }
        img {
          display: inline-block;
          border: 3px solid $white-color;
          width: 100px;
          height: 100px;
        }
        .author-profile-title-details {
          margin-top: 15px;

          .author-profile-details {
            h4 {
              margin-bottom: 5px;
              font: {
                size: 22px;
                weight: 800;
              }
            }
            span {
              color: $optional-color;
            }
          }
        }
      }
    }
  }
}
blockquote,
// .blockquote {
//   overflow: hidden;
//   background-color: #fafafa;
//   padding: 50px !important;
//   position: relative;
//   text-align: center;
//   z-index: 1;
//   margin: {
//     bottom: 20px;
//     top: 20px;
//   }
//   p {
//     color: $black-color;
//     line-height: 1.6;
//     margin-bottom: 0;
//     font: {
//       style: italic;
//       weight: 700;
//       size: 22px !important;
//     }
//   }
//   cite {
//     display: none;
//   }
//   &::before {
//     color: #efefef;
//     position: absolute;
//     animation: fade-up 1.5s infinite linear;
//     left: 50px;
//     top: -50px;
//     z-index: -1;
//     content: "\f10a";
//     font: {
//       family: Flaticon;
//       size: 135px;
//     }
//   }
//   &::after {
//     content: "";
//     position: absolute;
//     left: 0;
//     top: 0;
//     bottom: 0;
//     width: 3px;
//     background-color: $main-color;
//     margin: {
//       top: 20px;
//       bottom: 20px;
//     }
//   }
// }
.escolalms-post-navigation {
  display: flex;
  flex-wrap: wrap;
  margin: {
    top: 30px;
  }
  padding: {
    top: 30px;
    bottom: 30px;
  }
  border: {
    top: 1px solid #eeeeee;
    bottom: 1px solid #eeeeee;
  }
}
.prev-link-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  padding: {
    right: 15px;
  }
  a {
    display: flex;
    align-items: center;

    &:hover {
      .image-prev {
        &::after {
          opacity: 1;
          visibility: visible;
        }
        .post-nav-title {
          opacity: 1;
          visibility: visible;
        }
      }
      .prev-link-info-wrapper {
        color: $main-color;
      }
    }
  }
  .image-prev {
    display: inline-block;
    min-width: 100px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: top;
    margin-right: 20px;
    position: relative;
    transition: $transition;

    img {
      border-radius: 5px;
    }
    &::after {
      display: block;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
      opacity: 0;
      background-color: $main-color;
      visibility: hidden;
      transition: $transition;
    }
    .post-nav-title {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      z-index: 2;
      color: $white-color;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      font: {
        size: 16px;
        weight: 700;
      }
    }
  }
  .prev-link-info-wrapper {
    color: $black-color;
    transition: $transition;
  }
  .prev-title {
    display: inline-block;
    font: {
      weight: 800;
      size: 16.5px;
    }
  }
  .meta-wrapper {
    display: block;
    color: $optional-color;
    text-transform: capitalize;
    margin-top: 5px;
    font: {
      weight: 500;
      size: 14px;
    }
  }
}
.next-link-wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
  padding: {
    left: 15px;
  }
  a {
    display: flex;
    align-items: center;

    &:hover {
      .image-next {
        &::after {
          opacity: 1;
          visibility: visible;
        }
        .post-nav-title {
          opacity: 1;
          visibility: visible;
        }
      }
      .next-link-info-wrapper {
        color: $main-color;
      }
    }
  }
  .image-next {
    display: inline-block;
    min-width: 100px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: top;
    margin-left: 20px;
    position: relative;
    transition: $transition;

    img {
      border-radius: 5px;
    }
    &::after {
      display: block;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
      opacity: 0;
      background-color: $main-color;
      visibility: hidden;
      transition: $transition;
    }
    .post-nav-title {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      z-index: 2;
      color: $white-color;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      font: {
        size: 16px;
        weight: 500;
      }
    }
  }
  .next-link-info-wrapper {
    transition: $transition;
    color: $black-color;
  }
  .next-title {
    display: inline-block;
    font: {
      weight: 800;
      size: 16.5px;
    }
  }
  .meta-wrapper {
    display: block;
    color: $optional-color;
    text-transform: capitalize;
    margin-top: 5px;
    font: {
      weight: 500;
      size: 14px;
    }
  }
}
.comments-area {
  margin-top: 30px;

  .comments-title {
    line-height: initial;
    margin: {
      bottom: 30px;
    }
    font: {
      size: 24px;
      weight: 800;
    }
  }
  ol,
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .comment-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .children {
    margin-left: 20px;
  }
  .comment-body {
    border-bottom: 1px dashed #eeeeee;
    padding-left: 65px;
    color: $black-color;
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .reply {
      margin-top: 15px;

      a {
        border: 1px dashed #ded9d9;
        color: $black-color;
        display: inline-block;
        padding: 6px 20px 3px;
        border-radius: 30px;
        text-transform: uppercase;
        font: {
          size: 13px;
          weight: 700;
        }
        &:hover {
          color: $white-color;
          background-color: $main-color;
          border-color: $main-color;
        }
      }
    }
  }
  .comment-author {
    font-size: 16.5px;
    margin-bottom: 0.3em;
    position: relative;
    z-index: 2;

    .avatar {
      height: 50px;
      left: -65px;
      position: absolute;
      width: 50px;
    }
    .fn {
      font: {
        weight: 800;
      }
    }
    .says {
      display: none;
    }
  }
  .comment-metadata {
    margin-bottom: 0.8em;
    color: $optional-color;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font: {
      size: 13px;
      weight: 400;
    }
    a {
      display: inline-block;
      color: $optional-color;

      &:hover {
        color: $main-color;
      }
    }
  }
  .comment-respond {
    margin-top: 30px;

    .comment-reply-title {
      margin-bottom: 0;
      font: {
        size: 24px;
        weight: 800;
      }
      #cancel-comment-reply-link {
        display: inline-block;
      }
    }
    .comment-form {
      overflow: hidden;
    }
    .comment-notes {
      margin: {
        bottom: 20px;
        top: 10px;
      }
      .required {
        color: red;
      }
    }
    .comment-form-comment {
      float: left;
      width: 100%;
    }
    label {
      display: none;
    }
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="week"],
    input[type="month"],
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="number"],
    textarea {
      display: block;
      width: 100%;
      background-color: #f4f4f4;
      border: none;
      padding: 1px 0 0 15px;
      height: 50px;
      outline: 0;
      border-radius: 3px;
      color: $black-color;
      font: {
        weight: 600;
        size: 15px;
      }
      &::placeholder {
        transition: $transition;
        color: $optional-color;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
    textarea {
      height: auto !important;
      padding-top: 15px;
    }
    .comment-form-author {
      float: left;
      width: 50%;
      padding-right: 10px;
      margin-bottom: 20px;
    }
    .comment-form-email {
      float: left;
      width: 50%;
      padding-left: 12px;
      margin-bottom: 20px;
    }
    .comment-form-url {
      float: left;
      width: 100%;
      margin-bottom: 20px;
    }
    .comment-form-cookies-consent {
      width: 100%;
      float: left;
      position: relative;
      padding-left: 20px;
      margin: {
        bottom: 20px;
        top: 15px;
      }
      input {
        position: absolute;
        left: 0;
        top: 4px;
      }
      label {
        display: inline-block;
        margin: 0;
        color: $optional-color;
        position: relative;
        top: -1px;
        font: {
          weight: normal;
          size: $font-size;
        }
      }
    }
    .form-submit {
      float: left;
      width: 100%;

      input {
        background: $main-color;
        border: none;
        color: $white-color;
        padding: 12px 30px 10px;
        display: inline-block;
        cursor: pointer;
        text-transform: uppercase;
        transition: $transition;
        border-radius: 0;
        font: {
          weight: 800;
          size: 14.5px;
        }
        &:hover,
        &:focus {
          color: $white-color;
          background: $black-color;
        }
      }
    }
  }
}

/*================================================
Become Instructor & Partner CSS
=================================================*/
.become-instructor-partner-area {
  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
    .row {
      margin: {
        left: 0;
        right: 0;
      }
      .col-lg-6 {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
}
.become-instructor-partner-content {
  position: relative;
  z-index: 1;
  padding: 100px;
  background: {
    image: url(../../images/pattern.png);
    position: center center;
    size: contain;
    repeat: repeat;
  }
  h2 {
    margin-bottom: 10px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    margin-bottom: 20px;
    max-width: 540px;
  }
  .default-btn {
    border-radius: 30px;
  }
  &.bg-color {
    background: {
      color: $main-color;
      image: unset !important;
    }
    h2 {
      color: $white-color;
    }
    p {
      color: $white-color;
      opacity: 0.97;
    }
    .default-btn {
      background-color: $white-color;
      color: $black-color;

      &:hover {
        color: $white-color;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: {
        // image: url(../../images/bg-lines.svg);
        position: center center;
        size: cover;
        repeat: no-repeat;
      }
    }
  }
}
.become-instructor-partner-image {
  width: 100%;
  height: 100%;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &.bg-image1 {
    // background-image: url(../../images/become-instructor.jpg);
  }
  &.bg-image2 {
    // background-image: url(../../images/become-partner.jpg);
  }
  img {
    display: none;
  }
}

/*================================================
View All Courses Area CSS
=================================================*/
.view-all-courses-area {
  padding-top: 20px;
  position: relative;
  z-index: 1;

  .row {
    margin: {
      left: 0;
      right: 0;
    }
    .col-lg-6 {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
}
.view-all-courses-content {
  margin-left: auto;
  position: relative;
  z-index: 1;
  max-width: 600px;

  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 0;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    margin: {
      top: 12px;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.view-all-courses-image {
  margin-left: -270px;
  text-align: center;

  img {
    width: 100%;
  }
}
.view-all-courses-area-two {
  position: relative;
  z-index: 1;

  .view-all-courses-content {
    margin-left: 0;
    max-width: 100%;
  }
  .view-all-courses-image {
    margin-left: 0;
    position: relative;
    z-index: 1;

    img {
      width: auto;
    }
  }
}
.shape9 {
  position: absolute;
  left: 10%;
  bottom: 20%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 10s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape11 {
  position: absolute;
  left: 15%;
  top: 18%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 10s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape12 {
  position: absolute;
  bottom: 18%;
  z-index: -1;
  right: 18%;

  img {
    animation: movescale 4s linear infinite;
  }
}

/*================================================
Information Area CSS
=================================================*/
.information-content {
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 45px;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  .apply-details {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      position: relative;
      margin-top: 30px;
      padding-left: 108px;

      .icon {
        width: 85px;
        height: 85px;
        line-height: 84px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $main-color;
        color: $white-color;
        font-size: 40px;
        text-align: center;
        transition: $transition;
      }
      h3 {
        margin-bottom: 10px;
        font: {
          size: 24px;
          weight: 800;
        }
      }
      &:hover {
        .icon {
          background-color: $black-color;
          color: $white-color;
        }
      }
    }
  }
}

/*================================================
Free Trial Area CSS
=================================================*/
.free-trial-form {
  background-color: #f3f3f3;
  padding: 50px 30px;

  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 30px;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  form {
    .form-group {
      margin-bottom: 22px;
    }
    input {
      background-color: $white-color !important;
    }
    button {
      border: none;
      text-align: center;
      color: $white-color;
      background-color: $main-color;
      transition: $transition;
      border-radius: 5px;
      display: block;
      width: 100%;
      font: {
        weight: 700;
        size: $font-size;
      }
      padding: {
        left: 30px;
        right: 30px;
        top: 13px;
        bottom: 13px;
      }
      &:hover {
        color: $white-color;
        background-color: $black-color;
      }
    }
  }
}

/*================================================
Newsletter Modal CSS
=================================================*/
.newsletter-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.9);

  .newsletter-modal-content {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-color: #fef8ef;
    max-width: 780px;
    border-radius: 5px;
    margin: {
      left: auto;
      right: auto;
    }
    .modal-image {
      border-radius: 5px 0 0 5px;

      img {
        border-radius: 5px 0 0 5px;
        width: 100%;
      }
    }
    .modal-inner-content {
      padding: 38px 35px;

      h2 {
        margin-bottom: 15px;
        font: {
          size: 36px;
          weight: 800;
        }
      }
      .sub-text {
        color: $black-color;
        display: block;
        line-height: 1.5;
        font: {
          weight: 600;
          size: 17px;
        }
      }
      p {
        font-size: 14px;
      }
      form {
        position: relative;
        margin: {
          top: 30px;
          bottom: 22px;
        }
        .input-newsletter {
          display: block;
          width: 100%;
          height: 50px;
          border: none;
          background-color: #e0d9d9;
          padding: 2px 0 0 15px;
          color: $black-color;
          border-radius: 5px;
          font: {
            size: 15px;
            weight: 600;
          }
          &::placeholder {
            color: $optional-color;
            transition: $transition;
          }
          &:focus {
            &::placeholder {
              color: transparent;
            }
          }
        }
        button {
          border: none;
          text-align: center;
          color: $white-color;
          background-color: $main-color;
          transition: $transition;
          border-radius: 5px;
          height: 50px;
          position: absolute;
          right: 0;
          top: 0;
          display: inline-block;
          font: {
            weight: 700;
            size: 15px;
          }
          padding: {
            left: 22px;
            right: 22px;
            top: 12px;
            bottom: 12px;
          }
          &:hover {
            color: $white-color;
            background-color: $black-color;
          }
        }
      }
    }
    .close-btn {
      position: absolute;
      right: 20px;
      cursor: pointer;
      color: $black-color;
      top: 15px;
      transition: $transition;

      &:hover {
        color: red;
      }
    }
  }
}

/*================================================
Training Area CSS
=================================================*/
.training-area {
  position: relative;
  z-index: 1;
}
.single-training-box {
  margin-bottom: 30px;
  background-color: $white-color;
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  padding: {
    left: 20px;
    right: 20px;
    bottom: 30px;
  }
  img {
    margin-bottom: 30px;
  }
  h3 {
    margin-bottom: 14px;
    font: {
      size: 23px;
      weight: 800;
    }
  }
  p {
    margin-bottom: 15px;
  }
  &::before {
    background-color: #f5f7fa;
    position: absolute;
    top: -55%;
    left: -5%;
    width: 110%;
    height: 100%;
    content: "";
    z-index: -1;
    border-radius: 50%;
  }
  .link-btn {
    display: inline-block;
    color: $main-color;
    font: {
      size: 15px;
      weight: 700;
    }
    &:hover {
      color: $black-color;
    }
  }
}
.tree-shape2 {
  position: absolute;
  left: 0;
  top: 5%;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.tree-shape3 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
  position: relative;
  z-index: 1;

  .shape3 {
    right: auto;
    bottom: 35%;
    left: 3%;
  }
  .shape4 {
    right: auto;
    bottom: 52%;
    left: 10%;
  }
  .shape2 {
    left: auto;
    bottom: 39%;
    right: 5%;
  }
  .shape9 {
    left: 40%;
    top: 60px;
  }
}
.overview-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: {
    right: -15px;
    left: -15px;
    top: 80px;
  }
  .overview-content,
  .overview-image {
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
      left: 15px;
      right: 15px;
    }
  }
  .overview-content {
    .sub-title {
      display: block;
      text-transform: uppercase;
      color: $main-color;
      margin-bottom: 10px;
      font: {
        size: 14px;
        weight: 600;
      }
    }
    h2 {
      margin-bottom: 15px;
      font: {
        size: 36px;
        weight: 800;
      }
    }
    .default-btn {
      margin-top: 10px;
    }
    .btn-box {
      margin-top: 25px;

      .playstore-btn {
        display: inline-block;
        background-color: $black-color;
        color: $white-color;
        position: relative;
        border-radius: 5px;
        font: {
          size: 14px;
          weight: 500;
        }
        padding: {
          right: 20px;
          top: 7px;
          bottom: 5px;
          left: 60px;
        }
        img {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
        span {
          display: block;
          margin-top: -2px;
          font: {
            size: 18px;
            weight: 700;
          }
        }
      }
      .applestore-btn {
        display: inline-block;
        background-color: $black-color;
        color: $white-color;
        position: relative;
        margin-left: 15px;
        border-radius: 5px;
        font: {
          size: 14px;
          weight: 500;
        }
        padding: {
          right: 20px;
          top: 7px;
          bottom: 5px;
          left: 65px;
        }
        img {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
        span {
          display: block;
          margin-top: -2px;
          font: {
            size: 18px;
            weight: 700;
          }
        }
      }
    }
  }
  .overview-image {
    text-align: center;
  }
  &:first-child {
    margin-top: 0;
  }
}

/*================================================
Teacher Register Area CSS
=================================================*/
.teacher-register-area {
  background: {
    image: url(../../images/teacher-register-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
}
.teacher-register-box {
  overflow: hidden;
  margin-left: auto;
  max-width: 600px;
  padding: 45px;
  border-radius: 5px;
  background: {
    color: $main-color;
    image: url(../../images/register-shape.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  h2 {
    margin-bottom: 0;
    color: $white-color;
    font: {
      size: 33px;
      weight: 800;
    }
  }
  p {
    color: $white-color;
    margin: {
      top: 10px;
      bottom: 0;
    }
  }
  form {
    margin-top: 25px;

    input,
    textarea {
      display: block;
      width: 100%;
      border: none;
      color: $white-color;
      background-color: #c22b34;
      height: 50px;
      padding-left: 15px;
      border-radius: 5px;
      font: {
        size: $font-size;
        weight: 600;
      }
      &::placeholder {
        color: #cdcdcd;
        transition: $transition;
      }
    }
    .form-control {
      &:focus {
        background-color: #c22b34;
        color: #fff;
      }
    }
    textarea {
      height: auto !important;
      padding-top: 15px;
    }
    .default-btn {
      background-color: $black-color;
      margin-top: 5px;
      padding: 12px 30px;

      span {
        background-color: $white-color;
      }
      &:hover {
        color: $main-color;
      }
    }
  }
  .invalid-feedback {
    color: #fff;
  }
}

/*================================================
Experience Area CSS
=================================================*/
.experience-area {
  &.extra-padding {
    padding-top: 150px;
  }
}
.experience-content {
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 10px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin-bottom: 15px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  .features-list {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
      top: 25px;
    }
    li {
      color: $black-color;
      margin-bottom: 20px;
      font-weight: 700;
      position: relative;
      padding: {
        left: 40px;
        top: 5px;
      }
      i {
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: $white-color;
        background-color: #a3c5bb;
        z-index: 1;
        border-radius: 50%;
        font-size: 20px;
        transition: $transition;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: $main-color2;
          border-radius: 50%;
          z-index: -1;
          margin: 2px 1px 1px 2px;
          transition: $transition;
        }
      }
      &:hover {
        i {
          background-color: $main-color;
          color: $main-color;

          &::after {
            background-color: $white-color;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .default-btn {
    margin-top: 35px;
  }
}
.experience-image {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: {
    top: 35px;
    left: 35px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    right: 0;
    top: 0;
    background-color: $main-color;
    margin-right: 35px;
  }
  &::after {
    background-color: #000000;
    opacity: 0.1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    margin: {
      left: 35px;
      top: 35px;
    }
  }
  .video-btn {
    display: inline-block;
    width: 80px;
    height: 80px;
    font-size: 35px;
    z-index: 3;
    text-align: center;
    line-height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: $main-color2;
    border-radius: 7px;
    color: $white-color;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      border-radius: 7px;
      border: 1px solid $white-color;
    }
    &::before {
      animation: ripple 2s linear infinite;
    }
    &::after {
      animation: ripple 2s linear 1s infinite;
    }
    &:hover {
      background-color: $main-color;
    }
  }
  .title {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    background-color: $main-color;
    color: $white-color;
    border-radius: 0 30px 0 0;
    padding: {
      top: 15px;
      bottom: 13px;
      left: 55px;
      right: 30px;
    }
    font: {
      size: 35px;
      weight: 700;
    }
    span {
      font-weight: 800;
    }
  }
}
.experience-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.experience-content {
  position: relative;
  z-index: 1;

  .shape {
    position: absolute;
    right: 0;
    z-index: -1;
    top: 65%;
    transform: translateY(-65%);

    img {
      animation: movebounce 5s linear infinite;
    }
  }
}
.experience-img {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-left: 30px;
  padding: {
    left: 15px;
    right: 15px;
    bottom: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: $main-color;
    z-index: -1;
  }
  .title {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    background-color: $main-color2;
    color: $white-color;
    width: 100%;
    padding: {
      top: 15px;
      bottom: 12px;
      left: 30px;
      right: 30px;
    }
    font: {
      size: 35px;
      weight: 700;
    }
    span {
      font-weight: 800;
    }
  }
}
.experience-shape1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.experience-shape2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

/*================================================
Download Syllabus Area CSS
=================================================*/
.download-syllabus-area {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: {
      image: url(../../images/download-shape.png);
      position: bottom;
      repeat: no-repeat;
    }
  }
}
.download-syllabus-image {
  text-align: center;
}
.download-syllabus-form {
  background-color: $main-color2;
  position: relative;
  z-index: 1;
  padding: 50px;
  border-radius: 5px;

  h2 {
    color: $white-color;
    margin-bottom: 10px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    margin-bottom: 0;
    font-weight: 600;
    color: $white-color;
  }
  form {
    margin-top: 25px;

    .form-group {
      .form-control {
        background-color: #447b6b;
        color: $white-color;
        border: none !important;

        &::placeholder {
          color: #eeeeee;
        }
        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
      .nice-select {
        background-color: #447b6b;
        border-radius: 3px;
        border: none;
        color: $white-color;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        padding: {
          left: 15px;
          right: 30px;
        }
        &:after {
          border-color: #eeeeee;
          right: 15px;
          width: 8px;
          height: 8px;
        }
        .list {
          background-color: $white-color;
          border-radius: 5px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          list-style-type: none;
          border: none;
          width: 100%;
          margin: {
            bottom: 0;
            top: 0;
          }
          padding: {
            left: 0;
            top: 10px;
            bottom: 10px;
          }
          .option {
            line-height: 38px;
            min-height: 38px;
            color: $black-color;
            position: relative;
            transition: $transition;
            padding: {
              left: 32px;
              right: 25px;
            }
            font: {
              size: $font-size;
              weight: 700;
            }
            &:hover {
              background-color: $main-color !important;
              color: $white-color;

              &::before {
                color: $white-color;
              }
            }
            &.focus,
            &.selected.focus {
              background-color: transparent !important;
              color: $black-color;
            }
            &::before {
              content: "\f11f";
              position: absolute;
              left: 10px;
              top: 0;
              opacity: 0;
              visibility: hidden;
              transition: $transition;
              color: $main-color;
              font: {
                family: Flaticon;
                size: 11px;
              }
            }
            &.selected {
              &::before {
                opacity: 1;
                visibility: visible;
              }
              &:hover {
                background-color: $main-color !important;
                color: $white-color;

                &::before {
                  color: $white-color;
                }
              }
            }
          }
        }
      }
    }
    .default-btn {
      margin-top: 5px;
    }
  }
}
.syllabus-shape1 {
  position: absolute;
  right: 15%;
  bottom: 0;
  z-index: -1;

  img {
    animation: moveleftbounce 10s linear infinite;
  }
}
.syllabus-shape2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.syllabus-shape3 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

/*================================================
Premium Access Area CSS
=================================================*/
.premium-access-area {
  position: relative;
  z-index: 1;

  .shape3 {
    right: 20%;
    bottom: 12%;
  }
  .shape4 {
    right: 16%;
    bottom: 50%;
  }
}
.premium-access-content {
  text-align: center;
  max-width: 720px;
  margin: {
    left: auto;
    right: auto;
  }
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    max-width: 615px;
    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    max-width: 615px;
    margin: {
      left: auto;
      right: auto;
      top: 12px;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.shape8 {
  position: absolute;
  left: 20%;
  top: 25%;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}

/*================================================
Apply Instructor Area CSS
=================================================*/
.apply-instructor-image {
  h2 {
    margin-bottom: 18px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  img {
    border-radius: 5px;
  }
}
.apply-instructor-content {
  background-color: #f8f9f8;
  border-radius: 5px;
  padding: 30px;

  .react-tabs__tab-list {
    border-bottom-color: #d8d8d8;
    margin-bottom: 35px;
    border-width: 1.5px;

    .react-tabs__tab {
      margin: {
        right: 50px;
        bottom: -1.5px;
      }
      background-color: transparent;
      color: $optional-color;
      border: none;
      padding: 0 0 10px;
      position: relative;
      font: {
        size: 18px;
        weight: 800;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        transition: $transition;
        height: 1.5px;
        background-color: $main-color;
      }
      &:hover,
      &.react-tabs__tab--selected {
        color: $black-color;

        &::before {
          width: 40%;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  h3 {
    margin-bottom: 13px;
    font: {
      size: 24px;
      weight: 800;
    }
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}

/*================================================
Breadcrumbs CSS
=================================================*/

@import "_breadcrumbs/_breadcrumbs";

/*================================================
Page Title Area CSS
=================================================*/

@import "_page-title/_page-title";

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  position: relative;
  z-index: 1;

  .shape4 {
    right: auto;
    bottom: 39%;
    left: 25%;
  }
}
.subscribe-content {
  text-align: center;
  max-width: 720px;
  margin: {
    left: auto;
    right: auto;
  }
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    max-width: 615px;
    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    max-width: 615px;
    margin: {
      left: auto;
      right: auto;
      top: 12px;
    }
  }
  .newsletter-form {
    position: relative;
    max-width: 650px;
    margin: {
      top: 30px;
      left: auto;
      right: auto;
    }
    .input-newsletter {
      display: block;
      width: 100%;
      border: none;
      background-color: #eaeaea;
      color: $black-color;
      height: 50px;
      padding-left: 18px;
      border-radius: 5px;
      font: {
        size: 16px;
        weight: 400;
      }
      &::placeholder {
        color: #999999;
        transition: $transition;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
    .validation-danger {
      color: red;
      margin-top: 15px;
    }
    .validation-success {
      margin-top: 15px;
    }
    .default-btn {
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 5px 5px 0;
      height: 50px;

      i {
        top: 12px;
      }
    }
  }
}
.subscribe-image {
  text-align: center;
}
.shape13 {
  position: absolute;
  left: 22%;
  bottom: 22%;
  z-index: -2;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.shape14 {
  position: absolute;
  right: 11%;
  bottom: 30%;
  z-index: -1;

  img {
    animation: movebounce 5s linear infinite;
  }
}
.shape15 {
  position: absolute;
  right: 15%;
  bottom: 15%;
  z-index: -2;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.subscribe-area-two {
  padding-top: 30px;
  background: {
    color: #eee8df;
    image: url(../../images/subscribe-bg.jpg);
    repeat: no-repeat;
    position: center center;
    size: cover;
  }
  .subscribe-content {
    text-align: left;
    max-width: 490px;
    margin: {
      left: 0;
      right: 0;
    }
    .newsletter-form {
      .input-newsletter {
        background-color: $white-color;
      }
    }
  }
}
.subscribe-area {
  &.bg-eee8df {
    .subscribe-content {
      .newsletter-form {
        .input-newsletter {
          background-color: $white-color;
        }
      }
    }
  }
}
.subscribe-content {
  &.text-left {
    max-width: 100%;
    margin: {
      left: 0;
      right: 0;
    }
    h2 {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
      }
    }
    .newsletter-form {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  cursor: zoom-in;

  a {
    display: block;
    border-radius: 5px;

    img {
      transition: $transition;
    }
  }
  &:hover {
    a {
      img {
        transform: scale(1.1);
        filter: blur(2px);
      }
    }
  }
}

/*================================================
Pricing Area CSS
=================================================*/
.single-pricing-box {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: 5px;
  background-color: $white-color;
  padding: {
    top: 30px;
    bottom: 26px;
  }
  .pricing-header {
    img {
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 0;
      background-color: #f9f9f9;
      text-transform: uppercase;
      font: {
        size: 24px;
        weight: 800;
      }
      padding: {
        top: 14px;
        bottom: 12px;
        left: 10px;
        right: 10px;
      }
    }
  }
  .pricing-features {
    margin-bottom: 20px;
    padding: {
      top: 25px;
      left: 10px;
      right: 10px;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-bottom: 14px;
        color: #4b5280;
        font: {
          size: 16.5px;
          weight: 600;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .price {
    font: {
      size: 42px;
      weight: 800;
    }
    span {
      display: block;
      color: #4b5280;
      margin-top: -5px;
      font: {
        size: 18px;
        weight: 600;
      }
    }
  }
  .default-btn {
    margin-top: 25px;
    background-color: $main-color2;
    padding: {
      left: 30px;
      right: 30px;
    }
    span {
      background-color: $main-color;
    }
  }
  .pricing-shape1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .pricing-shape2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
  position: relative;
  margin-bottom: 30px;

  .products-image {
    text-align: center;
    position: relative;
    overflow: hidden;

    a {
      position: relative;
      display: block;

      img {
        transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    .products-button {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;

      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          margin-bottom: 10px;
          opacity: 0;
          visibility: hidden;
          transform: translateX(30px);
          transition: all 0.3s ease-in-out 0s;

          a {
            display: inline-block;
            font-size: 20px;
            line-height: 1;
            color: $black-color;
            position: relative;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
            background-color: $white-color;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 45px;
            border-radius: 50%;

            .tooltip-label {
              right: 110%;
              top: 5px;
              transform: translateX(0);
              position: absolute;
              padding: 0 10px;
              visibility: hidden;
              white-space: nowrap;
              opacity: 0;
              color: transparent;
              background-color: transparent;
              transition: all 0.2s ease-in-out;
              background-color: $main-color;
              color: $white-color;
              line-height: 28px;
              font: {
                size: 12px;
                weight: 800;
              }
              &::before {
                content: "";
                background-color: $main-color;
                top: 50%;
                position: absolute;
                transition: all 0.2s ease-in-out;
                right: -1px;
                width: 10px;
                height: 10px;
                transform: rotate(45deg) translateY(-50%);
              }
            }
            &:hover {
              background-color: $main-color;
              color: $white-color;

              .tooltip-label {
                visibility: visible;
                opacity: 1;
                right: 135%;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(1) {
            transition-delay: 0.1s;
          }
          &:nth-child(2) {
            transition-delay: 0.2s;
          }
          &:nth-child(3) {
            transition-delay: 0.3s;
          }
        }
      }
    }
    .sale-tag {
      position: absolute;
      left: 20px;
      top: 20px;
      background-color: red;
      color: $white-color;
      width: 55px;
      height: 55px;
      line-height: 55px;
      text-align: center;
      border-radius: 50%;
      z-index: 2;
      text-transform: uppercase;
      font: {
        weight: 700;
        size: 14px;
      }
    }
    .new-tag {
      position: absolute;
      left: 20px;
      top: 20px;
      background-color: green;
      color: $white-color;
      text-align: center;
      z-index: 2;
      padding: 1px 7px;
      text-transform: uppercase;
      font: {
        weight: 700;
        size: 14px;
      }
    }
  }
  .products-content {
    margin-top: 20px;
    position: relative;

    h3 {
      margin-bottom: 0;
      font: {
        size: 20px;
        weight: 800;
      }
      a {
        display: inline-block;
      }
    }
    .price {
      margin-top: 13px;
      transition: $transition;
      font: {
        size: $font-size;
        weight: 800;
      }
      .old-price {
        text-decoration: line-through;
        color: #999999;
        font-weight: 700;
        margin-right: 10px;
      }
    }
    .star-rating {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #f49f0b;

      i {
        display: inline-block;
        margin-left: 2px;
      }
    }
    .add-to-cart {
      position: absolute;
      left: 0;
      bottom: 0;
      text-transform: uppercase;
      transition: $transition;
      display: inline-block;
      color: $optional-color;
      opacity: 0;
      visibility: hidden;
      transform: translateX(20px);
      font: {
        size: 14px;
        weight: 800;
      }
      &:hover {
        color: $main-color;
      }
    }
  }
  &:hover {
    .products-content {
      .price {
        opacity: 0;
        visibility: hidden;
      }
      .add-to-cart {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
    .products-image {
      a {
        img {
          transform: scale3d(1.1, 1.1, 1.1) translateZ(0);
        }
      }
      .products-button {
        ul {
          li {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
  border-radius: 5px;

  a {
    display: block;
    border-radius: 5px;

    img {
      border-radius: 5px;
    }
  }
}
.products-details-desc {
  padding-left: 30px;

  h3 {
    margin-bottom: 15px;
    font: {
      size: 24px;
      weight: 800;
    }
  }
  .price {
    margin-bottom: 15px;
    color: $main-color;
    font: {
      size: 15px;
      weight: 600;
    }
    .old-price {
      text-decoration: line-through;
      color: #828893;
      font-weight: normal;
      margin-left: 10px;
    }
  }
  .products-review {
    margin-bottom: 18px;

    .rating {
      display: inline-block;
      padding-right: 5px;
      font-size: 18px;

      i {
        color: #ffba0a;
        display: inline-block;
        margin-right: 2px;
      }
    }
    .rating-count {
      display: inline-block;
      color: $black-color;
      border-bottom: 1px solid $black-color;
      line-height: initial;
      position: relative;
      top: -3px;
      font-weight: 600;

      &:hover {
        color: $main-color;
        border-color: $main-color;
      }
    }
  }
  .products-meta {
    margin-top: 20px;

    span {
      display: block;
      color: $black-color;
      margin-bottom: 10px;
      font: {
        size: $font-size;
        weight: 700;
      }
      span {
        display: inline-block;
        margin-bottom: 0;
        font-weight: 600;

        &.sku {
          color: $main-color;
        }
        &.in-stock {
          color: $optional-color;
        }
      }
      a {
        display: inline-block;
        color: $optional-color;
        font-weight: 600;

        &:hover,
        &:focus {
          color: $main-color;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .products-add-to-cart {
    margin-top: 25px;

    .input-counter {
      max-width: 130px;
      min-width: 130px;
      margin-right: 10px;
      text-align: center;
      display: inline-block;
      position: relative;

      span {
        position: absolute;
        top: 0;
        background-color: transparent;
        cursor: pointer;
        color: #d0d0d0;
        width: 50px;
        height: 100%;
        line-height: 55px;
        transition: $transition;
        font-size: 25px;

        &.minus-btn {
          left: 0;
        }
        &.plus-btn {
          right: 0;
        }
        &:hover {
          color: $main-color;
        }
      }
      input {
        height: 50px;
        color: $black-color;
        outline: 0;
        display: block;
        border: none;
        background-color: #f8f8f8;
        text-align: center;
        width: 100%;
        font: {
          size: 17px;
          weight: 600;
        }
        &::placeholder {
          color: $black-color;
        }
      }
    }
    .default-btn {
      top: -2px;
      padding: {
        top: 12.5px;
        bottom: 12.5px;
      }
    }
  }
  .products-share {
    margin-top: 30px;

    .social {
      padding-left: 0;
      list-style-type: none;
      margin-bottom: 0;

      li {
        display: inline-block;

        span {
          display: inline-block;
          margin-right: 3px;
          font-weight: 700;
          position: relative;
          top: -2px;
        }
        a {
          display: block;
          width: 32px;
          height: 32px;
          line-height: 34px;
          border-radius: 50%;
          background-color: $main-color;
          color: $white-color;
          border: 1px solid $main-color;
          text-align: center;
          font-size: 17px;
          margin-left: 5px;

          &:hover,
          &:focus {
            color: $main-color;
            background-color: transparent;
          }
          &.facebook {
            background-color: #3b5998;
            border-color: #3b5998;
            color: $white-color;

            &:hover,
            &:focus {
              color: #3b5998;
              background-color: transparent;
            }
          }
          &.twitter {
            background-color: #1da1f2;
            border-color: #1da1f2;
            color: $white-color;

            &:hover,
            &:focus {
              color: #1da1f2;
              background-color: transparent;
            }
          }
          &.linkedin {
            background-color: #007bb5;
            border-color: #007bb5;
            color: $white-color;

            &:hover,
            &:focus {
              color: #007bb5;
              background-color: transparent;
            }
          }
          &.instagram {
            background-color: #c13584;
            border-color: #c13584;
            color: $white-color;

            &:hover,
            &:focus {
              color: #c13584;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
.products-details-tabs {
  margin-top: 50px;

  .react-tabs__tab-list {
    text-align: center;
    padding-left: 0;
    margin-bottom: 40px;
    list-style-type: none;
    display: block;

    .react-tabs__tab {
      display: inline-block;
      margin: {
        left: 15px;
        right: 15px;
      }
      color: #737373;
      border: none;
      border-bottom: 1px solid #aaa;
      padding: 0;
      background-color: transparent;
      position: relative;
      padding-bottom: 8px;
      font: {
        size: 20px;
        weight: 800;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 3px;
        background-color: $main-color;
        transition: $transition;
        bottom: -2px;
      }
      &:hover,
      &.react-tabs__tab--selected {
        color: $black-color;

        &::before {
          width: 100%;
        }
      }
    }
  }

  .react-tabs__tab-panel {
    max-width: 720px;
    margin: {
      left: auto;
      right: auto;
    }
    p {
      line-height: 1.8;
    }
    ul {
      padding-left: 0;
      list-style-type: none;
      margin: {
        top: 20px;
        bottom: 0;
      }
      li {
        margin-bottom: 12px;
        position: relative;
        color: $optional-color;
        padding-left: 15px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: $black-color;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .products-reviews {
      h3 {
        margin-bottom: 0;
        display: inline-block;
        margin-right: 15px;
        font: {
          size: 24px;
          weight: 800;
        }
      }
      .rating {
        display: inline-block;

        span {
          font-size: 17px;
          color: #cecfd2;
          margin-right: 2px;

          &.checked {
            color: orange;
          }
        }
      }
      .rating-count {
        margin: {
          top: 10px;
          bottom: 20px;
        }
        span {
          display: block;
          font-size: $font-size;
          color: $optional-color;
        }
      }
      .row {
        overflow: hidden;
        margin: {
          left: 0;
          right: 0;
        }
      }
      .side {
        float: left;
        width: 9%;
        margin-top: 10px;

        div {
          font: {
            size: $font-size;
            weight: 700;
          }
        }
      }
      .middle {
        margin-top: 14px;
        float: left;
        width: 82%;
      }
      .right {
        text-align: right;
      }
      .bar-container {
        width: 100%;
        background-color: #f1f1f1;
        text-align: center;
        color: $white-color;
        border-radius: 5px;
      }
      .bar-5 {
        width: 100%;
        height: 18px;
        background-color: #4caf50;
        border-radius: 5px;
      }
      .bar-4 {
        width: 75%;
        height: 18px;
        background-color: #2196f3;
        border-radius: 5px;
        border-radius: 5px;
      }
      .bar-3 {
        width: 50%;
        height: 18px;
        background-color: #00bcd4;
        border-radius: 5px;
      }
      .bar-2 {
        width: 25%;
        height: 18px;
        background-color: #ff9800;
        border-radius: 5px;
      }
      .bar-1 {
        width: 0;
        height: 18px;
        background-color: #f44336;
        border-radius: 5px;
      }
    }
    .products-review-comments {
      margin-top: 40px;

      h3 {
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 10px;
        margin: {
          top: 0;
          bottom: 10px;
        }
        font: {
          size: 24px;
          weight: 800;
        }
      }
      .user-review {
        border-bottom: 1px solid #f3f3f3;
        padding: 20px 0 20px 110px;
        position: relative;

        img {
          position: absolute;
          left: 0;
          top: 20px;
          width: 90px;
          height: 90px;
          border-radius: 5px;
        }
        .sub-comment {
          margin-bottom: 8px;
          font: {
            weight: 700;
          }
        }
        .review-rating {
          display: block;
          margin-bottom: 8px;

          .review-stars {
            display: inline-block;

            i {
              color: #cecfd2;
              font-size: 18px;
              display: inline-block;
              margin-right: 2px;

              &.checked {
                color: orange;
              }
            }
          }
          span {
            color: $black-color;
            position: relative;
            top: -2px;
            font-weight: 700;
            margin-left: 5px;
          }
        }
      }
    }
    .review-form-wrapper {
      margin-top: 30px;

      h3 {
        margin-bottom: 10px;
        font: {
          size: 24px;
          weight: 800;
        }
      }
      .comment-notes {
        span {
          color: red;
        }
      }
      form {
        margin-top: 20px;
        text-align: center;

        .form-group {
          margin-bottom: 25px;
          text-align: left;
        }
        .rating {
          text-align: left;
          overflow: hidden;
          max-width: 115px;
          margin: {
            top: -5px;
            bottom: 20px;
          }
          label {
            float: right;
            position: relative;
            width: 23px;
            height: 23px;
            cursor: pointer;

            &:not(:first-of-type) {
              padding-right: 5px;
            }
            &:before {
              content: "\2605";
              transition: $transition;
              font-size: 27px;
              color: #cccccc;
              line-height: 1;
            }
          }
          input {
            display: none;
          }
          input:checked ~ label:before,
          &:not(:checked) > label:hover:before,
          &:not(:checked) > label:hover ~ label:before {
            color: #f6b500;
          }
        }
        .comment-form-cookies-consent {
          text-align: left;
          margin-bottom: 0;

          [type="checkbox"]:checked,
          [type="checkbox"]:not(:checked) {
            display: none;
          }
          [type="checkbox"]:checked + label,
          [type="checkbox"]:not(:checked) + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            margin-bottom: 0;
            color: $optional-color;
            font: {
              weight: 600;
            }
          }
          [type="checkbox"]:checked + label:before,
          [type="checkbox"]:not(:checked) + label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 19px;
            height: 19px;
            transition: all 0.2s ease;
            border: 1px solid #f5f5f5;
            border-radius: 3px;
            background: #f5f5f5;
          }
          [type="checkbox"]:checked + label:after,
          [type="checkbox"]:not(:checked) + label:after {
            content: "";
            width: 8px;
            height: 8px;
            background: $main-color;
            position: absolute;
            top: 5.5px;
            left: 6px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
          [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
          [type="checkbox"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          [type="checkbox"]:hover + label:before {
            border-color: $main-color;
          }
          [type="checkbox"]:checked + label:before {
            border-color: $main-color;
          }
        }
        button {
          margin-top: 22px;
          border: none;
          display: inline-block;
          text-align: center;
          overflow: hidden;
          color: $white-color;
          background-color: $main-color;
          transition: $transition;
          border-radius: 5px;
          padding: 13px 60px;
          font: {
            weight: 700;
            size: $font-size;
          }
          &:hover {
            background-color: $black-color;
            color: $white-color;
          }
        }
      }
    }
  }
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
  table {
    margin-bottom: 0;

    thead {
      tr {
        th {
          border-bottom-width: 0px;
          vertical-align: middle;
          padding: 0 0 15px;
          text-transform: uppercase;
          border: none;
          white-space: nowrap;
          font: {
            size: $font-size;
            weight: 800;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          color: $optional-color;
          white-space: nowrap;
          font: {
            weight: 700;
            size: 15px;
          }
          padding: {
            left: 0;
            right: 0;
          }
          border: {
            color: #eaedff;
            left: none;
            right: none;
          }
          &.product-thumbnail {
            a {
              display: block;

              img {
                width: 80px;
              }
            }
          }
          &.product-name {
            a {
              display: inline-block;
            }
          }
          &.product-subtotal {
            .remove {
              color: red;
              float: right;
              position: relative;
              top: -1px;
              font-size: 18px;
            }
          }
          &.product-quantity {
            .input-counter {
              max-width: 130px;
              min-width: 130px;
              text-align: center;
              display: inline-block;
              position: relative;

              span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 40px;
                height: 100%;
                line-height: 50px;
                transition: $transition;
                font-size: 22px;

                &.minus-btn {
                  left: 0;
                }
                &.plus-btn {
                  right: 0;
                }
                &:hover {
                  color: $main-color;
                }
              }
              input {
                height: 45px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                  size: 17px;
                  weight: 600;
                }
                &::placeholder {
                  color: $black-color;
                }
              }
            }
          }
          &.product-subtotal {
            overflow: hidden;
          }
        }
      }
    }
  }
}
.cart-buttons {
  margin-top: 30px;

  .shopping-coupon-code {
    position: relative;
    max-width: 530px;

    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 50px;
      background: $black-color;
      color: $white-color;
      border: none;
      padding: 0 25px;
      line-height: 48px;
      outline: 0;
      transition: $transition;
      font: {
        size: $font-size;
        weight: 700;
      }
      &:hover {
        background-color: $main-color;
      }
    }
  }
}
.cart-totals {
  background: $white-color;
  padding: 40px;
  max-width: 500px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: {
    top: 60px;
    left: auto;
    right: auto;
  }
  h3 {
    margin-bottom: 25px;
    font: {
      size: 24px;
      weight: 800;
    }
  }
  ul {
    padding-left: 0;
    margin: 0 0 25px;
    list-style-type: none;

    li {
      border: 1px solid #eaedff;
      padding: 10px 15px;
      color: $black-color;
      overflow: hidden;
      font: {
        weight: 700;
      }
      &:first-child {
        border: {
          bottom: none;
        }
      }
      &:last-child {
        font-size: 20px;
        border: {
          top: none;
        }
        span {
          color: $black-color;
          font-weight: 600;
        }
      }
      span {
        float: right;
        color: $optional-color;
        font: {
          weight: normal;
        }
      }
    }
  }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 15px 20px;
  border-top: 3px solid $main-color;
  position: relative;
  margin-bottom: 40px;

  i {
    color: $main-color;
    margin-right: 2px;
    font-size: 20px;
    position: relative;
    top: 3px;
  }
  span {
    display: inline-block;
    font-weight: 700;
    color: $main-color;

    a {
      display: inline-block;
    }
  }
}
.billing-details {
  .title {
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    font: {
      size: 24px;
      weight: 800;
    }
    &::before {
      content: "";
      position: absolute;
      background: $main-color;
      bottom: -1px;
      left: 0;
      width: 50px;
      height: 1px;
    }
  }
  .form-group {
    margin-bottom: 25px;

    label {
      display: block;
      margin-bottom: 8px;
      font: {
        weight: 700;
        size: 15px;
      }
      .required {
        color: red;
      }
    }
    .nice-select {
      background: #f5f5f5;
      border-color: #f5f5f5;
      color: $black-color;
      transition: $transition;
      padding: 0 0 0 12px;
      height: 50px;
      line-height: 51px;
      font: {
        size: 15px;
        weight: 700;
      }
      .list {
        background-color: $white-color;
        border-radius: 5px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        list-style-type: none;
        border: none;
        width: 100%;
        margin: {
          bottom: 0;
          top: 0;
        }
        padding: {
          left: 0;
          top: 10px;
          bottom: 10px;
        }
        .option {
          line-height: 38px;
          min-height: 38px;
          color: $black-color;
          position: relative;
          transition: $transition;
          padding: {
            left: 32px;
            right: 25px;
          }
          font: {
            size: $font-size;
            weight: 700;
          }
          &:hover {
            background-color: $main-color !important;
            color: $white-color;

            &::before {
              color: $white-color;
            }
          }
          &.focus,
          &.selected.focus {
            background-color: transparent !important;
            color: $black-color;
          }
          &::before {
            content: "\f11f";
            position: absolute;
            left: 10px;
            top: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            color: $main-color;
            font: {
              family: Flaticon;
              size: 11px;
            }
          }
          &.selected {
            &::before {
              opacity: 1;
              visibility: visible;
            }
            &:hover {
              background-color: $main-color !important;
              color: $white-color;

              &::before {
                color: $white-color;
              }
            }
          }
        }
      }
      &:after {
        border-color: $black-color;
        height: 8px;
        width: 8px;
        margin-top: -5px;
      }
      &:hover {
        border-color: $main-color;
        background-color: transparent;
      }
    }
  }
  .form-check {
    margin-bottom: 20px;
    padding-left: 25px;

    .form-check-input {
      margin-left: -25px;
    }
    .form-check-label {
      color: $black-color;
      font-weight: 600;
    }
    label {
      position: relative;
      left: -3px;
      top: 0;
      font: {
        weight: 500;
      }
    }
  }
  .col-lg-12 {
    &:last-child {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
}
.order-details {
  .title {
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    font: {
      size: 24px;
      weight: 800;
    }
    &::before {
      content: "";
      position: absolute;
      background: $main-color;
      bottom: -1px;
      left: 0;
      width: 50px;
      height: 1px;
    }
  }
  .order-table {
    table {
      margin-bottom: 0;
      background-color: #fff;

      thead {
        tr {
          th {
            border-bottom-width: 0;
            vertical-align: middle;
            border-color: #eaedff;
            padding: 12px 20px 10px;
            white-space: nowrap;
            font: {
              weight: 800;
              size: $font-size;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            color: $optional-color;
            white-space: nowrap;
            border-color: #eaedff;
            font: {
              size: 15px;
              weight: 700;
            }
            padding: {
              left: 20px;
              right: 20px;
              top: 15px;
              bottom: 13px;
            }
            &.product-name {
              a {
                display: inline-block;
              }
            }
            &.order-subtotal,
            &.order-shipping,
            &.total-price {
              span {
                color: $black-color;
                font: {
                  weight: 800;
                  size: 16px;
                }
              }
            }
            &.shipping-price,
            &.order-subtotal-price,
            &.product-subtotal {
              color: $black-color;
              font: {
                weight: 800;
                size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .payment-box {
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    margin-top: 30px;
    padding: 30px;

    .payment-method {
      p {
        [type="radio"] {
          &:checked,
          &:not(:checked) {
            display: none;
          }
        }
        [type="radio"] {
          &:checked,
          &:not(:checked) {
            + label {
              padding-left: 27px;
              cursor: pointer;
              display: block;
              color: $black-color;
              position: relative;
              margin-bottom: 8px;
              font: {
                weight: 800;
              }
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 3px;
                width: 18px;
                height: 18px;
                border: 1px solid #dddddd;
                border-radius: 50%;
                background: $white-color;
              }
              &::after {
                content: "";
                width: 12px;
                height: 12px;
                background: $main-color;
                position: absolute;
                top: 6px;
                left: 3px;
                border-radius: 50%;
                transition: $transition;
              }
            }
          }
        }
        [type="radio"] {
          &:not(:checked) {
            + label {
              &::after {
                opacity: 0;
                visibility: hidden;
                transform: scale(0);
              }
            }
          }
        }
        [type="radio"] {
          &:checked {
            + label {
              &::after {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
              }
            }
          }
        }
      }
    }
    .default-btn {
      margin-top: 15px;
      box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
      border-radius: 3px;
    }
  }
}

/*================================================
Privacy Policy Area CSS
=================================================*/
.privacy-policy-content {
  img {
    border-radius: 5px;
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }
  h3 {
    margin: {
      bottom: 15px;
      top: 25px;
    }
    font: {
      size: 22px;
      weight: 800;
    }
  }
  .blockquote,
  // blockquote {
  //   margin-bottom: 30px;
  //   background-color: #e6f2f5;
  //   text-align: left !important;
  //   padding: 25px !important;

  //   p {
  //     margin-bottom: 0;
  //     line-height: 1.8;
  //     font: {
  //       size: $font-size !important;
  //       weight: normal;
  //     }
  //   }
  //   &::after {
  //     display: none;
  //   }
  //   &::before {
  //     display: none;
  //   }
  // }
  ol,
  ul {
    margin-top: 20px;

    li {
      margin-bottom: 10px;
      color: $optional-color;
      font-size: $font-size;
      line-height: 1.8;
    }
  }
}

/*================================================
Terms of Service Area CSS
=================================================*/
.terms-of-service-content {
  img {
    border-radius: 5px;
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }
  h3 {
    margin: {
      bottom: 15px;
      top: 25px;
    }
    font: {
      size: 22px;
      weight: 800;
    }
  }
  // .blockquote,
  // blockquote {
  //   margin-bottom: 30px;
  //   background-color: #e6f2f5;
  //   text-align: left !important;
  //   padding: 25px !important;

  //   p {
  //     margin-bottom: 0;
  //     line-height: 1.8;
  //     font: {
  //       size: $font-size !important;
  //       weight: normal;
  //     }
  //   }
  //   &::after {
  //     display: none;
  //   }
  //   &::before {
  //     display: none;
  //   }
  // }
  ol,
  ul {
    margin-top: 20px;

    li {
      margin-bottom: 10px;
      color: $optional-color;
      font-size: $font-size;
      line-height: 1.8;
    }
  }
}

/*================================================
Purchase Guide Area CSS
=================================================*/
.purchase-guide-content {
  img {
    border-radius: 5px;
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }
  h3 {
    margin: {
      bottom: 15px;
      top: 25px;
    }
    font: {
      size: 22px;
      weight: 800;
    }
  }
  // .blockquote,
  // blockquote {
  //   margin-bottom: 30px;
  //   background-color: #e6f2f5;
  //   text-align: left !important;
  //   padding: 25px !important;

  //   p {
  //     margin-bottom: 0;
  //     line-height: 1.8;
  //     font: {
  //       size: $font-size !important;
  //       weight: normal;
  //     }
  //   }
  //   &::after {
  //     display: none;
  //   }
  //   &::before {
  //     display: none;
  //   }
  // }
  ol,
  ul {
    margin-top: 20px;

    li {
      margin-bottom: 10px;
      color: $optional-color;
      font-size: $font-size;
      line-height: 1.8;
    }
  }
}

/*================================================
Membership Levels Area CSS
=================================================*/
.membership-levels-table {
  .table {
    margin-bottom: 0;

    thead {
      th {
        vertical-align: middle;
        background-color: $main-color;
        white-space: nowrap;
        padding: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 0.9);
        border: {
          left: 1px solid #e98f8f;
          bottom: none;
          right: 1px solid #e98f8f;
        }
        h2 {
          color: $white-color;
          margin-bottom: 0;
          font: {
            size: 35px;
            weight: 800;
          }
        }
        h3 {
          color: $white-color;
          text-transform: uppercase;
          margin: {
            top: 8px;
            bottom: 5px;
          }
          font: {
            size: $font-size;
            weight: 700;
          }
        }
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 20px;
          white-space: nowrap;
          vertical-align: middle;
          border: none;
          text-align: center;
          font: {
            size: $font-size;
            weight: 600;
          }
          &:first-child {
            text-align: left;
          }
          &.item-check {
            i {
              width: 20px;
              height: 20px;
              line-height: 20px;
              border-radius: 100%;
              background-color: #0eb582;
              color: $white-color;
              display: block;
              margin: 0 auto;
            }
          }
          &.item-none {
            i {
              width: 20px;
              height: 20px;
              line-height: 20px;
              border-radius: 100%;
              background-color: #d85554;
              color: $white-color;
              display: block;
              margin: 0 auto;
            }
          }
          .select-btn {
            display: inline-block;
            color: $black-color;
            background-color: #efefef;
            padding: 13px 30px;
            border-radius: 5px;
            margin-top: 10px;
            font: {
              size: $font-size;
              weight: 700;
            }
            &:hover {
              color: $white-color;
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  background-color: #111111;
  height: 100vh;
}
.error-content {
  text-align: center;

  h3 {
    color: $white-color;
    margin: {
      bottom: 20px;
      top: 45px;
    }
    font: {
      size: 40px;
      weight: 800;
    }
  }
  p {
    color: rgba(255, 255, 255, 0.7);
    max-width: 550px;
    margin: {
      left: auto;
      right: auto;
      bottom: 0;
    }
  }
  .btn-box {
    margin-top: 30px;

    .default-btn {
      margin: {
        left: 7px;
        right: 7px;
      }
      span {
        background-color: $white-color;
      }
      &:hover {
        color: $black-color;
      }
    }
  }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion-tab {
  .react-tabs__tab-list {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 60px;
    text-align: center;
    border: none;

    .react-tabs__tab {
      margin: {
        left: 10px;
        right: 10px;
      }
      display: inline-block;
      color: $black-color;
      text-decoration: none;
      border-radius: 5px;
      padding: 20px;
      box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
      font: {
        size: $font-size;
        weight: 800;
      }
      i {
        font: {
          size: 30px;
          weight: 400;
        }
      }
      span {
        display: block;
        margin-top: 8px;
      }
      &:hover {
        background-color: $main-color;
        color: $white-color;
      }

      &.react-tabs__tab--selected {
        background-color: $main-color;
        color: $white-color;
      }
    }
  }
}
.faq-accordion {
  .accordion {
    list-style-type: none;
    padding-left: 0;
    border: none;
    margin: {
      bottom: 0;
    }
    .accordion__item {
      border-radius: 5px;
      display: block;
      box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
      background: $white-color;
      margin-bottom: 15px;
      border: none;
      border-radius: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .accordion__button {
      padding: 20px 25px;
      background-color: #fff;
      color: $black-color;
      text-decoration: none;
      position: relative;
      display: block;
      border-radius: 5px;
      font: {
        size: 17px;
        weight: 800;
      }
      i {
        position: absolute;
        left: 25px;
        top: 18px;
        font-size: 25px;
        transition: $transition;
      }
      &.active {
        i {
          transform: rotate(180deg);
        }
      }
    }
    .accordion__panel {
      position: relative;
      margin-top: -5px;
      padding-bottom: 20px;
      padding-right: 30px;
      padding-left: 48px;
      padding-top: 0;
      p {
        line-height: 1.8;
      }
    }
  }
}
.tab {
  .tabs-item {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
  background: {
    image: url(../../images/coming-soon-bg.jpg);
    position: top center;
    size: cover;
    repeat: no-repeat;
  }
}
.coming-soon-content {
  max-width: 700px;
  background: $white-color;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 40px 60px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin: {
    left: auto;
    right: auto;
  }
  .logo {
    display: inline-block;
  }
  h2 {
    font: {
      size: 40px;
      weight: 800;
    }
    margin: {
      top: 30px;
      bottom: 0;
    }
  }
  #timer {
    margin-top: 40px;

    div {
      background-color: $black-color;
      color: $white-color;
      width: 100px;
      height: 105px;
      border-radius: 5px;
      font: {
        size: 40px;
        weight: 800;
      }
      margin: {
        left: 10px;
        right: 10px;
      }
      span {
        display: block;
        margin-top: -2px;
        font: {
          size: 17px;
          weight: 600;
        }
      }
    }
  }
  form {
    position: relative;
    max-width: 500px;
    margin: {
      left: auto;
      right: auto;
      top: 50px;
    }
    .form-group {
      margin-bottom: 25px;
      width: 100%;
      position: relative;

      .label-title {
        margin-bottom: 0;
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        color: $black-color;

        i {
          position: absolute;
          left: 0;
          transition: $transition;
          top: 9px;
          font: {
            size: 22px;
          }
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 2px;
          transition: $transition;
          background: $main-color;
        }
      }
      .input-newsletter {
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #eeeeee;
        padding: 0 0 0 32px;
        color: $black-color;
        height: 45px;
        display: block;
        width: 100%;
        transition: $transition;
        font: {
          size: 16px;
          weight: 400;
        }
        &::placeholder {
          color: #a1a1a1;
          transition: $transition;
        }
        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
    }
    .default-btn {
      border-radius: 0;
    }
    .validation-danger {
      margin-top: 15px;
      color: red;
    }
    .validation-success {
      margin-top: 15px;
    }
    p {
      margin: {
        bottom: 0;
        top: 20px;
      }
    }
  }
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
  padding: 40px 50px;
  border-radius: 5px;
  background-color: $white-color;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.1);
  margin-right: 30px;

  h2 {
    margin-bottom: 25px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  form {
    .form-group {
      margin-bottom: 25px;

      label {
        display: block;
        margin-bottom: 8px;
        color: $black-color;
        font-weight: 700;
      }
    }
    .remember-me-wrap {
      margin-bottom: 0;

      [type="checkbox"]:checked,
      [type="checkbox"]:not(:checked) {
        display: none;
      }
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        margin-bottom: 0;
        color: $optional-color;
        font: {
          weight: 600;
        }
      }
      [type="checkbox"]:checked + label:before,
      [type="checkbox"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 19px;
        height: 19px;
        transition: all 0.2s ease;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        background: #f5f5f5;
      }
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after {
        content: "";
        width: 8px;
        height: 8px;
        background: $main-color;
        position: absolute;
        top: 5.5px;
        left: 6px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      [type="checkbox"]:hover + label:before {
        border-color: $main-color;
      }
      [type="checkbox"]:checked + label:before {
        border-color: $main-color;
      }
    }
    .lost-your-password-wrap {
      text-align: right;

      a {
        display: inline-block;
        position: relative;
        font-weight: 600;
        line-height: 1.3;

        &::before {
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          transition: $transition;
          background-color: #eeeeee;
        }
        &::after {
          width: 0;
          height: 1px;
          position: absolute;
          left: 0;
          transition: $transition;
          bottom: 0;
          content: "";
          background-color: $main-color;
        }
        &:hover {
          &::before {
            width: 0;
          }
          &::after {
            width: 100%;
          }
        }
      }
    }
    button {
      margin-top: 22px;
      border: none;
      display: block;
      text-align: center;
      overflow: hidden;
      color: $white-color;
      background-color: $main-color;
      transition: $transition;
      width: 100%;
      border-radius: 5px;
      padding: 14.5px 30px;
      font: {
        weight: 700;
        size: $font-size;
      }
      &:hover {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
}
.register-form {
  padding: {
    top: 30px;
    left: 30px;
  }
  h2 {
    margin-bottom: 25px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  form {
    .form-group {
      margin-bottom: 25px;

      label {
        display: block;
        margin-bottom: 8px;
        color: $black-color;
        font-weight: 700;
      }
    }
    .description {
      font-style: italic;
      font-size: 13.5px;
      margin: {
        top: -10px;
        bottom: 0;
      }
    }
    button {
      margin-top: 22px;
      border: none;
      display: block;
      text-align: center;
      overflow: hidden;
      color: $white-color;
      background-color: $main-color;
      transition: $transition;
      width: 100%;
      border-radius: 5px;
      padding: 14.5px 30px;
      font: {
        weight: 700;
        size: $font-size;
      }
      &:hover {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info {
  padding-right: 15px;

  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin: {
      bottom: 12px;
    }
    font: {
      size: 36px;
      weight: 800;
    }
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    margin: {
      top: 35px;
      bottom: 0;
    }
    li {
      position: relative;
      margin-bottom: 35px;
      padding: {
        left: 100px;
      }
      .icon {
        width: 75px;
        height: 85px;
        background-color: #f8f9f8;
        border-radius: 3px;
        position: absolute;
        text-align: center;
        left: 0;
        font-size: 40px;
        color: $main-color;
        transition: $transition;
        top: 0;

        i {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      h3 {
        margin-bottom: 8px;
        font: {
          size: 24px;
          weight: 800;
        }
      }
      p {
        font-weight: 600;
        max-width: 300px;
        margin: {
          bottom: 0;
          top: 2px;
        }
      }
      &:hover {
        .icon {
          background-color: $main-color;
          color: $white-color;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.contact-form {
  overflow: hidden;
  padding: 35px;
  margin-left: 15px;
  border-radius: 5px;
  background: {
    color: $main-color;
    image: url(../../images/register-shape.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  h2 {
    margin-bottom: 0;
    color: $white-color;
    font: {
      size: 33px;
      weight: 800;
    }
  }
  p {
    color: $white-color;
    margin: {
      top: 10px;
      bottom: 0;
    }
  }
  form {
    margin-top: 25px;

    input,
    textarea {
      display: block;
      width: 100%;
      border: none;
      color: $white-color;
      background-color: #c22b34;
      height: 50px;
      padding-left: 15px;
      border-radius: 5px;
      font: {
        size: $font-size;
        weight: 600;
      }
      &::placeholder {
        color: #cdcdcd;
        transition: $transition;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
    textarea {
      height: auto !important;
      padding-top: 15px;
    }
    .default-btn {
      background-color: $black-color;
      margin-top: 5px;
      padding: 12px 30px;

      span {
        background-color: $white-color;
      }
      &:hover {
        color: $main-color;
      }
    }
    .invalid-feedback {
      color: $white-color;
    }
  }
}
#map {
  iframe {
    height: 500px;
    margin-bottom: -7px;
    width: 100%;
    border: none;
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: -100px;
  right: 15px;
  color: $black-color;
  background-color: $white-color;
  z-index: 4;
  width: 45px;
  text-align: center;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  font-size: 40px;
  transition: 0.9s;
  overflow: hidden;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

  &.active {
    opacity: 1;
    visibility: visible;
    bottom: 15px;
  }
  i {
    position: absolute;
    right: 0;
    left: 0;
    top: 70%;
    transform: translateY(-70%);
    text-align: center;
    margin: {
      left: auto;
      right: auto;
    }
  }
  &:hover {
    background-color: $main-color;
    color: $white-color;
    transition: $transition;
    transform: translateY(-10px);
  }
}
.modal-video-close-btn {
  right: 0;
}
.modal-video-body {
  padding: 30px;
}

// Teacher Dashboard
.td-sidebar {
  background-color: #f8f9f8;
  border-top: 3px solid $main-color;
  border-radius: 3px 3px 0px 0px;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      border-bottom: 1px solid #f1f1f1;
      margin-top: -1px;
      &:last-child {
        border-bottom: none;
      }
      a {
        padding: 15px 20px;
        display: block;

        &.active,
        &:hover {
          background-color: $main-color;
          color: $white-color;
        }
      }
    }
  }
}
.td-text-area {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin-bottom: 15px;
  }
  ul,
  ol {
    padding-left: 17px;
    li {
      margin-bottom: 15px;
      line-height: 1.6;
    }
  }
}

.border-box {
  border: 1px solid #f5f5f5;
  padding: 30px;
  position: relative;
}

button {
  .spinner-border {
    width: 15px;
    height: 15px;
    position: relative;
    top: -2px;
    margin-left: 10px;
  }
}
.loading-spinner {
  margin: auto;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
  z-index: 990;
  text-align: center;

  .spinner-border {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }
}
.table {
  .btn {
    font-size: 12px;
    padding: 4px 8px;
  }
}
.empty-content {
  background-color: #f5f5f5;
  padding: 30px;
  text-align: center;
}

/* Course video list */
.course-video-list {
  height: 600px;
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  a {
    display: block;
    position: relative;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }

    h4 {
      position: absolute;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), black);
      bottom: 0;
      left: 0;
      color: #fff;
      margin: 0;
      width: 100%;
      font-size: 15px;
      padding: 45px 15px 10px 15px;
      font-weight: 600;
      line-height: 1.4;
      &:hover {
        color: $main-color;
      }
    }
  }
}
.course-video-iframe {
  position: relative;

  iframe {
    height: 600px;
    width: 100%;
    border: 5px solid #eee;
    border-radius: 10px;
  }

  video {
    height: 600px;
    width: 100%;
    border: 5px solid #eee;
    border-radius: 10px;
  }

  .prev,
  .next {
    background-color: $main-color;
    border: 1px solid $main-color;
    padding: 0;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 38px;
    position: absolute;
    font-size: 20px;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    opacity: 0;
    transition: $transition;
    z-index: 999;
  }
  .next {
    left: auto;
    right: -10px;
  }

  &:hover {
    .prev,
    .next {
      opacity: 1;
    }
  }
}

/* User Profile */
.user-profile {
  text-align: center;
  img {
    margin-bottom: 20px;
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
  }
  p {
    margin: 0;
  }
}

/*new-css*/
.bg-fff8f8 {
  background-color: #fff8f8;
}
.bg-f9fbff {
  background-color: #f9fbff;
}

/*================================================
Features Area CSS
=================================================*/
.features-box {
  z-index: 1;
  padding: 35px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: $transition;
  background-color: $white-color;

  .icon {
    font-size: 50px;
    line-height: 1;
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: 15px;
    font: {
      size: 22px;
      weight: 800;
    }
  }
  p {
    margin-bottom: 12px;
  }
  .link-btn {
    display: inline-block;
    color: $main-color;
    font: {
      size: 15px;
      weight: 700;
    }
    &:hover {
      color: $black-color;
    }
  }
  .back-icon {
    top: 20px;
    right: 20px;
    opacity: 0.05;
    line-height: 1;
    font-size: 120px;
    position: absolute;
  }
  &:hover {
    transform: translateY(-10px);
  }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
  z-index: 1;
  position: relative;
  padding: {
    top: 80px;
    bottom: 100px;
  }
  .container-fluid {
    max-width: 1395px;
  }
  .banner-shape1 {
    left: auto;
    bottom: 10%;
    right: 17%;
    width: 190px;
  }
  .banner-shape2 {
    width: 50px;
    left: 29%;
    top: 9%;
  }
  .banner-shape3 {
    right: auto;
    width: 50px;
    left: 30%;
    top: 5%;
  }
}
.main-banner-content-style-two {
  h1 {
    margin-bottom: 20px;
    font: {
      size: 60px;
      weight: 800;
    }
  }
  p {
    max-width: 580px;
    line-height: 1.8;
    font: {
      weight: 600;
      size: 16px;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.bulb {
  left: 15%;
  bottom: 0;
  z-index: -1;
  position: absolute;

  img {
    animation: moveleftbounce 4s linear infinite;
  }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  .bulb {
    left: auto;
    right: 50px;
    width: 280px;
  }
}

/*================================================
View All Courses Area CSS
=================================================*/
.view-all-courses-area-three {
  padding-top: 20px;
  position: relative;
  z-index: 1;

  .bulb {
    left: auto;
    right: 50px;
  }
}
.view-all-courses-content-style-two {
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin-bottom: 0;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    margin-top: 15px;
  }
  .default-btn {
    margin-top: 10px;
  }
}
.view-all-courses-image-style-two {
  margin-left: 30px;
}

/*================================================
Premium Access Area CSS
=================================================*/
.premium-access-area-two {
  z-index: 1;
  padding-top: 30px;
  position: relative;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: -1;
    height: 35px;
    position: absolute;
    background-color: #100f1f;
  }
}
.premium-access-content-style-two {
  position: relative;
  top: -20px;

  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 8px;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin-bottom: 15px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  .default-btn {
    margin-top: 10px;
  }
}
.premium-access-image-style-two {
  text-align: center;
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-slides-two {
  max-width: 1150px;
  margin: {
    left: auto;
    right: auto;
  }
  &.owl-theme {
    .owl-nav {
      margin-top: 0;

      [class*="owl-"] {
        left: -20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        padding: 0;
        background-color: $white-color !important;
        width: 40px;
        color: $main-color;
        border: 1px solid $main-color;
        height: 40px;
        font-size: 25px !important;
        transition: $transition;
        border-radius: 50%;

        i {
          left: 0;
          right: 0;
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: $main-color !important;
          color: $white-color;
        }
        &.owl-next {
          left: auto;
          right: -20px;
        }
      }
    }
  }
}
.testimonials-item {
  z-index: 1;
  padding: 100px;
  background-color: #fff8f8;
  border-radius: 5px;
  position: relative;

  p {
    font-size: 18px;
    line-height: 1.8;
  }
  h3 {
    color: $main-color;
    margin: {
      top: 20px;
      bottom: 0;
    }
    font: {
      size: 20px;
      weight: 700;
    }
  }
  span {
    display: block;
    color: $optional-color;
    font-size: 15px;
    margin-top: 4px;
  }
  img {
    display: inline-block !important;
    width: auto !important;
    text-align: center;
  }
  &::before {
    opacity: 0.04;
    content: "\f10a";
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    line-height: 1;
    font: {
      family: Flaticon;
      size: 200px;
    }
    margin: {
      top: 10px;
      left: 50px;
    }
  }
}

/*================================================
Get Instant Courses Area CSS
=================================================*/
.get-instant-courses-area-two {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 25px;
    z-index: -1;
    background-color: $white-color;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 21px;
    z-index: -1;
    background-color: $white-color;
  }
  .bulb {
    left: 20px;
    width: 180px;
    bottom: 21px;
  }
}
.get-instant-courses-content-style-two {
  .sub-title {
    display: block;
    color: $main-color;
    margin-bottom: 10px;
    text-transform: uppercase;
    font: {
      size: 14px;
      weight: 600;
    }
  }
  h2 {
    margin-bottom: 15px;
    font: {
      size: 36px;
      weight: 800;
    }
  }
  p {
    max-width: 620px;
    line-height: 1.8;
  }
  .default-btn {
    margin-top: 5px;
  }
}
.get-instant-courses-image-style-two {
  text-align: right;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-item {
  text-align: center;

  img {
    width: auto !important;
    display: inline-block !important;
    transition: $transition;
    opacity: 0.6;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
}

/*================================================
Video Area CSS
=================================================*/
.video-area {
  z-index: 1;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    z-index: -1;
    background-color: #fff8f8;
  }
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-box {
  background-color: $white-color;
  border: 1px dashed #ad8360;
  border-radius: 50%;
  text-align: center;
  position: relative;
  height: 280px;
  width: 280px;
  margin: {
    left: auto;
    right: auto;
    bottom: 30px;
  }
  h3 {
    margin-bottom: 8px;
    color: $main-color;
    font: {
      size: 44px;
      weight: 800;
    }
    span {
      font-size: 48px;
    }
    .odometer {
      position: relative;
      top: -2px;
    }
  }
  p {
    text-transform: uppercase;
    color: $black-color;
    line-height: 1;
    font: {
      weight: 700;
      size: 16px;
    }
  }
  .content {
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
}

.site-wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

@import "overwrite";
