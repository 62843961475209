@import "../../style/scss/base/index";

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  background-color: $inputBackground;
  padding: 20px;
  margin-top: 80px;
}

.banner-wrapper {
  display: flex;
  justify-content: center;
  max-width: 1420px;
  padding: 0 30px;
}

.banner-content {
  h1 {
    color: $lightBlue;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    font-size: 36px;
    font-weight: bold;
    color: $blue;
  }
  p {
    color: $blue;
  }
}

.banner-image {
  display: flex;
}

.banner-image__wrapper {
  position: relative;
  display: inline-block;
  width: 600px;
  z-index: 1;
}

.banner-image__main-img {
  border-radius: 209px;
}

.banner-image__shape-1 {
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 87%;
  text-align: center;
  height: 60px;
  width: 60px;
  background-color: $cyan;
  border-radius: 50%;
}

.banner-image__shape-2 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  text-align: center;
  height: 120px;
  width: 120px;
  border: 3px solid $cyan;
  border-radius: 50%;
}

@media (max-width: 1100px) {
  .banner-wrapper {
    flex-direction: column;
  }
  .banner-image {
    justify-content: center;
  }
  .banner-image__wrapper {
    margin-top: 30px;
  }
  .banner-image__wrapper {
    width: auto;
    height: auto;
  }
}

@media (max-width: 600px) {
  .banner-image__shape-1 {
    height: 30px;
    width: 30px;
  }

  .banner-image__shape-2 {
    height: 60px;
    width: 60px;
    left: 8%;
  }
}

@media (max-width: 400px) {
  .banner-image__shape-2 {
    left: 4%;
  }
}
