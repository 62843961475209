@import "../../style/scss/base/index";

.app-input {
  display: block;
  width: 100%;
  height: 40px;
  border: 1px solid $borderGrey;
  padding-left: 5px;
  border-radius: 7px;
  background-color: $lightBlue2;
  transition: 0.5s;
}

.app-input--icon {
  padding-right: 40px;
}

.app-input:hover {
  border: 1px solid $lightBlue;
  transition: 0.5s;
}

.app-input:focus {
  border: 1px solid $lightBlue;
  transition: 0.5s;
}
