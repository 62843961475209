@import "../../../style/scss/base";

.pdf-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-bottom: 50px;
    button {
      margin: 0px 25px;
    }
  }
  @media (max-width: 768px) {
    &__buttons {
      flex-direction: column;
      button {
        margin: 5px 0px;
      }
    }
  }
}
