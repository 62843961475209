/* default-btn */
.default-btn {
  border: 2px solid transparent;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: $white;
  background-color: $blue;
  transition: $transition;
  @include font-15-normal();
  border-radius: 19px;
  padding: 8px 30px;

  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $white;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &:hover {
    color: $white;
    background-color: $blueHover;
    border: 2px solid $blueHover;
    span {
      width: 225%;
      height: 562.5px;
    }
  }

  &:disabled {
    background-color: #dedede;
    color: $white;
    &:hover {
      color: $blue;
    }
  }
}
.default-btn-style-two {
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: $blue;
  background-color: $white;
  transition: $transition;
  border-radius: 19px;
  border: 2px solid $blue;
  @include font-15-normal();
  padding: 8px 30px;

  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $blue;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &:hover {
    border: 2px solid $blue;
    color: $white;
    span {
      width: 225%;
      height: 562.5px;
    }
  }
}
