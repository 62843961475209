@import "../../style/scss/base/vars";

.white-wrapper {
  background-color: $white;
  padding: 19px 24px;
  border-radius: 12px;
  width: 100%;
  @media (max-width: 900px) {
    margin-bottom: 20px;
  }

  &--with-big-padding {
    padding: 35px;
  }

  &.loader {
    min-height: 60vh;

    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
