@import "../../style/scss/base/index";

.authors-container {
  display: flex;
  justify-content: center;
  min-height: 77vh;
  margin: 0;
  background-color: $inputBackground;
}

.authors-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 50px 30px;

  h1 {
    font-size: 22px;
    font-weight: bold;
    color: $blue;
  }
}

.authors-card__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 25px 0;
}

.authors-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 10px 0;
  max-width: 800px;
  width: 100%;
  background-color: $white;
  padding: 25px;
  border: none;
  border-radius: 10px;


  &__content {
    display: flex;
    border: none;
    margin: 20px 0;
    padding: 0;
    background-color: inherit;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__bio {
    color: $greyFont;
    margin: 0 0 30px 0;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
    span {
      color: $blue;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: bold;
  }

  &__img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }

  &__icon-container {
    background-color: $blue;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 12px;
    width: 12px;
    background-color: inherit;
  }

  &__icon {
    width: 15px;
    margin: 0;
    padding: 0;
    transition: transform 0.5s ease-in-out;
    display: flex;
    justify-content: center;
  }

  &__icon--active {
    transform: rotate(180deg);
  }

  &__question {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .authors-card__info {
    flex-direction: column;
  }
  .authors-card__content {
    flex-direction: column;
  }
  .authors-card__text {
    margin: 10px 0;
    align-items: center;
  }
}
