.lms-tag {
  display: inline-block;
  background: #f6f7fa;
  color: #003e70;
  padding: 7px 15px;
  border: none;
  border-radius: 20px;
  margin-left: 5px;
  transition: 0.3s all ease;
  font: {
    size: 13px !important;
  }
  margin: {
    top: 8px;
    right: 4px;
  }
  &:not(.noninteractive) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:hover:not(.noninteractive),
  &:focus-visible,
  &.active {
    color: #fff;
    background-color: #129bdb;
  }

  .close {
    margin-top: 2px;
    opacity: 0;
    width: 10px;
    i {
      opacity: 0;
      color: #fff;
    }

    &.active {
      opacity: 1;
      i {
        opacity: 1;
      }
    }
  }
}

.lms-tag.active {
  color: #fff;
  background-color: #129bdb;
}
