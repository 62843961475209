@import "../../style/scss/base";

.my-courses-page {
  margin-top: 100px;
  min-height: 60vh;
}

.profile-courses {
  margin-top: 50px;
  padding-bottom: 0;

  &.no-data {
    margin-bottom: 50px;
  }
  a {
    &:hover {
      color: $blue;
    }
  }
  h2 {
    @include font-22-semi-bold();
    color: $blue;
    margin-bottom: 20px;
  }

  .single-courses-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 13px;
    position: relative;
    padding: 6px 7px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    .courses-wrapper {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .courses-image {
        margin-right: 16px;
        .image {
          width: 160px;
          position: relative;
          height: 106px;
          @media (max-width: 768px) {
            width: 100%;
            height: 200px;
          }
        }
        @media (max-width: 768px) {
          margin-right: 0px;
        }
      }
      .courses-content {
        padding-right: 20px;
        border-right: 1px solid #eaeaea;
        width: 100%;
        .courses-categories {
          a {
            @include font-13-normal();
            color: $yellow;
            margin-right: 4px;
          }
        }
        @media (max-width: 768px) {
          border-right: transparent;
          padding-right: 0px;
        }
        h3 {
          margin-top: 5px;
          @include font-16-semi-bold();
          margin-bottom: 5px;
        }
        .courses-progress {
          > span {
            @include font-13-normal();
            color: $blue;
          }
          .progress {
            border-radius: 55px;
            height: 6px;
            position: relative;
            overflow: inherit;
            .progress-bar {
              background-color: $green;
              border-radius: 55px;
            }
            .circle-wrapper {
              position: absolute;
              z-index: 10;
              .circle {
                content: "";
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-color: $green;
              }
              span {
                @include font-13-normal();
                font-weight: 600;
                color: $green;
              }
            }
          }
          @media (max-width: 768px) {
            max-width: 93%;
          }
        }
      }
    }
    .courses-continue {
      padding-left: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      a {
        width: 170px;
        padding: 8px 45px;
        border-radius: 23px;
        background-color: $green;
        color: $white;
        @include font-16-semi-bold();
        transition: 0.3s background-color ease;
        border: none;
      }
      &:hover {
        a {
          background-color: lighten($green, 15%);
        }
      }
    }
  }
}

.ended-courses {
  margin-top: 50px;
  margin-bottom: 300px;
  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
  &.available {
    margin-bottom: 60px;
  }
  h2 {
    @include font-22-semi-bold();
    color: $blue;
    margin-bottom: 20px;
  }
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.tags-wrapper {
  margin-top: 50px;
}
