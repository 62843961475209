@import "_typebase";
@import "../../../node_modules/react-image-lightbox/style.css";
$font-family: "Nunito", sans-serif;
$font-family2: "Playfair Display", serif;
$main-color: #fe4a55;
$main-color2: #6ba292;
$optional-color: #606060;
$white-color: #ffffff;
$black-color: #221638;
$font-size: 16px;
$transition: 0.5s;

.escolalms-image {
  width: 100%;
  height: 100%;
}

.single-courses-box .courses-image .image {
  height: 250px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.single-courses-box .courses-image .price {
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  line-height: 1em;
  font-size: 20px;
}

.courses-sidebar-sticky .courses-details-info {
  padding: 12px;
  margin: 0;
}

.course-program {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  padding: 0;
  margin-top: 140px;
  @media only screen and (max-width: 767px) {
    margin-top: 80px;
  }
  .course-program-player {
    flex: 1 1 auto;
    padding: 10px;
  }
  .course-program-wrapper {
    @media only screen and (max-width: 650px) {
      width: 100%;
      height: 100%;
    }
  }
  .course-program-sidebar {
    flex: 0 1 auto;
    position: sticky;
    width: 100%;
    max-width: 400px;
    // min-height: calc(100vh - 100px);
    @media only screen and (max-width: 650px) {
      position: relative;
      width: 100%;
      flex: auto;
      height: 100%;
      top: 0;
      margin: 30px 0;
    }
  }
}

.img-container-xl {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}

.topic-preview-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 999999;
  .topic-preview-modal-content {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  button {
    position: fixed;
    top: 20px;
    right: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #d0d0d0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    -moz-transition: 0.5s;
    transition: 0.5s;
    font-size: 50px;
  }
  .container-xl {
    background: #fff;
    padding: 30px;
    margin: 30px;
  }
}

.courses-details-header .courses-price,
.courses-price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  .price {
    position: static;
    top: auto;
  }
  > button,
  a {
    margin-top: 15px;
    &.full-width {
      width: 100%;
    }
  }
}

.modal {
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99998;
  }
  > div {
    z-index: 99999;
  }
  &.show {
    display: block;
  }
}

.course-nav {
  .option-item {
    margin-left: 15px;
  }
}

.course-author {
  .escolalms-image {
    width: auto;
    height: auto;
  }
  img {
    object-fit: cover;
  }
}

.profile-bio-summary {
  p:nth-child(1),
  p:nth-child(2) {
    display: block;
  }
  p:nth-child(2) {
    display: block;
    &:after {
      content: "...";
    }
  }
  p {
    display: none;
  }
}

.escolalms-nav .navbar .navbar-brand {
  img {
    height: 40px;
    width: auto;
  }
}

.courses-details-desc-style-two
  .courses-author
  .author-profile
  .author-profile-title
  img {
  object-fit: cover;
}

.course-navbar-title {
  margin: 0.5em;
  margin-left: 2em;
}

.lang-btn {
  background: transparent;
  border: none;
}

.inline-loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999999;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
}

.inline-loader .loader {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.inline-loader .ldio > div {
  -webkit-animation: ldio 1s infinite linear;
  animation: ldio 1s infinite linear;
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}

.inline-loader .ldio > div div {
  position: absolute;
}

.inline-loader .ldio > div div:nth-child(1),
.inline-loader .ldio > div div:nth-child(2) {
  width: 75px;
  height: 75px;
  border: 5px solid;
  border-radius: 50%;
  border-color: transparent #fe4a55 #fe4a55 #fe4a55;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}

.inline-loader .ldio > div div:nth-child(1) {
  -webkit-transform: rotate(45deg) translate(12.5px, 12.5px);
  transform: rotate(45deg) translate(12.5px, 12.5px);
}

.inline-loader .ldio > div div:nth-child(2) {
  -webkit-transform: rotate(0deg) translate(12.5px, 12.5px);
  transform: rotate(0deg) translate(12.5px, 12.5px);
}

.inline-loader .ldio > div div:nth-child(3) {
  width: 0;
  height: 0;
  border: 12px solid;
  border-color: transparent transparent transparent #fe4a55;
  -webkit-transform: translate(50px, 6.5px);
  transform: translate(50px, 6.5px);
}

.inline-loader .loadingio-spinner-reload {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
}

.inline-loader .ldio {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

@-webkit-keyframes ldio {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ldio {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.image-player {
  .escolalms-image {
    max-width: 50vw;
    max-height: 50vh;
    cursor: pointer;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      transition: transform 0.5s ease-out;
      width: auto;
      height: auto;
      max-width: 50vw;
      max-height: 50vh;
    }
  }
}

.__embed {
  min-width: 50vw;
  min-height: 50vh;
  //height: calc(100vh - 300px);
}

iframe.__embed__fallback {
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  min-width: 50vw;
  min-height: 50vh;
  width: 100%;
  height: calc(100vh - 300px);
}

.react-player__preview {
  //
}
