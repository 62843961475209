@import "../../style/scss/base/";

.course-program-sidebar {
  padding: 15px;
  right: 0;
  top: 77px;
  width: 400px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  z-index: 99999;
  background: #fff;
  z-index: 0;
  max-height: 900px;
  h3 {
    margin: 12px 0px 8px;
    width: 100%;
    @include font-15-semi-bold();
    color: $blue;
    &:not(:first-child) {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #e8e9eb;
    }
    cursor: pointer;
    > i.bx {
      transition: transform 0.5s;
    }
    &:hover {
      > i.bx {
        transform: rotate(180deg);
      }
    }
    > span {
      display: flex;
      align-items: center;
      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 26px;
        min-height: 26px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: $inputBackground;
        margin-right: 10px;
      }
    }
  }
  .courses-meta {
    text-align: right;

    .duration {
      display: inline-block;
      background: $ultraLightBlue;
      color: $lightBlue;
      text-transform: lowercase;
      border-radius: 3px;

      padding: 4px 9px;
      font: {
        size: 13px;
        weight: 600;
      }
    }
    .status {
      display: inline-block;
      height: 20px;
      width: 20px;
      &.RichText {
        background-image: url(../../images/awf/txt_.svg);
      }
      &.Video {
        background-image: url(../../images/awf/mov_.svg);
      }
      &.H5P {
        background-image: url(../../images/awf/inter_.svg);
      }
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    li {
      display: block;

      &.finished {
        i {
          color: $green;
          background-color: #dbf4e9;
          &::after {
            background-color: #dbf4e9;
          }
        }
      }

      a {
        background-color: #f8f9f8;

        position: relative;
        border-radius: 5px;
        padding: {
          left: 40px;
          right: 13px;
          top: 10px;
          bottom: 7px;
        }
        @include font-13-normal();
        color: $blue;
        i {
          position: absolute;
          left: 15px;
          top: 12px;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          z-index: 1;
          border-radius: 50%;
          font-size: 16px;
          transition: $transition;
          background-color: $blue;
          color: $blue;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 50%;
            z-index: -1;
            margin: 2px;
            transition: $transition;
            background-color: $white;
          }
        }
        &.active {
          i {
            color: $white;
            background-color: #a3c5bb;
            &::after {
              background-color: inherit;
            }
          }
        }

        .courses-name {
          @include font-13-normal();
          color: $blue;
        }
        &:hover {
          color: $blue;
        }
      }
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(8),
      &:nth-child(10),
      &:nth-child(12),
      &:nth-child(14),
      &:nth-child(16),
      &:nth-child(18),
      &:nth-child(20),
      &:nth-child(22),
      &:nth-child(24),
      &:nth-child(26),
      &:nth-child(28),
      &:nth-child(30),
      &:nth-child(32),
      &:nth-child(34),
      &:nth-child(36),
      &:nth-child(38),
      &:nth-child(40),
      &:nth-child(42),
      &:nth-child(44),
      &:nth-child(46),
      &:nth-child(48),
      &:nth-child(50),
      &:nth-child(52),
      &:nth-child(54),
      &:nth-child(56),
      &:nth-child(58),
      &:nth-child(60),
      &:nth-child(62),
      &:nth-child(64),
      &:nth-child(66),
      &:nth-child(68),
      &:nth-child(70),
      &:nth-child(72),
      &:nth-child(74),
      &:nth-child(76),
      &:nth-child(78),
      &:nth-child(80),
      &:nth-child(82),
      &:nth-child(84),
      &:nth-child(86),
      &:nth-child(88),
      &:nth-child(90),
      &:nth-child(92),
      &:nth-child(94),
      &:nth-child(96),
      &:nth-child(98),
      &:nth-child(100) {
        a {
          background-color: $white;
        }
      }
      &.active {
        a {
          background-color: $blue;
          span {
            color: $white;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    height: auto;
  }
}

.courses-link {
  display: flex;
  align-items: center;
}
