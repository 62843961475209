@import "../../../style/scss/base/";

.topic-video-wrapper {
  .default-btn {
    background-color: $white;
    @include font-16-semi-bold();
    border-radius: 36px;
    color: $blue;
    padding: 19px 40px 19px 26px;
  }
}

.topic-video-wrapper-simple {
  margin-top: 2em;
  video {
    width: 100%;
    height: auto;
    display: block;
  }
}
