@import "../../style/scss/base/index";

.login-modal__header {
  color: $blue;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 30px;
}

.login-modal__forgot-password {
  color: $greyFont;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}

.login-modal__footer-wrapper {
  margin: 35px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.light-button.login-modal__footer-button {
  background-color: inherit;
  border: 1px solid #4e799c;
  align-items: center;
  text-align: center;
  max-width: 100%;

  &:hover {
    border: 1px solid $blue;
  }
}

.ant-alert {
  border-radius: 7px !important;
}

.ant-alert-message {
  white-space: pre;
}

.ant-modal {
  width: 450px !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 20px !important;
}

.ant-modal-title {
  font-size: 18px;
  font-weight: bold;
  color: $white;
  margin-bottom: 20px;
}

.ant-modal-footer {
  background: $blue !important;
  color: $white;
  height: 150px;
  h1 {
    font-size: 18px;
    font-weight: bold;
    color: $white;
    margin-bottom: 20px;
  }
}

.login-modal__form {
  padding: 0 40px;
}

.login-modal__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  button {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  .login-modal__form {
    padding: 0;
  }
  .login-modal__footer-wrapper {
    padding: 0 10px;
  }
}

@media (max-width: 350px) {
  .light-button.login-modal__footer-button {
    font-size: 12px;
  }
}
