@import "../../style/scss/base/vars";
@import "../../style/scss/base/typo";

.static-page {
  &__content {
    text-align: left;
    margin-bottom: 80px;
    color: $greyFont;

    h1, h2, h3, h4, h5, h6 {
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.45px;
      color: $lightBlue;
      opacity: 1;
    }

    h1 {
      font-size: 22px;
      line-height: 44px;
      letter-spacing: -0.55px;
      color: $blue;
    }

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }

    h3, h4, h5, h6 {
      font-size: 15px;
    }

    @include standard-text();

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: -0.38px;
      margin-bottom: 15px;
    }
  }
}
