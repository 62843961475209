$green: #22b979;
$blue: #003e70;
$blueHover: #00569B;
$greyFont: #4f5e6b;
$greyFont2: #979eaf;
$lightBlue: #129bdb;
$ultraLightBlue: #dbf0fa;
$cyan: #00bed8;
$lightBlue2: #f8fafd;
$white: #fff;
$black: #000;
$borderGrey: #c8cfe0;
$inputBackground: #f6f7fa;
$error: #f20e54;
$footerText: #b7c2cb;
$transition: 0.5s;
$courseCardBorder: #eaeaea;
$yellow: #db940e;
$loginBorderButton: #4e799c;
$greyContent: #606060;
