.breadcrumbs {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;

  &__item {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    color: $black-color;
    font: {
      size: $font-size;
      weight: 600;
    }

    &::before {
      content: "";
      position: absolute;
      right: -10px;
      top: 5px;
      transform: rotate(10deg);
      background-color: #696969;
      height: 15px;
      width: 1px;
    }

    &:last-child {
      margin-right: 0;

      &::before {
        display: none;
      }
    }
  }

  &__link {
    color: $optional-color;
    display: block;
    font-weight: 500;

    &:hover {
      color: $main-color;
    }
  }
}
