@import "../../style/scss/base/index";

.nav-button {
  background-color: $white;
  @include font-13-normal();
  padding: {
    left: 0;
    right: 0;
    top: 5px;
    bottom: 5px;
  }
  margin: {
    left: 20px;
    right: 13px;
  }
  border: none;
}