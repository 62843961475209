.go-top {
  background-color: $blue;
  .bx-chevron-up {
    color: $white;
  }
  &:hover {
    background-color: $white;
    .bx-chevron-up {
      color: $blue;
    }
  }
}
